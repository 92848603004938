// assets
import { IconPlane, IconTrain, IconBus } from '@tabler/icons';

// constant
const icons = {
    IconPlane,
    IconTrain,
    IconBus
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Booking = {
    id: 'Booking',
    title: 'Booking',
    type: 'group',
    children: [
        {
            id: 'icons23',
            title: 'Flight',
            type: 'collapse',
            icon: icons.IconPlane,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Flight',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons24',
            title: 'IRCTC',
            type: 'collapse',
            icon: icons.IconTrain,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'IRCTC',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons25',
            title: 'BUS & Movie Ticket Booking',
            type: 'collapse',
            icon: icons.IconBus,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'BUS & Movie Ticket Booking',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default Booking;
