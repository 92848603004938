/* eslint-disable */

import { axiosInstanceLive } from 'instance/Axios';
import { handleCatchError } from 'utils/CatchHandler';

const commonEndPoint = 'api/V2/';
let merchantEmpID;

export const merchantCommonPost = async (apiEndPoint, payload) => {
    const isEmployee = localStorage.getItem('isEmployee');
    if (isEmployee === 'true') {
        merchantEmpID = localStorage.getItem('empId');
    }
    // const domainName = window.location.protocol + '//' + window.location.hostname;
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.post(
            endPoint,
            payload,

            {
                headers
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};
export const merchantCommonGetRequest = async (apiEndPoint, params) => {
    const isEmployee = localStorage.getItem('isEmployee');
    if (isEmployee === 'true') {
        merchantEmpID = localStorage.getItem('empId');
    }
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.get(
            endPoint,

            {
                headers,
                params
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};

export const GET_API_ENDPOINTS = {
    checkPermission: 'merchants/checkPermission',
    rdService: 'merchants/rdService',
    // Add more endpoints as needed
    //bankList
    bankList: 'moneytransfer/iserveubanklist',
    //merchant employee apis
    merchantEmployeeList: 'merchants/employee/list',
    //PANCARD APIs
    getStateDist: 'moneytransfer/getStateDist',
    //HELPING TOOL API  >>>>
    helpingTool: 'merchants/helpingTool',
    //Government services search
    getGovtServices: 'moneytransfer/getGovtServices',
    //Other api end points
    getBcCertificate: 'merchants/getBcCertificate',
    //Bill/Reciept
    billList: 'merchants/getBillReceipt',
    //BCAGREEMENT
    bcCertificateGetCErtificate: 'merchants/getBcCertificate',
    //EDIT PROFILE
    addressDetails: 'moneytransfer/address_details',
    getProfile: 'merchants/getProfile',
    //LOGOUT
    logout: 'moneytransfer/logoutSession',
    getState: 'icicMtm/merchants/GetState',
    convertionSlab: 'moneytransfer/convertionSlab',
    getIciciState: 'onboarding/getIciciState',
    getFaq: 'faq',
    mdrCharge: 'merchants/mdrCharges',
    mobileBankingList: 'merchants/mobile-banking/users'
};
export const POST_API_ENDPOINTS = {
    GETCheckPermission: 'merchants/checkPermission',
    checkMerchantOrBC: 'merchants/checkIsMerchant',
    convertBC: 'merchants/bcConvertion',
    searchServices: 'moneytransfer/search/GovtServices',
    getGovtMultiServices: 'moneytransfer/getGovtMultiServices',
    getGovtSubServices: 'moneytransfer/getGovtSubServices',
    faqSearch: 'faq/search',
    updateBcAgentAccount: 'merchants/updateBcAgentAccount',
    agreePolicy: 'moneytransfer/terms/agreePolicy',
    //merchant employee apis>>>>>
    addMerchantEmployee: 'merchants/employee/add',
    // merchant employee apis ENDS here>>>>>

    //PANCARD APIS>>>>>>>>>
    indexverifyPancard: 'merchants/pancard/indexverifyPancard',
    checkStatusregister: 'merchants/pancard/checkStatusregister',
    registerPandcard: 'merchants/pancard/Register',
    viewPanRegister: 'merchants/pancard/viewPanRegister',
    editRegister: 'merchants/pancard/editRegister',
    CouponBuy: 'merchants/pancard/CouponBuy',
    viewCouponList: 'merchants/pancard/viewCouponList',
    CouponStatusCheck: 'merchants/pancard/CouponStatusCheck',
    //PANCARD APIS ENDS HERE>>>>>>>>>

    //OTHER API END POINTS>>>>>
    uploadBcCertificate: 'merchants/uploadBcCertificate',
    getBalance: 'moneytransfer/getBalance',
    //UPIATM APIS>>>>>>>>>
    subscriptionCheck: 'merchants/upiATM/UPIATMsubscriptionCheck',
    subscriptionHistory: 'merchants/paysprint/upiATM/history',
    subscriptionOtpSend: 'merchants/paysprint/upiATM/sendOTP',
    subscriptionStatusCheck: 'merchants/paysprint/upiATM/statusCheck',
    subscriptionOtpVerofication: 'merchants/paysprint/upiATM/verifyOTP',
    unSubscription: 'merchants/upiATM/Unsubscription',
    subscriptionOtp: 'merchants/upiATM/subscriptionOTP',
    subscription: 'merchants/upiATM/subscription',
    //UPIATM APIS ENDS HERE>>>>>>>>>
    //BILL/RECIEPT APIS>>>>>>>>>
    billInvoice: 'merchants/getBillReceiptDetails',
    billReceipt: 'merchants/storeBillReceipt',
    //BCAGREEMENT
    bcUploadCertificate: 'merchants/uploadBcCertificate',
    getBcAgreement: 'moneytransfer/get-bc-agreement',
    signBcAgreement: 'moneytransfer/sign-bc-agreement',
    //BC conversion without esign for whitelabel
    bcConvertWithoutEsign: 'moneytransfer/whitelabel-convert-bc',
    //EDIT PROFILE
    editProfile: 'merchants/updateProfile',
    otpSend: 'merchants/otpSend',
    otpVerify: 'merchants/otpVerify',
    setOtpNumber: 'merchants/storeOtpNumber',
    changePassword: 'merchants/MerchantChangePassword',
    changeMpin: 'moneytransfer/merchant/ChangeMpin',
    //ICICI Registration
    iciciRegistrationStep1: 'icicMtm/merchants/StoreicicAtmWithStep',
    //MPIN
    checkMpin: 'moneytransfer/merchant/CheckMpin',
    setMpin: 'moneytransfer/merchant/SetMpin',
    getOtpMpin: 'moneytransfer/merchant/GenerateOTPMpin',
    //HELPING TOOL API END HERE>>>>>>
    //LATEST INSURANCE-FEB-2024
    webinsurance: 'moneytransfer/merchants/webinsurance',
    insureanceReport: 'merchants/InsurancePolicyList',
    //INSURANCE COMMISSION CLAIM APIS
    insuranceCompanyList: 'moneytransfer/ListInsuranceCompany',
    storeCommissionRequest: 'moneytransfer/StoreCommissionrequest',
    commissionRequestHistory: 'moneytransfer/History/Commissionrequest',
    //MULTI-PURPOSE ONBOARDING API START>>>>>>>
    getOnboardingDetails: 'onboarding/getOnboardingDetails',
    multiPurposeOnboard: 'onboarding/store',
    //MULTI-PURPOSE ONBOARDING API END>>>>>>>

    //PASSWORD RESET WITH EMAIL APIS START>>>>>>>>>>>
    resetPasswordStep1afterLogin: 'moneytransfer/verifyResetPasswordstep1New',
    verifyOtpResetPasswordAfterLogin: 'moneytransfer/verifyOtpResetPasswordNew',
    ResetConfirmPasswordAfterLogin: 'moneytransfer/ResetConfirmPasswordNew',
    //PASSWORD RESET WITH EMAIL APIS END>>>>>>>>>>>

    //PREMIUM BC CONVERSION APIS START >>>>>>>>>
    premiumBCCharges: 'moneytransfer/bctopremium/charges',
    premiumBCChargesNew: 'moneytransfer/bctopremium/chargesV2',
    premiumBcUpdateViaWallet: 'moneytransfer/bctopremium/Updatevia/wallet',
    convertBackToBC: 'merchants/premiumToBcConversion',
    //PREMIUM BC CONVERSION APIS END >>>>>>>>>
    //UPLOAD PROFILE PHOTO START >>>>>>>>>>>
    profilePhotoUpload: 'moneytransfer/addProfileImg',
    //BUSINESS PROOF UPLOAD APIS START>>>>>>>>>>>>>
    businessProofCheck: 'moneytransfer/getstatus/Bussinessproff',
    uploadBusinessProof: 'moneytransfer/StoreUpdate/Bussinessproff',
    //BUSINESS PROOF UPLOAD APIS END>>>>>>>>>>>>>
    themeModeUpdate: 'merchants/updateDarkMode',
    bbpsBiller: 'merchants/getBbpsBillerReport',
    //mobile banking
    mobileBankingRegistration: 'merchants/mobile-banking/signup',
    mobileBankingView: 'merchants/mobile-banking/view-user',
    mobileBankingUserTransactions: 'merchants/mobile-banking/user-transactions',
    mobileBankingAllTransactions: 'merchants/mobile-banking/all-transactions',
    //credit api
    creditApi: 'merchants/Creditbalancetodistributor',
    //REWARD APIS>>>>>>>>>>>>>>>>>>>>>>>>
    getReward: 'merchants/rewards/getItems',
    spinResult: 'merchants/rewards/result'
};
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export const checkPermissionGet = async (params) => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.checkPermission, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const rdServiceLinksApi = async () => {
    //API FOR GETTING GOVT MULTI SERVICES
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.rdService)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const checkMerchantOrBCAPI = async (payload) => {
    const loginRole = localStorage.getItem('role');

    //API FOR CHECKING IF THE USER IS BCAGENT OR MERCHANT
    if (loginRole === 'merchants') {
        const response = await merchantCommonPost(POST_API_ENDPOINTS.checkMerchantOrBC, payload)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        return response;
    } else {
        return null;
    }
};

export const convertAsBc = async (payload) => {
    //API FOR CONVERTING MERCHANT INTO BCAGENT
    const response = await merchantCommonPost(POST_API_ENDPOINTS.convertBC, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const searchCommonServices = async (payload) => {
    //API FOR SEARCHING ALL THE COMMON SERVICES
    const response = await merchantCommonPost(POST_API_ENDPOINTS.searchServices, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const searchGovtMultiServices = async (payload) => {
    //API FOR GETTING GOVT MULTI SERVICES
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getGovtMultiServices, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getBankList = async () => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.bankList)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getGovtSubServicesApi = async (payload) => {
    //API FOR GETTING GOVT MULTI SERVICES
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getGovtSubServices, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const faqSearchApi = async (payload) => {
    //API FOR FAQ SEARCH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.faqSearch, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const updateBcAgentAccountApi = async (payload) => {
    //API FOR FAQ SEARCH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.updateBcAgentAccount, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const agreePolicyApi = async (payload) => {
    //API FOR FAQ SEARCH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.agreePolicy, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

//MERCHANT EMPLOYEE API CALLS START HERE>>>>>>>>>

export const merchantEmployeeListApi = async () => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.merchantEmployeeList)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const addMerchantEmployeeApi = async (payload) => {
    //API FOR FAQ SEARCH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.addMerchantEmployee, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
//MERCHANT EMPLOYEE API CALLS END HERE>>>>>>>>>

//PANCARD API CALLS START HERE>>>>>>>>>
export const getStateDistApi = async () => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.getStateDist)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const indexverifyPancardApi = async (payload) => {
    //API FOR indexverify pancard
    const response = await merchantCommonPost(POST_API_ENDPOINTS.indexverifyPancard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const checkRegisterStatusApi = async (payload) => {
    //API FOR FAQ SEARCH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.checkStatusregister, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const pancardRegistrationApi = async (payload) => {
    //API FOR FAQ SEARCH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.registerPandcard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const viewPanRegisterApi = async (payload) => {
    //API view pan register details
    const response = await merchantCommonPost(POST_API_ENDPOINTS.viewPanRegister, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const editRegisterApi = async (payload) => {
    //API for edit pan register details
    const response = await merchantCommonPost(POST_API_ENDPOINTS.editRegister, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const couponBuyApi = async (payload) => {
    //API for pancard coupon buy
    const response = await merchantCommonPost(POST_API_ENDPOINTS.CouponBuy, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const viewCouponListApi = async (payload) => {
    //API for pancard view coupon list
    const response = await merchantCommonPost(POST_API_ENDPOINTS.viewCouponList, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const couponStatusCheckApi = async (payload) => {
    //API for pancard coupon status check
    const response = await merchantCommonPost(POST_API_ENDPOINTS.CouponStatusCheck, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
//PANCARD API CALLS END HERE>>>>>>>>>

export const helpingToolApi = async () => {
    //API FOR HELPING TOOLS LIST
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.helpingTool)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

//GOVERNMENT SERVICES API START HERE>>>>

export const getGovtServicesApi = async (params) => {
    //API TO fetch mobile recharge providers
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.getGovtServices, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
//UPIATM API CALLS START HERE>>>>>>>>>
export const upiAtmSubscriptionCheck = async (payload) => {
    //API for upiatm subscription status check
    const response = await merchantCommonPost(POST_API_ENDPOINTS.subscriptionCheck, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const conversionSlabApi = async () => {
    //API to get the bc agent conversion slabs
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.convertionSlab)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

//Other api calls (GET)>>>>>>>

export const getBcCertificateApi = async (params) => {
    //API TO fetch mobile recharge providers
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.getBcCertificate, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const upiAtmHistory = async (payload) => {
    //API for upiatm subscription History
    const response = await merchantCommonPost(POST_API_ENDPOINTS.subscriptionHistory, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

//OTHER API CALLS (POST)>>>>>>>
export const uploadBcCertificateApi = async (payload) => {
    //API for pancard coupon status check
    const response = await merchantCommonPost(POST_API_ENDPOINTS.uploadBcCertificate, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const upiAtmOtp = async (payload) => {
    //API for upiatm subscription otp
    const response = await merchantCommonPost(POST_API_ENDPOINTS.subscriptionOtpSend, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const upiAtmStatusCheck = async (payload) => {
    //API for upiatm subscription status
    const response = await merchantCommonPost(POST_API_ENDPOINTS.subscriptionStatusCheck, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const upiAtmOtpVerification = async (payload) => {
    //API for upiatm subscription otp verification
    const response = await merchantCommonPost(POST_API_ENDPOINTS.subscriptionOtpVerofication, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const upiAtmUnsubscription = async (payload) => {
    //API for upiatm unsubscription
    const response = await merchantCommonPost(POST_API_ENDPOINTS.unSubscription, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const upiAtmSubscriptionOtp = async (payload) => {
    //API for upiatm subscription otp
    const response = await merchantCommonPost(POST_API_ENDPOINTS.subscriptionOtp, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const upiAtmSubscription = async (payload) => {
    //API for upiatm subscription otp
    const response = await merchantCommonPost(POST_API_ENDPOINTS.subscription, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
//UPIATM API CALLS END HERE>>>>>>>>>

//BILL?RECEIPT API CALLS START HERE>>>>>>>>>
export const billInvoice = async (payload) => {
    //API for bill/reciept subscription otp
    const response = await merchantCommonPost(POST_API_ENDPOINTS.billInvoice, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const billList = async () => {
    //API for bill/reciept List
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.billList)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const billReceipt = async (payload) => {
    //API for bill/reciept subscription otp
    const response = await merchantCommonPost(POST_API_ENDPOINTS.billReceipt, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
//BILL/RECEIPT API CALLS END HERE>>>>>>>>>
//EDIT PROFILE API CALLS END HERE>>>>>>>>>
export const editProfile = async (payload) => {
    //API for edit profile upload
    const response = await merchantCommonPost(POST_API_ENDPOINTS.editProfile, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const addressDetailsGet = async () => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.addressDetails)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getProfile = async () => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.getProfile)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const logOutGet = async () => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.logout)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getOtp = async (payload) => {
    //API for otp snd
    const response = await merchantCommonPost(POST_API_ENDPOINTS.otpSend, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const otpVerify = async (payload) => {
    //API for otp snd
    const response = await merchantCommonPost(POST_API_ENDPOINTS.otpVerify, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const setOtpNumber = async (payload) => {
    //API for set otp number
    const response = await merchantCommonPost(POST_API_ENDPOINTS.setOtpNumber, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const setChangePassword = async (payload) => {
    //API for change password
    const response = await merchantCommonPost(POST_API_ENDPOINTS.changePassword, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const setChangeMpin = async (payload) => {
    //API for change Mpin
    const response = await merchantCommonPost(POST_API_ENDPOINTS.changeMpin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
//EDIT ICICI REGISTRATION API CALLS END HERE>>>>>>>>>
export const iciciStep1 = async (payload) => {
    //API for step1
    const response = await merchantCommonPost(POST_API_ENDPOINTS.iciciRegistrationStep1, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getState = async () => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.getState)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
//EDIT ICICI REGISTRATION API CALLS END HERE>>>>>>>>>
export const checkMpin = async (payload) => {
    //API for mpin check
    const response = await merchantCommonPost(POST_API_ENDPOINTS.checkMpin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const setMpins = async (payload) => {
    //API for mpin set
    const response = await merchantCommonPost(POST_API_ENDPOINTS.setMpin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getMpinOtp = async (payload) => {
    //API for mpin Otp
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getOtpMpin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const webinsuranceApi = async (payload) => {
    //API for pancard coupon status check
    const response = await merchantCommonPost(POST_API_ENDPOINTS.webinsurance, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getOnboardingDetailsApi = async (payload) => {
    //API for
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getOnboardingDetails, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getIciciStateApi = async () => {
    //API FOR FETCHING STATE LIST
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.getIciciState)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getDefaultFaq = async () => {
    //API FOR FETCHING DEFAULT FAQ
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.getFaq)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const multiPurposeOnboardApi = async (payload) => {
    //API for MULTI ONBOARDING DATA FETVH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.multiPurposeOnboard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const resetPasswordStep1AfterLoginApi = async (payload) => {
    //API to send OTP to given email id (while the user is logged in).
    const response = await merchantCommonPost(POST_API_ENDPOINTS.resetPasswordStep1afterLogin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const verifyOtpResetPasswordAfterLoginApi = async (payload) => {
    //API to verify OTP sent to given email id (while the user is logged in).
    const response = await merchantCommonPost(POST_API_ENDPOINTS.verifyOtpResetPasswordAfterLogin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const ResetConfirmPasswordAfterLoginApi = async (payload) => {
    //API to reset new password (while the user is logged in).
    const response = await merchantCommonPost(POST_API_ENDPOINTS.ResetConfirmPasswordAfterLogin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getBalanceApi = async (payload) => {
    //API for MULTI ONBOARDING DATA FETVH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getBalance, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getBCAgreementHtml = async (payload) => {
    //API for FETCHING BC AGREEMENT FILE
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getBcAgreement, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const signBcAgreementApi = async (payload) => {
    //API for SIGNING BC AGREEMENT USING E-SIGN
    const response = await merchantCommonPost(POST_API_ENDPOINTS.signBcAgreement, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const bcConvertWithoutEsignApi = async (payload) => {
    //API for CONVERTING AS A BC WITHOUT E-SIGN(FOR WHITELABELS)
    const response = await merchantCommonPost(POST_API_ENDPOINTS.bcConvertWithoutEsign, payload)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.log(error);
        return null;
    });
return response;
};
export const getRewardsApi = async (payload) => {
    //API for GET REWARDS
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getReward, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const insuranceListApi = async (payload) => {
    //API for all insurance report
    const response = await merchantCommonPost(POST_API_ENDPOINTS.insureanceReport, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const insuranceCompanyListApi = async (payload) => {
    //API for insurance company list
    const response = await merchantCommonPost(POST_API_ENDPOINTS.insuranceCompanyList, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const storeCommssionClaimApi = async (payload) => {
    //API for claiming insurance commission
    const response = await merchantCommonPost(POST_API_ENDPOINTS.storeCommissionRequest, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const commissionRequestHistoryAPi = async (payload) => {
    //API for claiming insurance commission
    const response = await merchantCommonPost(POST_API_ENDPOINTS.commissionRequestHistory, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getPremiumBcChargesApi = async (payload) => {
    //API for fetching premium bc conversion charges
    const response = await merchantCommonPost(POST_API_ENDPOINTS.premiumBCCharges, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getPremiumBcChargesNewApi = async (payload) => {
    //API for fetching premium bc conversion charges
    const response = await merchantCommonPost(POST_API_ENDPOINTS.premiumBCChargesNew, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const premiumBcUpdateViaWalletApi = async (payload) => {
    //API for upgrade as premium bc via wallet debit
    const response = await merchantCommonPost(POST_API_ENDPOINTS.premiumBcUpdateViaWallet, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const convertPremiumBackToBC = async (payload) => {
    //API for downgrade as BC from premium BC
    const response = await merchantCommonPost(POST_API_ENDPOINTS.convertBackToBC, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const profilePhotoUploadApi = async (payload) => {
    //API for update profile photo
    const response = await merchantCommonPost(POST_API_ENDPOINTS.profilePhotoUpload, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const checkBusinessProofStatusAPi = async (payload) => {
    //API for checking the status of business proof upload
    const response = await merchantCommonPost(POST_API_ENDPOINTS.businessProofCheck, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const uploadBusinessProofAPi = async (payload) => {
    //API for checking the status of business proof upload
    const response = await merchantCommonPost(POST_API_ENDPOINTS.uploadBusinessProof, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const updateThemeMode = async (payload) => {
    //API for checking the status of business proof upload
    const response = await merchantCommonPost(POST_API_ENDPOINTS.themeModeUpdate, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getMdrChargesApi = async () => {
    //API FOR MDR CHARGES
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.mdrCharge)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const bbpsBillerReportApi = async (payload) => {
    //API for bbps biller report
    const response = await merchantCommonPost(POST_API_ENDPOINTS.bbpsBiller, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const mobileBankingRegistrationApi = async (payload) => {
    //API for mobile banking registration
    const response = await merchantCommonPost(POST_API_ENDPOINTS.mobileBankingRegistration, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const mobileBankingListApi = async () => {
    //API FOR Mobile Banking List
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.mobileBankingList)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const mobileBankingViewApi = async (payload) => {
    //API for mobile banking view
    const response = await merchantCommonPost(POST_API_ENDPOINTS.mobileBankingView, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const mobileBankingUserTransactionsApi = async (payload) => {
    //API for mobile banking user transactions
    const response = await merchantCommonPost(POST_API_ENDPOINTS.mobileBankingUserTransactions, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const mobileBankingAllTransactionsApi = async (payload) => {
    //API for mobile banking All transactions
    const response = await merchantCommonPost(POST_API_ENDPOINTS.mobileBankingAllTransactions, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const CreditBalanceApi = async (payload) => {
    //API for credit
    const response = await merchantCommonPost(POST_API_ENDPOINTS.creditApi, payload)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.log(error);
        return null;
    });
return response;
};
export const getSpinResultApi = async (payload) => {
    //API for GET result
    const response = await merchantCommonPost(POST_API_ENDPOINTS.spinResult, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
