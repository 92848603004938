/* eslint-disable */
import { LeftCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import Typography from 'antd/es/typography/Typography';

const GlobalBackButton = () => {
    const navigate = useNavigate();
    const navigateBack = () => {
        navigate(-1);
    };
    return (
        <>
            <Tooltip placement="bottomLeft" title="Go back to the last page">
                <Link to="" onClick={navigateBack} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <LeftCircleFilled style={{ fontSize: 25, marginRight: 4 }} />
                </Link>
            </Tooltip>
        </>
    );
};

export default GlobalBackButton;
