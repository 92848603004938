import { createSlice } from '@reduxjs/toolkit';

const buttonSlice2 = createSlice({
    name: 'buttonState2',
    initialState: {
        buttonState2: false
    },
    reducers: {
        setFreezeTrue: (state) => {
            state.yourState1 = true;
        },
        setFreezeFalse: (state) => {
            state.yourState1 = false;
        }
    }
});
export const { setFreezeTrue, setFreezeFalse } = buttonSlice2.actions;
export default buttonSlice2.reducer;
