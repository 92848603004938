// assets
import WalletIcon from '@mui/icons-material/Wallet';

// constant
const icons = {
    WalletIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Wallets = {
    id: 'newUtilities6',
    // title: 'distributor',
    type: 'group',
    children: [
        {
            id: 'icons1',
            title: 'Wallets',
            type: 'collapse',
            icon: icons.WalletIcon,
            children: [
                {
                    id: 'tabler-icons1',
                    title: 'No.of Wallets',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'Volume of prepaid wallets TXNs',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'No of Prepaid wallets TXNS',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'No of Preapid cards Sales',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'No of Salary Cards',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'No of Rings Sales',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'MDR',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                }
            ]
        }
    ]

    // {
    //     id: 'tabler-icons2',
    //     title: 'Profile',
    //     type: 'item',
    //     url: 'mis/profile',
    //     breadcrumbs: true
    // },
    // {
    //     id: 'tabler-icons3',
    //     title: 'Merchants',
    //     type: 'item',
    //     url: 'mis/merchants',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons4',
    //     title: 'Wallet Request',
    //     type: 'item',
    //     url: 'mis/walletrequest',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons5',
    //     title: 'Approve Wallet',
    //     type: 'item',
    //     url: '/mis/approvewallet',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons6',
    //     title: 'Bank Details',
    //     type: 'item',
    //     url: '/mis/bankdetails',
    //     breadcrumbs: false
    // }
};

export default Wallets;
