import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OtpInput from 'react-otp-input';
import '../auth-forms/CustomOtpInput.css';
import { useEffect, useState } from 'react';
// import AcemoneyLogo from '../../../../assets/images/RADIANT_ACE_MONEY_MASTER_LOGOo.png';
import { handleCatchError } from 'utils/CatchHandler';
import { axiosInstanceLive } from 'instance/Axios';
import Mlink from '@mui/material/Link';
// import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';
import { Modal } from 'antd';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
// import { useSelector } from 'react-redux';
import { setMpinFalse } from 'redux/MpinModalSlice';
import { useDispatch } from 'react-redux';
import '../../../../layout/MainLayout/modalStyles.css';
import { ToastContainer } from 'react-toastify';
import { setDisablerTrue, setDisablerFalse } from 'redux/SidebarDisablerSlice';
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Mpin(props) {
    const [mpin, setMpin] = useState('');
    const [confirm_mpin, setconfirm_mpin] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [isLoading1, setisLoading1] = useState(false);
    const [showOtpField, setShowOtpField] = useState(false);
    const [validate_token, setValidate_token] = useState('');
    const [otp, setOtp] = useState('');
    const [forgotShow, setForgotShow] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isModalOpen) {
            dispatch(setDisablerFalse());
        }
        // eslint-disable-next-line
    }, [isModalOpen]);
    const showModal = () => {
        dispatch(setDisablerTrue());
        setIsModalOpen(true);
    };
    useEffect(() => {
        showModal();
        //eslint-disable-next-line
    }, []);
    const [settype1, setSettype] = useState('');
    // const mpinModalState = useSelector((state) => state?.mpinModalState?.yourStateMpin);
    const navigate = useNavigate();
    // const publicMessage = props?.publicMessage;
    //eslint-disable-next-line
    const mpinStatus = props?.status;
    useEffect(() => {
        if (mpinStatus) {
            setForgotShow(true);
        } else {
            setForgotShow(false);
        }
    }, [mpinStatus]);
    //eslint-disable-next-line
    const publicMessage = props?.publicMessage;
    const isMobile = window.innerWidth <= 768;
    const generateOtp = async (settype) => {
        setisLoading(true);
        try {
            const token = localStorage.getItem('token');
            const agentid = localStorage.getItem('id');
            setSettype(settype);
            console.log('mpin');
            const response = await axiosInstanceLive.post(
                '/api/V2/moneytransfer/merchant/GenerateOTPMpin',
                { mpin, settype, agentid },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(response);
            setisLoading(false);
            if (!response?.data?.status) {
                globalNotifyError(response?.data?.message);
                const errors = response?.data?.errors;
                const errorArrays = Object?.values(errors);
                const firstArray = errorArrays[0];
                globalNotifyError(firstArray[0]);
            } else {
                globalNotifySuccess(response?.data?.message);
                setShowOtpField(response?.data?.status);
                setValidate_token(response?.data?.validate_token);
            }
        } catch (error) {
            setisLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const handleSubmit = () => {
        console.log(mpin, confirm_mpin);
        if (mpin == confirm_mpin) {
            const Settype = 0;
            generateOtp(Settype);
        } else {
            globalNotifyError('MPIN do not match');
        }
    };
    const handleResend = () => {
        if (mpin === confirm_mpin) {
            const Settype = 1;
            generateOtp(Settype);
        } else {
            globalNotifyError('MPIN do not match');
        }
    };
    const handleSetMpin = async () => {
        setisLoading1(true);
        try {
            const token = localStorage.getItem('token');
            const agentid = localStorage.getItem('id');
            const settype = settype1;
            const response = await axiosInstanceLive.post(
                '/api/V2/moneytransfer/merchant/SetMpin',
                { mpin, settype, agentid, validate_token, otp },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(response);
            setisLoading1(false);
            if (!response?.data?.status) {
                globalNotifyError(response?.data?.message);
                const errors = response?.data?.errors;
                const errorArrays = Object?.values(errors);
                const firstArray = errorArrays[0];
                globalNotifyError(firstArray[0]);
            } else {
                globalNotifySuccess(response?.data?.message);
                localStorage.setItem('accessToken', token);
                localStorage.setItem('token', response?.data?.refresh_token);
                if (publicMessage) {
                    navigate('/public-message', { state: publicMessage });
                } else {
                    navigate('/merchant-home');
                }
                // setRefresh_token(response?.data?.refresh_token);
            }
        } catch (error) {
            setisLoading1(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //check mpin
    const handleCheckMpin = async (mpin) => {
        setisLoading(true);
        try {
            const token = localStorage.getItem('token');
            const agentid = localStorage.getItem('id');
            const response = await axiosInstanceLive.post(
                '/api/V2/moneytransfer/merchant/CheckMpin',
                { mpin, agentid },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(response);
            setisLoading(false);
            if (!response?.data?.status) {
                globalNotifyError(response?.data?.message);
            } else {
                globalNotifySuccess(response?.data?.message);
                localStorage.setItem('accessToken', token);
                localStorage.setItem('token', response?.data?.refresh_token);
                dispatch(setMpinFalse());
                setIsModalOpen(false);
                if (publicMessage) {
                    navigate('/public-message', { state: publicMessage });
                } else {
                    navigate('/merchant-home');
                }
                // setRefresh_token(response?.data?.refresh_token);
            }
        } catch (error) {
            setisLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const handleMpinChange = (mpin) => {
        // console.log('otp in handle :', otp);
        if (/^\d*$/.test(mpin)) {
            setMpin(mpin);
            // If the entered value has reached 6 digits, trigger the submit function
            if (mpin.length === 6) {
                handleCheckMpin(mpin);
            }
        }
    };
    const handleMpinChange1 = (mpin) => {
        // console.log('otp in handle :', otp);
        if (/^\d*$/.test(mpin)) {
            setMpin(mpin);
            // If the entered value has reached 6 digits, trigger the submit function
            if (mpin.length === 6) {
                setMpin(mpin);
            }
        }
    };
    const handleMpinChange2 = (confirm_mpin) => {
        if (/^\d*$/.test(confirm_mpin)) {
            setconfirm_mpin(confirm_mpin);
            // If the entered value has reached 6 digits, trigger the submit function
            if (confirm_mpin.length === 6) {
                setconfirm_mpin(confirm_mpin);
            }
        }
    };
    const handleOtpChange = (otp) => {
        if (/^\d*$/.test(otp)) {
            setOtp(otp);
            // If the entered value has reached 6 digits, trigger the submit function
            if (otp.length === 6) {
                setOtp(otp);
            }
        }
    };
    const handleForgot = () => {
        setForgotShow(false);
    };
    return (
        <>
            <Modal
                title=""
                open={isModalOpen}
                closable={false}
                footer={null}
                className="custom-modal"
                wrapClassName="custom-modal-backdrop"
                centered
            >
                <>
                    <ThemeProvider theme={defaultTheme}>
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                {/* <Box sx={{ m: 1 }}>
                                <img src={AcemoneyLogo} alt="Logo" width={480} />
                            </Box> */}
                                {/* <Typography component="h1" variant="h5">
                        mPIN
                    </Typography> */}
                                {!forgotShow && (
                                    <>
                                        {!showOtpField && (
                                            <>
                                                {/* <Box component="form" noValidate sx={{ mt: 1 }}> */}
                                                <p style={{ fontWeight: 'bold' }}>Enter MPIN</p>
                                                <OtpInput
                                                    value={mpin}
                                                    onChange={handleMpinChange1}
                                                    numInputs={6}
                                                    renderSeparator={
                                                        <span className={isMobile ? 'otp-separator-mobile' : 'otp-separator'}>-</span>
                                                    }
                                                    isInputSecure={true}
                                                    shouldAutoFocus={true}
                                                    inputStyle="custom-otp-input"
                                                    // inputType="number"
                                                    containerStyle={isMobile ? 'otp-container-mobile' : 'otp-container'}
                                                    renderInput={(props) => <input {...props} />}
                                                />
                                                <p style={{ fontWeight: 'bold' }}>Confirm MPIN</p>
                                                <OtpInput
                                                    value={confirm_mpin}
                                                    onChange={handleMpinChange2}
                                                    numInputs={6}
                                                    renderSeparator={
                                                        <span className={isMobile ? 'otp-separator-mobile' : 'otp-separator'}>-</span>
                                                    }
                                                    isInputSecure={true}
                                                    inputStyle="custom-otp-input"
                                                    // inputType="number"
                                                    containerStyle={isMobile ? 'otp-container-mobile' : 'otp-container'}
                                                    renderInput={(props) => <input {...props} />}
                                                />
                                                {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                    onClick={handleSubmit}
                                                    disabled={isLoading}
                                                >
                                                    Generate OTP
                                                </Button>
                                            </>
                                        )}
                                        {showOtpField && (
                                            <>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <p style={{ fontWeight: 'bold' }}>Enter OTP</p>
                                                        <OtpInput
                                                            value={otp}
                                                            onChange={handleOtpChange}
                                                            numInputs={6}
                                                            renderSeparator={
                                                                <span className={isMobile ? 'otp-separator-mobile' : 'otp-separator'}>
                                                                    -
                                                                </span>
                                                            }
                                                            isInputSecure={true}
                                                            shouldAutoFocus={true}
                                                            inputStyle="custom-otp-input"
                                                            // inputType="number"
                                                            containerStyle={isMobile ? 'otp-container-mobile' : 'otp-container'}
                                                            renderInput={(props) => <input {...props} />}
                                                        />
                                                        {/* <TextField
                                                            sx={{ mb: 1 }}
                                                            id="outlined-basic"
                                                            label="OTP Number"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            value={otp}
                                                            onChange={handleOtpChange}
                                                        /> */}
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 1, mb: 2 }}
                                                    disabled={isLoading1}
                                                    onClick={handleSetMpin}
                                                >
                                                    Set MPIN
                                                </Button>
                                                <Grid container>
                                                    <Grid item>
                                                        <Mlink
                                                            component="button"
                                                            variant="body2"
                                                            onClick={handleResend}
                                                            style={{ float: 'right' }}
                                                        >
                                                            Resend OTP
                                                        </Mlink>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                                {forgotShow && (
                                    <>
                                        <p style={{ fontWeight: 'bold' }}>Enter MPIN</p>
                                        <OtpInput
                                            value={mpin}
                                            onChange={handleMpinChange}
                                            numInputs={6}
                                            renderSeparator={<span className={isMobile ? 'otp-separator-mobile' : 'otp-separator'}>-</span>}
                                            isInputSecure={true}
                                            shouldAutoFocus={true}
                                            inputStyle="custom-otp-input"
                                            // inputType="number"
                                            containerStyle={isMobile ? 'otp-container-mobile' : 'otp-container'}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            // onClick={handleCheckMpin}
                                            disabled={isLoading}
                                        >
                                            Submit
                                        </Button>
                                        <Grid container>
                                            <Grid item>
                                                <Mlink component="button" variant="body2" onClick={handleForgot} style={{ float: 'right' }}>
                                                    Forgot MPIN
                                                </Mlink>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {/* </Box> */}
                            </Box>
                        </Container>
                    </ThemeProvider>
                    <></>
                </>
            </Modal>
            <ToastContainer />
        </>
    );
}
