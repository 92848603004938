import { createSlice } from '@reduxjs/toolkit';

const MpinModalSlice = createSlice({
    name: 'mpinModal',
    initialState: {
        mpinModal: false
    },
    reducers: {
        setMpinTrue: (state) => {
            state.yourStateMpin = true;
        },
        setMpinFalse: (state) => {
            state.yourStateMpin = false;
        }
    }
});
export const { setMpinTrue, setMpinFalse } = MpinModalSlice.actions;
export default MpinModalSlice.reducer;
