import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

const LoadingDialog = ({ open, status, onReload, handleClose }) => {
    return (
        <Dialog
            open={open}
            aria-labelledby="loading-dialog"
            maxWidth="xs"
            onClose={handleClose}
            PaperProps={{
                style: {
                    borderRadius: 16,
                    padding: '20px',
                    backgroundColor: '#f7f9fc'
                }
            }}
        >
            <DialogContent sx={{ textAlign: 'center' }}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={3}>
                    {status === 'loading' ? (
                        <>
                            <CircularProgress color="primary" size={60} />
                            <Typography
                                variant="h6"
                                sx={{
                                    marginTop: 2,
                                    color: '#1f2937',
                                    fontWeight: '500',
                                    letterSpacing: '0.3px'
                                }}
                            >
                                Checking status...
                            </Typography>
                        </>
                    ) : status === 'pending' ? (
                        <>
                            <Typography
                                variant="body1"
                                align="center"
                                sx={{
                                    marginBottom: 2,
                                    color: '#374151',
                                    fontSize: '1.1rem',
                                    lineHeight: 1.6,
                                    letterSpacing: '0.3px'
                                }}
                            >
                                Your e-sign verification is awaiting admin approval. You can continue with all services only after the
                                approval has been granted. Please try again once approval is complete.
                            </Typography>
                            {/* <Button variant="contained" color="primary" onClick={onLogout}>
                                Logout
                            </Button> */}
                        </>
                    ) : (
                        <>
                            <Typography
                                variant="body1"
                                align="center"
                                sx={{
                                    marginBottom: 2,
                                    color: '#374151',
                                    fontSize: '1.1rem',
                                    lineHeight: 1.6,
                                    letterSpacing: '0.3px'
                                }}
                            >
                                Please complete the e-sign process and reload the page.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onReload}
                                sx={{
                                    backgroundColor: '#3b82f6',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    borderRadius: '8px',
                                    fontSize: '1rem',
                                    textTransform: 'none',
                                    ':hover': { backgroundColor: '#2563eb' }
                                }}
                            >
                                Reload Page
                            </Button>
                        </>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default LoadingDialog;

LoadingDialog.propTypes = {
    open: PropTypes.bool,
    status: PropTypes.string,
    onReload: PropTypes.func,
    handleClose: PropTypes.func
};
