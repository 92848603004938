// assets
import { IconHeadphones, IconDeviceLandlinePhone } from '@tabler/icons';

// constant
const icons = {
    IconHeadphones,
    IconDeviceLandlinePhone
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const HelpLine = {
    id: 'sample-help',
    type: 'group',
    title: '',
    children: [
        {
            id: 'icons22',
            title: 'Customer Support',
            type: 'collapse',
            icon: icons.IconHeadphones,
            children: [
                {
                    id: 'sample-page2',
                    title: 'Customer Support',
                    type: 'item',
                    url: '/customer-support/ticket-management',
                    // icon: icons.IconHeadphones,
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons52',
                    title: 'Help Line',
                    type: 'item',
                    url: '/helpline',
                    breadcrumbs: false
                }
                // {
                //     id: 'tabler-icons528',
                //     title: 'Test Iframe',
                //     type: 'item',
                //     url: '/iframe-test',
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default HelpLine;
