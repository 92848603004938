/* eslint-disable */

// import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
import Dashboard from './dashboard';
// import UtilityBillsAndEMI from './utilityBillsAndEMI';
// import other from './other';
// import Booking from './Booking';
// import AceMoneyEarnMore from './AceMoneyEarnMore';
// import Insurance from './Insurance';
// import AccountAndSavings from './AcountAndSavings';
// import CollectPayment from './CollectPayment';
// import RefferAndEarn from './RefferAndEarn';
// ==============================|| MENU ITEMS ||============================== //

const superDistributorMenuItems = {
    items: [Dashboard]
};

export default superDistributorMenuItems;
