import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import ProtectedRouteMIS from '../ProtectRouteMIS';
//mis
const Dashboardmis = Loadable(lazy(() => import('views/mis/Dashboard')));
const QrMerchants = Loadable(lazy(() => import('views/mis/QrMerchants')));
const Wallets = Loadable(lazy(() => import('views/mis/Wallets')));
const CoOperative = Loadable(lazy(() => import('views/mis/CoOperative')));
const CoOperativeBc = Loadable(lazy(() => import('views/mis/CoOperativeBc')));
const UpiPayOuts = Loadable(lazy(() => import('views/mis/UpiPayOuts')));
const MerchantThroughCoOperative = Loadable(lazy(() => import('views/mis/MerchantsThroughCo')));
const BcPoints = Loadable(lazy(() => import('views/mis/BcPoints')));

export const MisRoutes = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteMIS element={<Dashboardmis />} />
        },

        {
            path: 'qrmerchants',
            element: <ProtectedRouteMIS element={<QrMerchants />} />
        },
        {
            path: 'wallets',
            element: <ProtectedRouteMIS element={<Wallets />} />
        },
        {
            path: 'cooperative',
            element: <ProtectedRouteMIS element={<CoOperative />} />
        },
        {
            path: 'cooperativebc',
            element: <ProtectedRouteMIS element={<CoOperativeBc />} />
        },
        {
            path: 'upipayouts',
            element: <ProtectedRouteMIS element={<UpiPayOuts />} />
        },
        {
            path: 'merchantsthroughcooperative',
            element: <ProtectedRouteMIS element={<MerchantThroughCoOperative />} />
        },
        {
            path: 'bcpoints',
            element: <ProtectedRouteMIS element={<BcPoints />} />
        }
    ]
};
