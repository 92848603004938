// path/to/yourReducer.js
import { createSlice } from '@reduxjs/toolkit';

const buttonSlice = createSlice({
    name: 'buttonState',
    initialState: {
        buttonState: false
    },
    reducers: {
        setToTrue: (state) => {
            state.yourState = true;
        },
        setToFalse: (state) => {
            state.yourState = false;
        }
    }
});

export const { setToTrue, setToFalse } = buttonSlice.actions;
export default buttonSlice.reducer;
