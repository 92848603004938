/* eslint-disable */

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
// import jwt from 'jsonwebtoken';
import jwt_decode from 'jwt-decode';

const useAuth = () => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('type');
    // decode the token to get its payload
    // const decodedToken = jwt.decode(token, { complete: true });
    if (role === 'distributor') {
        if (token) {
            // console.log('token is present', token);
            const decoded = jwt_decode(token);
            // console.log('exp of token : ', decoded.exp);
            // console.log('time left : ', decoded.exp - Date.now() / 1000);
            // check if the token has expired
            if (decoded.exp < Date.now() / 1000) {
                // console.log('expired');
                // token has expired
                return 1;
            } else {
                // console.log('not expired');
                // The token is still valid
                const user = token;
                return user && token;
            }
        } else {
            return 1;
        }
    } else {
        // console.log('unautherized ,you are not a distributor');
        return 2;
    }
};

const ProtectedRouteDistributor = ({ element }) => {
    const isAuth = useAuth();
    if (isAuth) {
        if (isAuth === 1) {
            return <Navigate to="/" replace />;
        } else if (isAuth === 2) {
            return <Navigate to="/unauthorized" replace />;
        } else {
            return element;
        }
    } else {
        return <Navigate to="/" replace />;
    }
    // return isAuth ? element : <Navigate to="/login" replace />;
};

export default ProtectedRouteDistributor;
