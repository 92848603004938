/* eslint-disable */

import { axiosInstanceLive } from 'instance/Axios';
import { handleCatchError } from 'utils/CatchHandler';

const commonEndPoint = 'api/V2/';

export const merchantCommonPost = async (apiEndPoint, payload) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    let merchantEmpID;
    const isEmployee = localStorage.getItem('isEmployee');
    if (isEmployee === 'true') {
        merchantEmpID = localStorage.getItem('empId');
    }
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.post(
            endPoint,
            payload,

            {
                headers
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};
export const merchantCommonGetRequest = async (apiEndPoint) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    let merchantEmpID;
    const isEmployee = localStorage.getItem('isEmployee');
    if (isEmployee === 'true') {
        merchantEmpID = localStorage.getItem('empId');
    }
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.get(
            endPoint,

            {
                headers
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};

export const GET_API_ENDPOINTS = {
    getWallet: 'merchants/getWallet'

    // Add more endpoints as needed
};
export const POST_API_ENDPOINTS = {
    getWalletCharges: 'merchants/upi/GetCharges',
    createOrder: 'merchants/upi/createOrder',
    payOrder: 'merchants/upi/payOrder',
    OrderStatus: 'merchants/upi/OrderStatus',
    upiVerify: 'merchants/upi/upiVerify',
    storeWallet: 'merchants/storeWallet',
    alltransactions: 'moneytransfer/virtual/alltransactions',
    checkMerchantBalance: 'moneytransfer/checkMerchantBalance',
    RedeemfromHold: 'moneytransfer/RedeemfromHold',
    RedeemtoWallet: 'moneytransfer/RedeemtoWallet',
    settlementHistory: 'moneytransfer/history/settlement',
    settlementRequest: 'moneytransfer/settlement/new/request',
    getTransactions: 'merchants/getTransactions',
    storeQrwallettransfer: 'merchants/storeQrwallettransfer',
    alltransactionsWallet3: 'moneytransfer/settlementLedger/alltransactions',
    RedeemtoWallet3: 'moneytransfer/settlementLedger/RedeemtoWallet',
    settlementHistoryWallet3: 'moneytransfer/history/settlementLegder'

    // Add more endpoints as needed
};
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export const getWalletApi = async () => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.getWallet)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getWalletChargesApi = async (payload) => {
    //API FOR CONVERTING MERCHANT INTO BCAGENT
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getWalletCharges, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const createOrderApi = async (payload) => {
    //API for creating a wallet request order
    const response = await merchantCommonPost(POST_API_ENDPOINTS.createOrder, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const payOrderApi = async (payload) => {
    //API for creating a wallet request order
    const response = await merchantCommonPost(POST_API_ENDPOINTS.payOrder, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const orderStatusApi = async (payload) => {
    //API for checking upi request status
    const response = await merchantCommonPost(POST_API_ENDPOINTS.OrderStatus, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const upiVerifyApi = async (payload) => {
    //API for verifying vpa id
    const response = await merchantCommonPost(POST_API_ENDPOINTS.upiVerify, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const storeWalletApi = async (payload) => {
    //API for storing wallet request
    const response = await merchantCommonPost(POST_API_ENDPOINTS.storeWallet, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const alltransactionsApi = async (payload) => {
    //API for fetching all transactions
    const response = await merchantCommonPost(POST_API_ENDPOINTS.alltransactions, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const alltransactionsApiWallet3 = async (payload) => {
    //API for fetching all transactions of wallet 3
    const response = await merchantCommonPost(POST_API_ENDPOINTS.alltransactionsWallet3, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const checkMerchantBalanceApi = async (payload) => {
    //API for fetching all transactions
    const response = await merchantCommonPost(POST_API_ENDPOINTS.checkMerchantBalance, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const RedeemfromHoldApi = async (payload) => {
    //API for redeem from hold
    const response = await merchantCommonPost(POST_API_ENDPOINTS.RedeemfromHold, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const RedeemtoWalletApi = async (payload) => {
    //API for redeem from hold
    const response = await merchantCommonPost(POST_API_ENDPOINTS.RedeemtoWallet, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const RedeemtoWalletApiWallet3 = async (payload) => {
    //API for redeem from hold for wallet 3
    const response = await merchantCommonPost(POST_API_ENDPOINTS.RedeemtoWallet3, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const settlementHistoryApi = async (payload) => {
    //API for fetching settlement history
    const response = await merchantCommonPost(POST_API_ENDPOINTS.settlementHistory, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const settlementHistoryApiWallet3 = async (payload) => {
    //API for fetching settlement history for wallet 3
    const response = await merchantCommonPost(POST_API_ENDPOINTS.settlementHistoryWallet3, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const settlementRequestApi = async (payload) => {
    //API for fetching settlement history
    const response = await merchantCommonPost(POST_API_ENDPOINTS.settlementRequest, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getAllTransactionsApi = async (payload) => {
    //API for fetching settlement history
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getTransactions, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const storeQrwallettransferApi = async (payload) => {
    //API for fetching settlement history
    const response = await merchantCommonPost(POST_API_ENDPOINTS.storeQrwallettransfer, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
