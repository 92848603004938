/* eslint-disable */

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import distributorMenuItems from 'menu-items(distributor)';
import superDistributorMenuItems from 'menu-items(superdistributor)';
import EmployeeMenuItems from 'menu-items(employee)';
import MisMenuItems from 'menu-items(mis)';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [menu, setMenu] = useState([]);
    const role = localStorage.getItem('role');
    const employee = localStorage.getItem('employeeRole');
    const user = useSelector((state) => state);
    const isEmployee = localStorage.getItem('isEmployee');
    let newMerchantMenu = menuItem.items;
    // console.log('menuitems', menuItem);
    // console.log('isEmployee', isEmployee);
    if (isEmployee === 'true') {
        newMerchantMenu = menuItem.items.filter((item) => item.id !== 'merchantEmployee');
        // console.log('menu items after edit', newMerchantMenu);
    }
    useEffect(() => {
        // switch ((role, employee)) {
        //     case 'merchants':
        //         setMenu(menuItem.items);
        //         break;
        //     case 'distributor':
        //         setMenu(distributorMenuItems.items);
        //         break;
        //     case 'superdistributor':
        //         setMenu(superDistributorMenuItems.items);
        //         break;
        //     case 'admin':
        //         setMenu(MisMenuItems.items);
        //         break;
        //     default:
        //         setMenu(['default value']);
        //         break;
        // }
        if (role === 'merchants') {
            setMenu(newMerchantMenu);
            // } else if (role === 'distributor' && !employee) {
        } else if (role === 'superdistributor') {
            setMenu(superDistributorMenuItems.items);
        } else if (role === 'admin') {
            setMenu(MisMenuItems.items);
        } else if ((role === 'distributor' && employee === 'employee') || employee === 'teamleader' || employee === 'teammanager') {
            setMenu(EmployeeMenuItems.items);
        } else {
            setMenu(distributorMenuItems.items);
        }
    }, []);
    // console.log('is menu arry?', menu);
    const navItems = menu.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
