// assets
import GroupsIcon from '@mui/icons-material/Groups';

// constant
const icons = {
    GroupsIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const coOperative = {
    id: 'newUtilities2',
    // title: 'distributor',
    type: 'group',
    children: [
        {
            id: 'icons1',
            title: 'Co-Operative',
            type: 'collapse',
            icon: icons.GroupsIcon,
            children: [
                {
                    id: 'tabler-icons1',
                    title: 'No Of New Onboarding',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'Digital Banking Solution',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'UPI- P2P',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'UPI -P2M',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'Sahakarana Seva kendram',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'AMC',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'MMC',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                }
            ]
        }
    ]

    // {
    //     id: 'tabler-icons2',
    //     title: 'Profile',
    //     type: 'item',
    //     url: 'mis/profile',
    //     breadcrumbs: true
    // },
    // {
    //     id: 'tabler-icons3',
    //     title: 'Merchants',
    //     type: 'item',
    //     url: 'mis/merchants',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons4',
    //     title: 'Wallet Request',
    //     type: 'item',
    //     url: 'mis/walletrequest',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons5',
    //     title: 'Approve Wallet',
    //     type: 'item',
    //     url: '/mis/approvewallet',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons6',
    //     title: 'Bank Details',
    //     type: 'item',
    //     url: '/mis/bankdetails',
    //     breadcrumbs: false
    // }
};

export default coOperative;
