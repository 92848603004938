import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { reduxIndexverifysender_dmt_api } from 'utils/API/Merchant APIs/DmtApiCalls';

export const fetchDmtAuthorization = createAsyncThunk('dmtAuthorized/dmtAuthorizationCheck', async () => {
    try {
        const response = await dmtAuthorizationCheck();
        // console.log(response);
        return response;
    } catch (error) {
        throw new Error('Failed');
    }
});

const DmtAuthorizedSlice = createSlice({
    name: 'dmtAuthorized',
    initialState: {
        authorizationStatus: null,
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDmtAuthorization.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDmtAuthorization.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.authorizationStatus = action.payload;
            })
            .addCase(fetchDmtAuthorization.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

//Dmt authorization check
const dmtAuthorizationCheck = async () => {
    try {
        const uid = localStorage.getItem('id');
        const transfer = 'fino-transfer';
        const payload = { uid, transfer };
        const response = await reduxIndexverifysender_dmt_api(payload);
        const authorizationMessage = response?.data;
        // console.log(authorizationMessage);
        return authorizationMessage;
    } catch (error) {
        console.log(error.message);
    }
};

export const pageActions = DmtAuthorizedSlice.actions;
export default DmtAuthorizedSlice.reducer;
