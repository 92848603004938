/* eslint-disable */
import { setBcTrue, setBcFalse } from 'redux/BcAgreementSlice';
import Button from '@mui/material/Button';
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { Modal } from 'antd';
import { styled } from '@mui/material/styles';
import React, { useRef } from 'react';
import { Typography } from '@mui/material';
const commonStyles = {
    m: 0
};
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});
import { useDispatch, useSelector } from 'react-redux';
import { setDisablerTrue, setDisablerFalse } from 'redux/SidebarDisablerSlice';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { handleCatchError } from 'utils/CatchHandler';
import { axiosInstanceLive } from 'instance/Axios';
import { useEffect } from 'react';
import { checkPermissionGet, getBcCertificateApi, uploadBcCertificateApi } from 'utils/API/Merchant APIs/MerchantApiCalls';
const BcAgreement = (data) => {
    const [bcimage, setBcImage] = useState('');
    const [bcimagepath, setBcImagePath] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showBcimage, setShowbcimage] = useState();
    const [bcimagedata, setBcIamgeData] = useState('');
    const [isPdf, setIsPdf] = useState('');
    const [pdfData, setPdfData] = useState('');
    // console.log('isModalOpen', isModalOpen);
    const showBc = useSelector((state) => state?.bcAgreement?.bcAgreementShow);
    const ImageInput = styled('input')({
        display: 'none'
    });
    const dispatch = useDispatch();
    useEffect(() => {
        if (showBc) {
            dispatch(setDisablerTrue());
            setIsModalOpen(showBc);
        } else {
            dispatch(setDisablerFalse());
            dispatch(setBcFalse());
        }
    }, [showBc]);
    const handleBcImage = async () => {
        const certificate = bcimage;
        try {
            const payload = { certificate };
            const response = await uploadBcCertificateApi(payload);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                checkPermissions();
            }
            if (response?.data?.errors) {
                const errors = response?.data?.errors;
                const errorArrays = Object?.values(errors);
                const firstArray = errorArrays[0];
                globalNotifyError(firstArray[0]);
            }
        } catch (error) {
            handleCatchError(error);
        }
    };
    //check permission
    const checkPermissions = async () => {
        //API TO CHECK PERMISSION FOR SETTLEMENT REQUEST
        try {
            const response = await checkPermissionGet();
            setShowbcimage(response?.data?.bccertificate);
            if (response?.data?.status) {
                checkbgimage();
                const permission = response?.data?.settlement_type;
            } else {
                const message = response?.data?.message;
                globalNotifyError(message ? message : 'Request failed,Please try again.');
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //bc image api
    const checkbgimage = async () => {
        //API TO CHECK PERMISSION FOR SETTLEMENT REQUEST
        try {
            const response = await getBcCertificateApi();
            // console.log('check image:', response);
            setBcIamgeData(response?.data?.image);
            setPdfData(response?.data?.image);
            if (response?.data?.type === 'image') {
                setIsPdf(true);
            } else {
                setIsPdf(false);
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    useEffect(() => {
        checkPermissions();
    }, []);
    // useEffect(() => {
    //     if (!isModalOpen) {
    //         dispatch(setDisablerFalse());
    //     }
    // }, [isModalOpen]);
    // const showModal = () => {
    //     dispatch(setDisablerTrue());
    //     setIsModalOpen(true);
    // };
    const handleOk = () => {
        if (bcimagepath) {
            handleBcImage();
            dispatch(setBcFalse());
            dispatch(setDisablerFalse());
            setIsModalOpen(false);
        } else {
            globalNotifyError('No files were choosen.');
        }
    };
    const handleCancel = () => {
        dispatch(setBcFalse());
        dispatch(setDisablerFalse());
        setIsModalOpen(false);
    };
    const handleOkImage = () => {
        dispatch(setBcFalse());
        dispatch(setDisablerFalse());
        setIsModalOpen(false);
    };
    const handleBcAgreement = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const fileName = file.name;
            const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
            const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];

            // Check if the file extension is allowed
            if (allowedExtensions.includes(fileExtension)) {
                setBcImage(reader.result);
                const filePath = `/${fileName}`;
                const selectedFile = event.target.files[0];

                if (selectedFile) {
                    if (selectedFile.size <= 10 * 1024 * 1024) {
                        setBcImagePath(fileName);
                    } else {
                        globalNotifyError('Image size exceeds 10MB. Please choose a smaller image.');
                    }
                }
            } else {
                globalNotifyError('Invalid file format. Please choose a PDF, JPG, or PNG file.');
            }
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const downloadPDF = () => {
        const link = document.createElement('a');
        link.href = pdfData;
        link.download = 'document.pdf';
        link.click();
    };
    return (
        <>
            {/* <Typography variant="body2" onClick={showModal}>
                {' '}
                Bc Agreement
            </Typography> */}
            {!showBcimage && (
                <Modal
                    title="Bc Agreement"
                    open={isModalOpen}
                    closable={false}
                    width={500}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="cancel" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={handleOk}>
                            Submit
                        </Button>
                    ]}
                >
                    <Button component="label" variant="contained" onChange={handleBcAgreement} startIcon={<CloudUploadIcon />}>
                        Upload file
                        <VisuallyHiddenInput type="file" />
                    </Button>
                </Modal>
            )}

            {showBcimage && (
                <Modal
                    title="Bc Agreement"
                    open={isModalOpen}
                    width={500}
                    closable={false}
                    footer={[
                        <Button key="submit" type="primary" onClick={handleOkImage}>
                            Ok
                        </Button>
                    ]}
                >
                    {' '}
                    {isPdf && <img src={bcimagedata} alt="bc" width="450px" height="400px" />}
                    {!isPdf && (
                        // <PDFViewer
                        //     document={{
                        //         url: { pdfData }
                        //     }}
                        // />
                        <Button component="label" variant="contained" onClick={downloadPDF}>
                            Download PDF
                        </Button>
                    )}
                </Modal>
            )}
        </>
    );
};
export default BcAgreement;
