// assets
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// constant
const icons = {
    AccountCircleIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Merchants = {
    id: 'newUtilities5',
    // title: 'distributor',
    type: 'group',
    children: [
        {
            id: 'icons1',
            title: 'Merchants',
            type: 'collapse',
            icon: icons.AccountCircleIcon,
            children: [
                {
                    id: 'tabler-icons1',
                    title: 'NO.of Qr TXNs',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'Volume of Qr',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'Volume of POS',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'POS Earnings',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                }
            ]
        }
    ]

    // {
    //     id: 'tabler-icons2',
    //     title: 'Profile',
    //     type: 'item',
    //     url: 'mis/profile',
    //     breadcrumbs: true
    // },
    // {
    //     id: 'tabler-icons3',
    //     title: 'Merchants',
    //     type: 'item',
    //     url: 'mis/merchants',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons4',
    //     title: 'Wallet Request',
    //     type: 'item',
    //     url: 'mis/walletrequest',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons5',
    //     title: 'Approve Wallet',
    //     type: 'item',
    //     url: '/mis/approvewallet',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons6',
    //     title: 'Bank Details',
    //     type: 'item',
    //     url: '/mis/bankdetails',
    //     breadcrumbs: false
    // }
};

export default Merchants;
