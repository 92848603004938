/* eslint-disable */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
// import FormControl from '@mui/material/FormControl';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { Space, Spin } from 'antd';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { Typography } from '@mui/material';
import { axiosInstanceLive, axiosInstanceNgrok } from 'instance/Axios';
import { Button, Modal, Result } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWalletBalanceAsync } from 'redux/WalletSlice';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { checkMerchantOrBCAPI, checkPermissionGet } from 'utils/API/Merchant APIs/MerchantApiCalls';
import BcConvertModal from 'views/Components/BcConvertModal';
import { checkIsBcOrMerchant, checkMerchantOrBc } from 'redux/ServiceRestrictSlice';
import Spinner from 'views/Components/LoadingSinner';
import {
    checkCustomerExistApi,
    createCustomerSendOtpApi,
    fetchCustomerListAPi,
    getCustomerApi,
    verifyCustomerOtpApi
} from 'utils/API/Merchant APIs/DmtApiCalls';
import { phoneValidationGlobal } from 'utils/Validations';
import { globalNotifyError } from 'views/utilities/AlertToast';

export const dmType = localStorage.getItem('dmtType');
// console.log('dmType in llist', dmType);

const commonStyles = {
    m: 0
};
const uid = localStorage.getItem('id');
// console.log('id is :', uid);

export default function DmtTableIservue() {
    const shouldshowEsign = localStorage.getItem('shouldshowEsign');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState('');
    const [statusNaviagator, setStatusNavigator] = useState('');
    const [customers, setCustomers] = useState([]);
    const [responseData, setResponseData] = useState('');
    const [search, setSearch] = useState('');
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [checkExistLoading, setCheckExistLoading] = useState(false);
    const [isTrue, setIsTrue] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [otp, setOtp] = useState('');
    const [isOnboarded, setIsOnboarded] = useState(false);
    const [iserveuLoading, setIserveuLoading] = useState(false);
    const [otpVerifyLoading, setOtpVerifyLoading] = useState(false);
    const [rowData, setRowData] = useState('');
    const [isGuestCustomer, setIsGuestCustomer] = useState(false); //Guest customer is a customer mapped under other agent who is under same distributor
    const [dmtType, setDmtType] = useState('');
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    // console.log('isCheckLoading', isCheckLoading);

    // console.log('isTrue', isTrue);
    const filteredData = customers?.filter((item) => item?.customermob?.includes(search));
    // console.log('filteredData with id', filteredData);
    const location = useLocation();
    const data = location?.state?.createSuccessData;
    // const CreateCustomerRes = location?.state?.Data;
    // setStatusNavigator(data);
    // console.log('status', data);

    function handleClickBreadcrumb(event) {
        event.preventDefault();
        // console.info('You clicked a breadcrumb.');
    }
    const handleKeyPress = (e) => {
        const key = e.key;
        if (key === 'Enter') {
            e.preventDefault();
        }
    };
    const handleCreateClick = () => {
        // console.log(filteredData);
        setIsTrue(false);
        if (search?.length === 10 && filteredData[0]?.customermob !== search) {
            checkCustomerExist();
            // navigate('/dmt/createCustomer', { state: { search } });
        } else if (filteredData[0]?.customermob === search) {
            // console.log('else....');
            validateMobile();
            setIsTrue(true);
        } else {
            validateMobile();
        }
    };

    const handleOtpSubmit = () => {
        if (otp.length === 6) {
            handleOtpVerifier();
        } else {
            CommonErrorNotify('Invalid OTP length');
        }
    };
    //MODAL HANDLERS
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        if (isGuestCustomer) {
            checkCustomerExist();
        } else {
            setIsModalOpen(false);
            customerDetails();
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        customerDetails();
    };
    //UseEffect functions
    useEffect(() => {
        if (isTrue) {
            notifyCustomerCreate();
            setIsTrue(false);
        }
    }, [isTrue]);
    const handleQuickTransfer = () => {
        const data = { id: '' };
        navigate('/dmt/fundTransfer', { state: { data, filteredData } });
    };

    const handleTransferClick = (data, index) => {
        localStorage.setItem('sendernumber', data?.customermob);
        // console.log('transfer data :', data);
        if (dmtType === 'iserveu') {
            if (data?.custId) {
                // console.log('data : ', data);
                // console.log('index : ', index);
                const filteredData = customers;
                // console.log('data', data, '!!!! filteredData', filteredData);
                navigate('/dmt/fundTransfer', { state: { data, filteredData, index } });
            } else {
                setIserveuLoading(true);
                setRowData(data);
                // console.log('data ::', data, 'index::', index);
                showModal();
                getCustomerForIserveuChecking(data);
            }
        } else if (dmtType === 'billavenue') {
            if (data?.billavenue_status == '1') {
                // console.log('data : ', data);
                // console.log('index : ', index);
                const filteredData = customers;
                // console.log('data', data, '!!!! filteredData', filteredData);
                navigate('/dmt/fundTransfer', { state: { data, filteredData, index } });
            } else {
                setIserveuLoading(true);
                setRowData(data);
                // console.log('data ::', data, 'index::', index);
                showModal();
                getCustomerForIserveuChecking(data);
            }
        }
    };

    const handleIserveuOnboard = (data, index) => {
        setIserveuLoading(true);
        setRowData(data);
        // console.log('data ::', data, 'index::', index);
        showModal();
        getCustomerForIserveuChecking(data);
    };

    //API call functions
    useEffect(() => {
        checkPermissionGet()
            .then((response) => {
                const dmtType = response?.data?.dmt_type;
                setDmtType(dmtType);
            })
            .catch((error) => console.log('error', error));
        // console.log('checkDmtType', checkDmtType);
    }, []);
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    const customerDetails = async () => {
        try {
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');
            const payload = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payload);
            // const globalMerchantPermission = useSelector((state) => state);
            // console.log('merchantBcCheckResponse', merchantBcCheckResponse);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus || shouldshowEsign === 'true') {
                setMerchantModalOpen(true);
                setIsCheckLoading(false);

                return;
            }
            setIsCheckLoading(false);
            setIsLoadingTable(true);
            setIsOnboarded(false);
            const response = await fetchCustomerListAPi();
            // console.log('dmt details : ', response);
            if (response?.data?.status) {
                const customerList = response?.data?.data?.customersList;
                const responseTransfer = response?.data;
                // console.log('response direct : ', response);
                setResponseData(responseTransfer);
                setCustomers(customerList);
                // console.log('response in state :', responseData);
                // console.log('id response : ', customerList);
                setIsLoadingTable(false);
            } else {
                navigate('/unauthorized-section', { state: response?.data?.message });
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
            setIsCheckLoading(false);
        }
    };

    //API to check whether the entered phone number is registered under any of the agent of the same distributor
    const checkCustomerExist = async () => {
        if (phoneValidationGlobal(search)) {
            try {
                setCheckExistLoading(true);
                const phone = search;
                const payload = { phone };
                const response = await checkCustomerExistApi(payload);
                // console.log('check customer exist api response : ', response.data);
                const data = response?.data;
                const senderno = search;
                setCheckExistLoading(false);
                if (response?.data?.success) {
                    if (response?.data?.exist) {
                        const custId = response?.data?.data[0]?.custId;
                        // console.log('check customer exist api response : ', custId);
                        if (custId) {
                            navigate('/dmt/dmtTransfer', { state: { search, data, senderno } });
                        } else {
                            setIsGuestCustomer(true);
                            const dataCustomer = response?.data?.data[0];
                            setIserveuLoading(true);
                            setRowData(dataCustomer);
                            // console.log('data ::', data, 'index::', index);
                            showModal();
                            getCustomerForIserveuChecking(dataCustomer);
                        }

                        // dmt / dmtTransfer;
                    } else {
                        const data = { customermob: search };
                        const isIserveuCheckSuccess = getCustomerAfterCheckCustomerFalse(data);
                        isIserveuCheckSuccess
                            .then((resolvedData) => {
                                // console.log('resolvedData', resolvedData);
                                if (resolvedData) {
                                    customerDetails();
                                } else {
                                    navigate('/dmt/createCustomer', { state: { search } });
                                }
                            })
                            .catch((error) => {
                                console.log('error promise', error);
                            });
                        // console.log('isIserveuCheckSuccess', isIserveuCheckSuccess);
                    }
                }
            } catch (error) {
                setCheckExistLoading(false);

                console.log(error.message);
                handleCatchError(error);
            }
        } else {
            globalNotifyError('Please enter a valid mobile number.');
        }
    };

    //API TO CHECK THE ISERVEU ONBOARD STATUS OF SELECTED CUSTOMER
    const getCustomerForIserveuChecking = async (data) => {
        try {
            const senderno = data?.customermob;
            setIserveuLoading(true);
            const payload = { senderno };
            const response = await getCustomerApi(payload);
            // console.log('get customer api response : ', response);
            if (response?.data?.status) {
                setIsOnboarded(true);
                return true;
            } else {
                setIsOnboarded(false);
                handleCreateSendOTP(data);
                return false;
            }
        } catch (error) {
            setIserveuLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Check iserveu api for a different use after checkcustomerexist false response
    const getCustomerAfterCheckCustomerFalse = async (data) => {
        try {
            const senderno = data?.customermob;
            setIserveuLoading(true);
            const payload = { senderno };
            const response = await getCustomerApi(payload);
            return response?.data?.status;
        } catch (error) {
            setIserveuLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //API TO SEND OTP
    const handleCreateSendOTP = async (data) => {
        // setShowResponse('');
        let firstName = data?.name;
        let lastName;
        if (dmtType === 'billavenue') {
            //if there is no last name, first name is assigned as last name
            if (!data?.lname) {
                lastName = firstName;
            } else {
                lastName = data?.lname;
            }

            //if the length of the first name is less than 5, the last name is merged to fname
            const lengthOfFname = firstName.length;
            // console.log('lengthOfFname', lengthOfFname);
            if (lengthOfFname < 5) {
                firstName = firstName + lastName;
                if (firstName.length < 5) {
                    //if even after merging first name and lname the length is < 5, merge the fname again to it
                    firstName = firstName + firstName;
                }

                // console.log('new first name', firstName);
            }
        }

        // const lastName = data?.lname;
        const senderno = data?.customermob;
        const title = '';
        try {
            const payload = { senderno, firstName, lastName, title };
            const response = await createCustomerSendOtpApi(payload);
            // console.log('response after otp send : ', response);
            if (response?.data?.status === true) {
                notify('OTP sent successfully.');
                setIserveuLoading(false);
            } else {
                CommonErrorNotify('OTP sending failed');
                setIsModalOpen(false);
            }
            if (response?.data?.count >= 4) {
                CommonErrorNotify('OTP limit exceed. Please try again after some time');
            }
            // const otpdata = response.data.meta.otp;
            // console.log(otpdata);
            // setCsotp(otpdata);
            // setShowResponse(Data);
            // console.log('otp', csotp, otp);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //API TO VERIFY OTP
    const handleOtpVerifier = async () => {
        // setIsDisabled(true);
        // setIsLoading(true);
        setOtpVerifyLoading(true);
        let firstName = rowData?.name;
        let lastName;
        if (dmtType === 'billavenue') {
            if (!rowData?.lname) {
                lastName = firstName;
            } else {
                lastName = rowData?.lname;
            }

            //if the length of the first name is less than 5, the last name is merged to fname
            const lengthOfFname = firstName.length;
            // console.log('lengthOfFname', lengthOfFname);
            if (lengthOfFname < 5) {
                firstName = firstName + lastName;
                if (firstName.length < 5) {
                    //if even after merging first name and lname the length is < 5, merge the fname again to it
                    firstName = firstName + firstName;
                }

                // console.log('new first name', firstName);
            }
        }
        // const lastName = rowData?.lname;
        const senderno = rowData?.customermob;
        try {
            const payload = { firstName, lastName, senderno, otp };
            const response = await verifyCustomerOtpApi(payload);
            console.log('verify otp response', response);
            // setIsLoading(false);
            // setIsDisabled(false);
            setOtpVerifyLoading(false);
            if (response?.data?.status === true) {
                // handlesubmit();
                // navigate('/dmt/dmtTable', { state: { createSuccessData: 'Customer created successfully' } });
                notify('OTP verified successfully');
                setIsOnboarded(true);
            } else {
                // setOtpErr(response?.data?.message);
                CommonErrorNotify(response?.data?.message);
                setIsOnboarded(false);
            }
            // if (response?.data?.status === false) {
            //     notify();
            // }
        } catch (error) {
            // setIsLoading(false);
            // setIsDisabled(false);
            setOtpVerifyLoading(false);
            if (error?.response && error?.response?.status === 429) {
                CommonErrorNotify(error?.response?.data?.data?.message);
                // console.log('2343434');
                // Handle 429 response
                // Perform actions such as displaying an error message or implementing rate limiting logic
            } else {
                // Handle other errors
                // Perform actions based on different error conditions
            }
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //API CALLS END HERE>>>>>>>>>>>>>>>>>>>>>>>>>>

    useEffect(() => {
        dispatch(fetchWalletBalanceAsync());
        dispatch(checkMerchantOrBc());
        customerDetails();
    }, []);
    // console.log('res in state :', responseData);
    function buttonView(item, index) {
        if (dmtType === 'iserveu') {
            if (item?.custId) {
                localStorage.setItem('sendernumber', item?.customermob);
                const filteredData = customers;
                navigate('/dmt/dmtTransfer', { state: { item, filteredData, index } });
            } else {
                setIserveuLoading(true);
                setRowData(item);
                showModal();
                getCustomerForIserveuChecking(item);
            }
        } else if (dmtType === 'billavenue') {
            if (item?.billavenue_status == '1') {
                localStorage.setItem('sendernumber', item?.customermob);
                const filteredData = customers;
                navigate('/dmt/dmtTransfer', { state: { item, filteredData, index } });
            } else {
                setIserveuLoading(true);
                setRowData(item);
                showModal();
                getCustomerForIserveuChecking(item);
            }
        }
    }
    const notifyCustomerCreate = () =>
        toast.error('Customer Already Exists', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });

    const notify = (message) =>
        toast.success(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });

    const CommonErrorNotify = (message) =>
        toast.error(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
    useEffect(() => {
        if (data) {
            notify(data);
        }
    }, [data]);
    const validateMobile = () => {
        const re = /^[0-9]{10}$/; // Regular expression to match 10 digit mobile number
        if (!re.test(search)) {
            setErrorMsg('Please enter a valid 10 digit mobile number');
        } else {
            setErrorMsg('');
        }
    };
    return (
        <>
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    boxShadow: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton /> Domestic Money Transfer{' '}
                </Typography>

                <Box sx={{ marginTop: '10px' }}>
                    <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </Link>
                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Domestic Money Transfer</Typography>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { mb: 1, mt: 2, width: '30ch' }
                }}
                noValidate
                autoComplete="off"
            >
                {/* <FormControl> */}
                <Stack direction="row" spacing={1}>
                    <TextField
                        label="Search with Mobile"
                        name="mobile"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        variant="outlined"
                        helperText={errorMsg}
                        error={Boolean(errorMsg)}
                        onKeyDown={handleKeyPress}
                        onBlur={validateMobile}
                    />
                    {/* <Button type="submit" variant="contained">
                            Verify
                        </Button> */}
                    <Box sx={{ '& > :not(style)': {}, paddingTop: 1 }}>
                        <Tooltip title="Click to create new customer">
                            <Button
                                loading={iserveuLoading || checkExistLoading}
                                onClick={handleCreateClick}
                                shape="circle"
                                type="primary"
                                icon={<AddIcon />}
                            />
                            {/* <AddIcon /> */}
                            {/* </Button> */}
                        </Tooltip>
                    </Box>
                </Stack>

                {/* </FormControl> */}
            </Box>
            {isLoadingTable ? (
                <Space
                    direction="vertical"
                    style={{
                        width: '100%',
                        paddingTop: 100
                    }}
                >
                    <Spin tip="Loading" size="large">
                        <div className="content" />
                    </Spin>
                </Space>
            ) : (
                <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sl. No</TableCell>
                                <TableCell align="right">Customer Name</TableCell>
                                <TableCell align="right">Mobile No.</TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">Onboarding Status</TableCell>
                                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.map((row, index) => (
                                <TableRow key={row?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>

                                    <TableCell align="right">{row?.name + ' ' + row?.mname + ' ' + row?.lname}</TableCell>
                                    <TableCell align="right">{row?.customermob}</TableCell>
                                    <TableCell align="center">
                                        {
                                            <Button type="primary" variant="contained" size="small" onClick={() => buttonView(row, index)}>
                                                View
                                            </Button>
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            <Button
                                                type="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleTransferClick(row, index)}
                                            >
                                                Transfer
                                            </Button>
                                        }
                                    </TableCell>
                                    {dmtType === 'iserveu' ? (
                                        !row?.custId ? (
                                            <TableCell align="center">
                                                <Button
                                                    type="primary"
                                                    variant="contained"
                                                    size="small"
                                                    danger
                                                    onClick={() => handleIserveuOnboard(row, index)}
                                                >
                                                    Onboard Now
                                                </Button>
                                            </TableCell>
                                        ) : (
                                            <TableCell align="center">
                                                <Typography color="green">Onboarded</Typography>
                                            </TableCell>
                                        )
                                    ) : dmtType === 'billavenue' ? (
                                        !row?.billavenue_status == '1' ? (
                                            <TableCell align="center">
                                                <Button
                                                    type="primary"
                                                    variant="contained"
                                                    size="small"
                                                    danger
                                                    onClick={() => handleIserveuOnboard(row, index)}
                                                >
                                                    Onboard Now
                                                </Button>
                                            </TableCell>
                                        ) : (
                                            <TableCell align="center">
                                                <Typography color="green">Onboarded</Typography>
                                            </TableCell>
                                        )
                                    ) : !row?.custId ? (
                                        <TableCell align="center">
                                            <Button
                                                type="primary"
                                                variant="contained"
                                                size="small"
                                                danger
                                                // onClick={() => handleIserveuOnboard(row, index)}
                                            >
                                                Onboard Now
                                            </Button>
                                        </TableCell>
                                    ) : (
                                        <TableCell align="center">
                                            <Typography color="green">Onboarded</Typography>
                                        </TableCell>
                                    )}
                                    {/* <TableCell align="right">{row.protein}</TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Modal title="Onboarding" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} closable={false} footer={false}>
                {isOnboarded ? (
                    <Result
                        status="success"
                        title="Successfully Onboarded."
                        subTitle="Congratulations on completing the onboarding process! You may now proceed with the money transfer. Thank you!"
                        extra={[
                            <Button type="primary" key="console" onClick={handleOk}>
                                Proceed
                            </Button>
                            // <Button key="buy">Buy Again</Button>
                        ]}
                    />
                ) : iserveuLoading ? (
                    <Space
                        direction="vertical"
                        style={{
                            width: '100%',
                            paddingTop: 40,
                            paddingBottom: 50
                        }}
                    >
                        <Spin tip="Onboarding" size="large">
                            <div className="content" />
                        </Spin>
                    </Space>
                ) : (
                    <>
                        <Space
                            direction="vertical"
                            style={{
                                width: '100%'
                                // paddingTop: 0
                            }}
                        >
                            <Typography color="blue">
                                An OTP has beed sent to your registered mobile number. Please verify the OTP here.
                            </Typography>
                            <TextField
                                label="Enter OTP"
                                name="OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                variant="outlined"
                                // helperText={errorMsg}
                                // error={Boolean(errorMsg)}
                            />
                            <Button type="primary" key="console" onClick={handleOtpSubmit} loading={otpVerifyLoading}>
                                Submit
                            </Button>
                        </Space>
                    </>
                )}
            </Modal>
            {merchantModalOpen && (
                <BcConvertModal
                    isOpen={merchantModalOpen}
                    handleclose={handleBcModalClose}
                    isPending={isBcConvertPending}
                    serviceName={'dmt'}
                    shouldshowEsign={shouldshowEsign}
                />
            )}
            <ToastContainer />
            <Spinner loading={isCheckLoading} />
        </>
    );
}
