// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconWallet,
    IconArrowBigUpLines,
    IconDeviceMobile,
    IconHandFinger,
    IconFileInvoice,
    IconChartInfographic,
    IconArrowsTransferDown,
    IconCalculator,
    IconHeartHandshake,
    IconCreditCard,
    IconFingerprint
} from '@tabler/icons';

// constant
const icons = {
    IconTypography,

    IconPalette,
    IconShadow,
    IconWindmill,
    IconWallet,
    IconArrowBigUpLines,
    IconDeviceMobile,
    IconHandFinger,
    IconFileInvoice,
    IconChartInfographic,
    IconArrowsTransferDown,
    IconCalculator,
    IconHeartHandshake,
    IconCreditCard,
    IconFingerprint
};
const bbpsReport = localStorage.getItem('bbpsNewReport');
const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    title: '',
    children: [
        {
            id: 'icons2',
            title: 'Reports',
            type: 'collapse',
            icon: icons.IconChartInfographic,
            children: [
                {
                    id: 'sample-page',
                    title: 'DMT',
                    type: 'item',
                    url: '/dmt/dmtreport',
                    icon: icons.IconArrowsTransferDown,
                    breadcrumbs: false
                },
                {
                    id: 'aaadhar',
                    title: 'Aadhaar Pay',
                    type: 'item',
                    url: '/aeps/aadhaarpayreport',
                    icon: icons.IconFingerprint
                },
                {
                    id: 'documentationAeps',
                    title: 'AEPS',
                    type: 'item',
                    url: '/aeps/AepsReport',
                    icon: icons.IconHandFinger
                    // external: true,
                    // target: true
                },
                {
                    id: 'documentationRecharges',
                    title: 'Recharges',
                    type: 'item',
                    url: '/BBPS/rechargereport',
                    icon: icons.IconDeviceMobile
                    // external: true,
                    // target: true
                },
                {
                    id: 'documentationBillpayments',
                    title: 'Bill Payments',
                    type: 'item',
                    url: '/BBPS/bbpsreport',
                    icon: icons.IconFileInvoice
                    // external: true,
                    // target: true
                },
                {
                    id: 'documentationPos',
                    title: 'POS',
                    type: 'item',
                    url: '/pos/pos-report',
                    icon: icons.IconCalculator
                    // external: true,
                    // target: true
                },
                {
                    id: 'documentationMatm',
                    title: 'MATM',
                    type: 'item',
                    url: '/matm/matm-report',
                    icon: icons.IconDeviceMobile
                    // external: true,
                    // target: true
                },
                {
                    id: 'documentationUpiAtm',
                    title: 'Upi Atm',
                    type: 'item',
                    url: '/atmupi-history',
                    icon: icons.IconCreditCard
                },
                {
                    id: 'documentationInsurance',
                    title: 'Insurance',
                    type: 'item',
                    url: '/common-service/insurance-report',
                    icon: icons.IconCreditCard
                },
                bbpsReport === 'true'
                    ? {
                          id: 'documentationBBpsBiller',
                          title: 'BBPS Biller',
                          type: 'item',
                          url: '/bbps/bbpsbillerreport',
                          icon: icons.IconFileInvoice
                      }
                    : null
            ].filter(Boolean)
        }
    ]
};

export default other;
