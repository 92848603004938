/* eslint-disable */
import { handleCatchError } from './CatchHandler';
import { logOutGet } from './API/Merchant APIs/MerchantApiCalls';

export const logoutFunctionGlobal = async () => {
    try {
        await logOutGet();
        localStorage.removeItem('token');
        localStorage.clear();
        window.location.href = '/';
    } catch (error) {
        console.log(error.message);
        handleCatchError(error);
    }
};
