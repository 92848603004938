import { Modal, Button } from 'antd';
import './BcConvertModal.css';
import { setDisablerFalse, setDisablerTrue } from 'redux/SidebarDisablerSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RollbackOutlined, UserAddOutlined } from '@ant-design/icons';
import {
    bcConvertWithoutEsignApi,
    // checkMerchantOrBCAPI,
    conversionSlabApi,
    // convertAsBc,
    getBCAgreementHtml,
    signBcAgreementApi
} from 'utils/API/Merchant APIs/MerchantApiCalls';
import { useState } from 'react';
// import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { Tabs, Table } from 'antd';
import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { globalNotifySuccess } from 'views/utilities/AlertToast';

const BcConvertModal = (props) => {
    const isWhitelabel = localStorage.getItem('whitelabelStatus');
    // eslint-disable-next-line
    const serviceName = props?.serviceName;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const agentid = localStorage.getItem('id');
    const payload = { agentid };
    // const open = true;
    const currentRoute = window.location.pathname;
    // eslint-disable-next-line
    const handleClose = props.handleclose;
    // eslint-disable-next-line
    const shouldshowEsign = props?.shouldshowEsign;
    const [open, setOpen] = useState(true);
    // const conditionsDocumentLink = 'https://aceware-commons-public.s3.ap-south-1.amazonaws.com/public_files/image_663e0fd88c369.pdf';
    const addendum1Link = 'https://aceware-commons-public.s3.ap-south-1.amazonaws.com/public_files/Addendum-1.pdf';
    const addendum2Link = 'https://aceware-commons-public.s3.ap-south-1.amazonaws.com/public_files/Addendum-2.pdf';

    // console.log('modal open', open);
    // const [loadingConvertion, setLoadingConvertion] = useState(false);
    const [slabLoading, setSlabLoading] = useState(false);
    const [isPending, setIsPending] = useState('');
    const [allSlab, setAllSlab] = useState([]);
    const [selectedServiceSlab, setSelectedServiceSlab] = useState([]);
    const [bcAgreementHtmlFile, setBcAgreementHtmlFile] = useState('');
    const [signBcAgreementHtml, setSignAgreementHtml] = useState('');
    const [agreementFileModalOpen, setAgreementFileModalOpen] = useState(false);
    const [agreementLoading, setAgreementLoading] = useState(false);
    const [checkedStatus, setCheckedStatus] = useState(false);
    const [checkedStatus2, setCheckedStatus2] = useState(false);
    const [checkError, setCheckError] = useState(false);

    const { TabPane } = Tabs;
    // const bcConversionAgreementLink = 'https://aceware-commons-public.s3.ap-south-1.amazonaws.com/MERCHANT+AGREEMENT-+review-2.docx';

    const columns = [
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'BC',
            dataIndex: 'BC',
            key: 'BC'
        },
        {
            title: 'Premium BC',
            dataIndex: 'PremiumBC',
            key: 'PremiumBC'
        }
    ];

    const divStyle = {
        maxWidth: '1300px', // Set the maximum width you desire
        maxHeight: '300px', // Set the maximum height you desire
        overflow: 'auto' // Add scrollbars if content overflows the fixed size
        // border: '1px solid #ccc' // Add a border for visual clarity
    };
    const handleAgreementModalClose = () => {
        setCheckError(false);
        setSignAgreementHtml('');
        setAgreementFileModalOpen(false);
    };
    const handleCheck1 = (e) => {
        if (e.target.checked) {
            if (checkedStatus2) {
                setCheckError(false);
            }
        } else {
            setCheckError(true);
        }
        setCheckedStatus(e.target.checked);
    };
    const handleCheck2 = (e) => {
        if (e.target.checked) {
            if (checkedStatus) {
                setCheckError(false);
            }
        } else {
            setCheckError(true);
        }
        setCheckedStatus2(e.target.checked);
    };
    const handleAgreeProceed = () => {
        if (checkedStatus && checkedStatus2) {
            setCheckError(false);
            eSignAgreement();
        } else {
            setCheckError(true);
        }
    };
    const eSignAgreement = async () => {
        setAgreementLoading(true);
        const eSignAgreementResponse = await signBcAgreementApi(payload);
        if (eSignAgreementResponse?.data?.success) {
            const htmlFileSign = eSignAgreementResponse?.data?.data;
            setSignAgreementHtml(htmlFileSign);
            const innerTab = window.open('about:blank', 'Inner Tab', 'width=800,height=1000');
            if (innerTab) {
                // Write HTML content to the new tab
                innerTab.document.open();
                innerTab.document.write(htmlFileSign);
                innerTab.document.close();
                handleBackClick();
            } else {
                // Handle if the browser blocks popup
                alert('Please allow popups for this site to open the inner tab.');
            }

            handleAgreementModalClose();
        }
        setAgreementLoading(false);
    };
    const handleShowAgreement = async () => {
        try {
            if (isWhitelabel === 'true') {
                setAgreementLoading(true);
                const response = await bcConvertWithoutEsignApi(payload);
                console.log('response', response);
                if (response.status === 200) {
                    handleClose();
                    globalNotifySuccess('Successfully converted as a BC');
                    window.location.reload();
                }
                setAgreementLoading(false);
                return;
            }
            setAgreementFileModalOpen(true);
            setAgreementLoading(true);
            const agreementHtmlResponse = await getBCAgreementHtml(payload);
            const htmlFile = agreementHtmlResponse?.data?.data;
            setBcAgreementHtmlFile(htmlFile);
            setAgreementLoading(false);
        } catch (error) {
            setAgreementLoading(false);
        }
    };
    const handleBackClick = () => {
        dispatch(setDisablerFalse());
        if (currentRoute === '/merchant-home') {
            handleClose();
            // setOpen(false);
        } else {
            navigate('/merchant-home');
        }
    };

    useEffect(() => {
        // setOpen(true);
        // eslint-disable-next-line
        const isOpen = props?.isOpen;

        setOpen(isOpen);
        const getConversionSlabs = async () => {
            setSlabLoading(true);
            const slabResponse = await conversionSlabApi();
            setSlabLoading(false);
            const allSlabsData = slabResponse?.data?.data;
            setAllSlab(allSlabsData);
            allSlabsData.map((item) => {
                if (item?.service === serviceName) {
                    setSelectedServiceSlab(item?.slab);
                }
            });
        };
        getConversionSlabs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (open) {
            dispatch(setDisablerTrue());
        } else {
            dispatch(setDisablerFalse());
        }
        // eslint-disable-next-line
        const pendingStatus = props?.isPending;

        setIsPending(pendingStatus);
        // eslint-disable-next-line
    }, [open]);

    return (
        <Modal closable={false} open={open} footer={false} className="custom-modal">
            {isPending ? (
                <div className="modal-content">
                    <h2>Conversion is pending</h2>
                    <p>Your request to become a BC is currently pending. Please wait until the request is approved. Thank you.</p>
                    <Button type="primary" onClick={handleBackClick} icon={<RollbackOutlined />} style={{ marginLeft: 6 }}>
                        Back to other services
                    </Button>
                </div>
            ) : (
                <>
                    {
                        <Typography variant="subtitle1" color="Highlight" style={{ fontWeight: 'bold' }}>
                            {shouldshowEsign === 'true'
                                ? `Dear Agent, we noticed that you have not yet completed the Aadhaar E-sign process. Please ensure it is completed promptly, as it is mandatory to proceed further.`
                                : isWhitelabel === 'true'
                                ? `Dear Merchant, we noticed that you have not yet completed the Aadhaar E-sign process. Please ensure it is completed promptly, as it is mandatory to proceed further.`
                                : `Dear Merchant, You are advised to upgrade to a BC Agent to proceed further. This will earn you additional income 
                        through commissions as given below.`}
                        </Typography>
                    }
                    {!serviceName && shouldshowEsign !== 'true' && isWhitelabel !== 'true' ? (
                        <>
                            <Tabs style={{ marginBottom: 10 }} defaultActiveKey="1">
                                {allSlab?.map((item, index) => (
                                    <TabPane tab={item?.label} key={index}>
                                        <Table loading={slabLoading} dataSource={item.slab} pagination={false}>
                                            <Table.Column title="Amount" dataIndex="amount" key="amount" align="center" />
                                            <Table.Column title="BC" dataIndex="BC" key="BC" align="center" />
                                            <Table.Column title="Premium BC" dataIndex="PremiumBC" key="PremiumBC" align="center" />
                                        </Table>
                                    </TabPane>
                                ))}
                            </Tabs>
                        </>
                    ) : shouldshowEsign !== 'true' && isWhitelabel !== 'true' ? (
                        <>
                            <Typography variant="h4" component="h4" sx={{ mt: 1, ml: 1, mb: 1, color: 'CaptionText' }}>
                                {serviceName.toUpperCase()}
                            </Typography>
                            <Table
                                loading={slabLoading}
                                style={{ marginBottom: 10 }}
                                pagination={false}
                                dataSource={selectedServiceSlab}
                                columns={columns}
                            />
                        </>
                    ) : null}
                    <Button
                        style={{ marginTop: 2 }}
                        loading={agreementLoading}
                        icon={<UserAddOutlined />}
                        onClick={handleShowAgreement}
                        type="primary"
                        danger
                    >
                        {shouldshowEsign === 'true' ? 'Complete E-sign Now' : 'Convert as a BC'}
                    </Button>
                    <Button icon={<RollbackOutlined />} type="primary" onClick={handleBackClick} style={{ marginLeft: 6 }}>
                        Go back to home
                    </Button>
                </>
            )}
            <Modal
                title={<Chip color="info" label={'BC Agreement '} sx={{}} />}
                className="custom-modal2"
                wrapClassName="custom-modal-backdrop"
                centered
                open={agreementFileModalOpen}
                onCancel={handleAgreementModalClose}
                width={900}
                style={{ height: 500 }}
                footer={
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                                control={<Checkbox onChange={handleCheck1} />}
                                label={
                                    <Typography>
                                        I have read and agree to the
                                        <a href={addendum1Link} target="_blank">
                                            <span> Addendum 1</span>
                                        </a>
                                    </Typography>
                                }
                            />
                            <p style={{ marginLeft: 10 }}></p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                                control={<Checkbox onChange={handleCheck2} />}
                                label={
                                    <Typography>
                                        I have read and agree to the
                                        <a href={addendum2Link} target="_blank">
                                            <span> Addendum 2</span>
                                        </a>
                                    </Typography>
                                }
                            />
                        </div>
                        {checkError && <span style={{ color: 'red', paddingLeft: 10 }}>Please check all boxes to proceed</span>}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Button
                                loading={agreementLoading}
                                onClick={handleAgreeProceed}
                                style={{ marginTop: 2, marginBottom: 4, width: '120px' }}
                                type="primary"
                                disabled={checkError}
                            >
                                {agreementLoading ? '' : 'Agree and Sign'}
                            </Button>
                        </div>
                    </div>
                }
                // closable={false}
                maskClosable={false}
            >
                <>
                    {agreementLoading ? (
                        <LinearProgress />
                    ) : (
                        <div style={{ textAlign: 'center', border: '1px solid #ccc' }}>
                            <div
                                style={divStyle}
                                dangerouslySetInnerHTML={{ __html: signBcAgreementHtml ? signBcAgreementHtml : bcAgreementHtmlFile }}
                            ></div>
                        </div>
                    )}
                </>
            </Modal>
        </Modal>
    );
};

export default BcConvertModal;
