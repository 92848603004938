// assets
import { IconDeviceMobileMessage, IconQrcode, IconId } from '@tabler/icons';

// constant
const icons = {
    IconDeviceMobileMessage,
    IconQrcode,
    IconId
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const CollectPayment = {
    id: 'CollectPayment',
    title: 'Collect Payment',
    type: 'group',
    children: [
        {
            id: 'icons20',
            title: 'UPI QR',
            type: 'collapse',
            icon: icons.IconQrcode,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'to accepts payments through QR Code scanners',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons21',
            title: 'SMS payment',
            type: 'collapse',
            icon: icons.IconDeviceMobileMessage,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'send payment links for accepting payments from customers',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons22',
            title: 'Aadhar Pay ',
            type: 'collapse',
            icon: icons.IconId,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Aadhar Pay',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        }
    ]
};
export default CollectPayment;
