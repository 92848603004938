// assets
import Groups3Icon from '@mui/icons-material/Groups3';

// constant
const icons = {
    Groups3Icon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const coOperativeBc = {
    id: 'newUtilities3',
    // title: 'distributor',
    type: 'group',
    children: [
        {
            id: 'icons1',
            title: 'Co-Operative as a BC',
            type: 'collapse',
            icon: icons.Groups3Icon,
            children: [
                {
                    id: 'tabler-icons1',
                    title: 'Micro Atm Volume',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'Micro Atm Earnings',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'AEPS Earnings                    ',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'AEPS Volume                    ',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'DMT Earnings                    ',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'DMT Volume                    ',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'BBPS Number of bills Paid                    ',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'BBPS Volume                    ',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'Recharges Volume                    ',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'Recharges Earnings                    ',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'Connected Banking Volume                    ',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons1',
                    title: 'No of Txns in Connected Banking                    ',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                }
            ]
        }
    ]

    // {
    //     id: 'tabler-icons2',
    //     title: 'Profile',
    //     type: 'item',
    //     url: 'mis/profile',
    //     breadcrumbs: true
    // },
    // {
    //     id: 'tabler-icons3',
    //     title: 'Merchants',
    //     type: 'item',
    //     url: 'mis/merchants',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons4',
    //     title: 'Wallet Request',
    //     type: 'item',
    //     url: 'mis/walletrequest',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons5',
    //     title: 'Approve Wallet',
    //     type: 'item',
    //     url: '/mis/approvewallet',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons6',
    //     title: 'Bank Details',
    //     type: 'item',
    //     url: '/mis/bankdetails',
    //     breadcrumbs: false
    // }
};

export default coOperativeBc;
