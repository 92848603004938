// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const misMenu = {
    id: 'newUtilities4',
    // title: 'distributor',
    type: 'group',
    children: [
        {
            id: 'icons1',
            title: 'Dashboard',
            type: 'collapse',
            icon: icons.IconUserCircle,
            children: [
                {
                    id: 'tabler-icons1',
                    title: 'Dashboard',
                    type: 'item',
                    url: 'mis/dashboard',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons4',
                    title: 'BC Points',
                    type: 'item',
                    url: 'mis/bcpoints',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons2',
                    title: 'Qr Merchants',
                    type: 'item',
                    url: 'mis/qrmerchants',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons3',
                    title: 'Wallets',
                    type: 'item',
                    url: 'mis/wallets',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons4',
                    title: 'Co-Operative',
                    type: 'item',
                    url: 'mis/cooperative',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons4',
                    title: 'Co-Operative BC',
                    type: 'item',
                    url: 'mis/cooperativebc',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons4',
                    title: 'Upi Payouts',
                    type: 'item',
                    url: 'mis/upipayouts',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons4',
                    title: 'Merchants Through Co-Operative',
                    type: 'item',
                    url: 'mis/merchantsthroughcooperative',
                    breadcrumbs: false
                }
            ]
        }
    ]
    // {
    //     id: 'tabler-icons4',
    //     title: 'MerchantsThroughCoOperative',
    //     type: 'item',
    //     url: 'mis/MerchantsThroughCoOperative',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons5',
    //     title: 'Approve Wallet',
    //     type: 'item',
    //     url: '/mis/approvewallet',
    //     breadcrumbs: false
    // },
    // {
    //     id: 'tabler-icons6',
    //     title: 'Bank Details',
    //     type: 'item',
    //     url: '/mis/bankdetails',
    //     breadcrumbs: false
    // }
};

export default misMenu;
