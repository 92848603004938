import { Box, Grid, Typography } from '@mui/material';
import { TextField, Button, FormControl } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { addressDetailsGet, updateBcAgentAccountApi } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { useNavigate } from 'react-router';

const phoneNumberValidation = Yup.string()
    .required('Please enter the phone number')
    .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits');
const nameValidation = Yup.string()
    .required('Please enter the  name')
    .max(25, 'Maximum number of characters is 25')
    .matches(/^[a-zA-Z]+$/, 'Name must contain only alphabetic characters');
const accountNumberValidation = Yup.string()
    .required('Please enter the account number')
    .matches(/^\d{9,18}$/, 'Account number must be 9 to 18 numbers');
const stringValidation = Yup.string().required('Please enter the details').max(30, 'Please enter a shorter value');
const validationSchema = Yup.object().shape({
    firstName: nameValidation,
    middleName: nameValidation.notRequired(),
    lastName: nameValidation,
    email: Yup.string()
        .required('Please enter the email address')
        .max(100, 'Please enter a smaller email id')
        .matches(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, 'Please enter a valid email address.'),
    phoneno: phoneNumberValidation,
    pancard: Yup.string()
        .required('Please enter the PAN card number')
        .matches(/[A-Z]{5}[0-9]{4}[A-Z]/, 'Please enter a valid PAN card number.')
        .max(10, 'The lenght of pancard should be 10 characters'),
    adharno: Yup.string()
        .required('Please enter the aadhaar number')
        .matches(/^[0-9]{12}$/, 'Aadhaar number must be exactly 12 digits'),
    agency: Yup.string().required('Please enter the agency name').max(50, 'Please enter a shorter name'),
    address: Yup.string().required('Please enter the address').max(150, 'Please enter a shorter address'),
    pincode: Yup.string()
        .required('Please enter the pincode')
        .matches(/^[0-9]{6}$/, 'Pincode must be exactly 6 digits'),
    altphoneno: phoneNumberValidation,
    dob: Yup.date().required('Please choose the date of birth'),
    bank_name: stringValidation,
    branch: stringValidation,
    ifsc: Yup.string()
        .required('Please enter the IFSC')
        .matches(/^[a-zA-Z0-9]{7,11}$/, 'IFSC must be 7 to 11 alphanumeric characters'),
    account_number: accountNumberValidation,
    account_number_confirmation: accountNumberValidation.oneOf([Yup.ref('account_number'), null], 'Account numbers should match.'),
    account_name: stringValidation.max(50, 'Please enter a shorter account name.'),
    kyctype: stringValidation
});

const initialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    district: ''
};

const UpdateProfileBc = () => {
    const [districtList, setDistrictList] = useState();
    const [distValue, setDistValue] = useState('');
    const [kycimage, setKycimage] = useState('');
    const [kycimagepath, setEkycimagePath] = useState('');
    const [chequeimage, setChequeimage] = useState('');
    const [chequeimagepath, setChequeimagePath] = useState('');
    const [updateLoading, setUpdateLoading] = useState(false);

    const navigate = useNavigate();
    const ImageInput = styled('input')({
        display: 'none'
    });

    const handleSubmit = async (values, { resetForm }) => {
        // Handle form submission here
        setUpdateLoading(true);
        const payload = { ...values, primaryProfileUpdate: true, district: distValue?.dist, kycimage, chequeimage };
        const response = await updateBcAgentAccountApi(payload);
        setUpdateLoading(false);
        const isSuccess = response?.data?.success;
        if (isSuccess) {
            localStorage.setItem('bcBankVerified', true);
            globalNotifySuccess('Details updated successfully');
            navigate('/merchant-home');
            resetForm();
        }
    };
    const handleChequeimage = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setChequeimage(reader.result);
            const fileName = file.name;
            // const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
            // const filePath = `/${fileName}`;
            // setChequeimagePath(fileName);
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                if (selectedFile.size <= 500 * 1024) {
                    // 500 KB in bytes
                    // Process the file
                    setChequeimagePath(fileName);
                } else {
                    globalNotifyError('Image size exceeds 500KB. Please choose a smaller image.');
                }
            }
        };
    };

    const handleEkycimage = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setKycimage(reader.result);
            const fileName = file.name;
            // const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
            // const filePath = `/${fileName}`;
            // setEkycimagePath(fileName);
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                if (selectedFile.size <= 500 * 1024) {
                    // 500 KB in bytes
                    // Process the file
                    setEkycimagePath(fileName);
                } else {
                    globalNotifyError('Image size exceeds 500KB. Please choose a smaller image.');
                }
            }
        };
    };
    function handleDistChange(event, newValue) {
        setDistValue(newValue);
    }
    useEffect(() => {
        const getProfileApi = async () => {
            const response = await addressDetailsGet();
            const districtList = response?.data?.districts;
            console.log('districtList', districtList);
            setDistrictList(districtList);
        };
        getProfileApi();
    }, []);

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                <Box sx={{ flexGrow: 1 }}>
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="firstName"
                                    label="First Name"
                                    id="outlined-basic-firstName"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.firstName && touched.firstName}
                                    helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="middleName"
                                    label="Middle Name"
                                    id="outlined-basic-middleName"
                                    variant="outlined"
                                    fullWidth
                                    // required
                                    error={errors.middleName && touched.middleName}
                                    helperText={errors.middleName && touched.middleName ? errors.middleName : ''}
                                    autoComplete="off"
                                />
                            </Grid>{' '}
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="lastName"
                                    label="Last Name"
                                    id="outlined-basic-lastName"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.lastName && touched.lastName}
                                    helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
                                    autoComplete="off"
                                />
                            </Grid>{' '}
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormControl fullWidth error={errors.gender && touched.gender}>
                                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                    <Field
                                        as={Select}
                                        name="gender"
                                        // labelId="demo-simple-select-label"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={values.gender} // Bind value to Formik's values
                                        onChange={handleChange} // Bind onChange to Formik's handleChange
                                        onBlur={handleBlur} // Bind onBlur to Formik's handleBlur
                                    >
                                        <MenuItem value="female">Female</MenuItem>
                                        <MenuItem value="male">Male</MenuItem>
                                        <MenuItem value="other">Other</MenuItem>
                                    </Field>
                                    {errors.gender && touched.gender && <div>{errors.gender}</div>}
                                </FormControl>
                            </Grid>{' '}
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="email"
                                    label="E-mail"
                                    id="outlined-basic-email"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.email && touched.email}
                                    helperText={errors.email && touched.email ? errors.email : ''}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="phoneno"
                                    label="Phone Number"
                                    id="outlined-basic-phoneNumber"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.phoneno && touched.phoneno}
                                    helperText={errors.phoneno && touched.phoneno ? errors.phoneno : ''}
                                    autoComplete="off"
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="pancard"
                                    label="Pancard Number"
                                    id="outlined-basic-pancardNumber"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={(e) => {
                                        // Convert the entered value to uppercase and set it as the field value
                                        setFieldValue('pancard', e.target.value.toUpperCase());
                                    }}
                                    error={errors.pancard && touched.pancard}
                                    helperText={errors.pancard && touched.pancard ? errors.pancard : ''}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="adharno"
                                    label="Aadhaar Number"
                                    id="outlined-basic-aadhaarNumber"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.adharno && touched.aadhaarNuadharnomber}
                                    helperText={errors.adharno && touched.adharno ? errors.adharno : ''}
                                    autoComplete="off"
                                    inputProps={{
                                        maxLength: 12
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="agency"
                                    label="Agency Name"
                                    id="outlined-basic-agencyName"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.agency && touched.agency}
                                    helperText={errors.agency && touched.agency ? errors.agency : ''}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={8}>
                                <Field
                                    as={TextField}
                                    name="address"
                                    label="Address"
                                    id="outlined-basic-address"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.address && touched.address}
                                    helperText={errors.address && touched.address ? errors.address : ''}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="pincode"
                                    label="Pincode"
                                    id="outlined-basic-pincode"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.pincode && touched.pincode}
                                    helperText={errors.pincode && touched.pincode ? errors.pincode : ''}
                                    autoComplete="off"
                                    inputProps={{
                                        maxLength: 6
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="altphoneno"
                                    label="Alternate phone number"
                                    id="outlined-basic-address"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.altphoneno && touched.altphoneno}
                                    helperText={errors.altphoneno && touched.altphoneno ? errors.altphoneno : ''}
                                    autoComplete="off"
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label="Date of birth *"
                                            value={values.dob}
                                            onChange={(date) => setFieldValue('dob', date)}
                                            disableFuture
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                {/* <FormControl fullWidth error={errors.gender && touched.gender}> */}
                                {/* <InputLabel id="demo-simple-select-label">District</InputLabel> */}
                                <Field
                                    as={Autocomplete}
                                    // name="district"
                                    // labelId="demo-simple-select-label"
                                    required
                                    value={distValue}
                                    onChange={handleDistChange}
                                    options={districtList ? districtList : []}
                                    getOptionLabel={(option) => option.dist || 'Choose district'}
                                    sx={{ width: '100%', mb: 1 }}
                                    renderInput={(params) => <TextField {...params} label="Select District" />}
                                    error={errors.district && touched.district}
                                    helperText={errors.district}
                                />

                                {/* {errors.district && touched.district && <div>{errors.district}</div>} */}
                                {/* </FormControl> */}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="bank_name"
                                    label="Bank Name"
                                    id="outlined-basic-bankName"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.bank_name && touched.bank_name}
                                    helperText={errors.bank_name && touched.bank_name ? errors.bank_name : ''}
                                    autoComplete="off"
                                />
                            </Grid>{' '}
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="branch"
                                    label="Branch Name"
                                    id="outlined-basic-branchName"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.branch && touched.branch}
                                    helperText={errors.branch && touched.branch ? errors.branch : ''}
                                    autoComplete="off"
                                />
                            </Grid>{' '}
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="ifsc"
                                    label="IFSC Code"
                                    id="outlined-basic-ifscCode"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={(e) => {
                                        // Convert the entered value to uppercase and set it as the field value
                                        setFieldValue('ifsc', e.target.value.toUpperCase());
                                    }}
                                    error={errors.ifsc && touched.ifsc}
                                    helperText={errors.ifsc && touched.ifsc ? errors.ifsc : ''}
                                    autoComplete="off"
                                />
                            </Grid>{' '}
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="account_number"
                                    label="Account Number"
                                    id="outlined-basic-accountNumber"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.account_number && touched.account_number}
                                    helperText={errors.account_number && touched.account_number ? errors.account_number : ''}
                                    autoComplete="off"
                                />
                            </Grid>{' '}
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="account_number_confirmation"
                                    label="Confirm Account Number"
                                    id="outlined-basic-confirmAccountNumber"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.account_number_confirmation && touched.account_number_confirmation}
                                    helperText={
                                        errors.account_number_confirmation && touched.account_number_confirmation
                                            ? errors.account_number_confirmation
                                            : ''
                                    }
                                    autoComplete="off"
                                />
                            </Grid>{' '}
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="account_name"
                                    label="Account Name"
                                    id="outlined-basic-accountName"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.account_name && touched.account_name}
                                    helperText={errors.account_name && touched.account_name ? errors.account_name : ''}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Field
                                    as={TextField}
                                    name="kyctype"
                                    label="KYC Type"
                                    id="outlined-basic-kycType"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.kyctype && touched.kyctype}
                                    helperText={errors.kyctype && touched.kyctype ? errors.kyctype : ''}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} lg={4}>
                                <Field
                                    as={TextField}
                                    name="kycImage"
                                    label="Upload KYC Image"
                                    id="outlined-basic-kycImage"
                                    variant="outlined"
                                    fullWidth
                                    value={kycimagepath}
                                    required
                                    error={errors.kycImage && touched.kycImage}
                                    helperText={errors.kycImage && touched.kycImage ? errors.kycImage : ''}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={2}>
                                <ImageInput accept="image/*" id="contained-button-file" multiple type="file" onChange={handleEkycimage} />
                                <label htmlFor="contained-button-file">
                                    <Tooltip title="choose only jpg/jpeg/png">
                                        <Button variant="outlined" component="span" size="large" sx={{ padding: '10px' }}>
                                            Choose File
                                        </Button>
                                    </Tooltip>
                                </label>
                                <Typography variant="caption" color="textSecondary" sx={{ marginTop: '5px' }}>
                                    maximum size 500kb
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2} lg={4}>
                                <Field
                                    as={TextField}
                                    name="chequeImage"
                                    label="Upload Cheque Image"
                                    id="outlined-basic-chequeImage"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={chequeimagepath}
                                    error={errors.chequeImage && touched.chequeImage}
                                    helperText={errors.chequeImage && touched.chequeImage ? errors.chequeImage : ''}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={2}>
                                <ImageInput
                                    accept="image/*"
                                    id="contained-button-file-1"
                                    multiple
                                    type="file"
                                    onChange={handleChequeimage}
                                />
                                <label htmlFor="contained-button-file-1">
                                    <Tooltip title="choose only jpg/jpeg/png">
                                        <Button variant="outlined" component="span" size="large" sx={{ padding: '10px' }}>
                                            Choose File
                                        </Button>
                                    </Tooltip>
                                </label>
                                <Typography variant="caption" color="textSecondary" sx={{ marginTop: '5px' }}>
                                    maximum size 500kb
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <LoadingButton
                                    disabled={updateLoading}
                                    loading={updateLoading}
                                    fullWidth
                                    size="medium"
                                    variant="contained"
                                    type="submit"
                                >
                                    Submit Details
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
export default UpdateProfileBc;
