import { Triangle } from 'react-loader-spinner';

// eslint-disable-next-line
const Spinner = ({ loading }) => {
    return (
        <>
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)'
                    }}
                >
                    {/* <MutatingDots
                        visible={loading}
                        height="100"
                        width="100"
                        color="#1F75FA"
                        secondaryColor="#84B0F4"
                        radius="12.5"
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /> */}
                    <Triangle
                        visible={loading}
                        height="80"
                        width="80"
                        color="#1F75FA"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
        </>
    );
};

export default Spinner;
