import { useEffect } from 'react';
import { handleCatchError } from './CatchHandler';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { axiosInstanceLive } from 'instance/Axios';
// import Mpin from 'views/pages/authentication/M-pin/Mpin';
import { useDispatch } from 'react-redux';
import { setMpinTrue } from 'redux/MpinModalSlice';
export const MPinStatusChecker = () => {
    const dispatch = useDispatch();
    const handleMpinStatus = async () => {
        try {
            const token = localStorage.getItem('token');
            const agentid = localStorage.getItem('id');
            const response = await axiosInstanceLive.post(
                '/api/V2/moneytransfer/merchant/StatusMpin',
                { agentid },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(response);
            if (!response?.data?.status) {
                globalNotifyError(response?.data?.message);
            } else {
                globalNotifySuccess(response?.data?.message);
                // <Mpin status={response?.data?.status} />;
                dispatch(setMpinTrue());
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    useEffect(() => {
        handleMpinStatus();
        //eslint-disable-next-line
    }, []);
};
