// assets
import { IconHeadphones } from '@tabler/icons';

// constant
const icons = {
    IconHeadphones
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const CustomerSupport = {
    id: 'customerSupport',
    title: 'Customer Support',
    type: 'group',
    children: [
        {
            id: 'tabler-icons908',
            title: 'Customer Support',
            icon: icons.IconHeadphones,
            type: 'item',
            url: '/customer-support/ticket-management',
            breadcrumbs: false
        }
    ]
};

export default CustomerSupport;
