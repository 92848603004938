/* eslint-disable */

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import walletReducer from './WalletSlice';
import DmtReducer from './DmtUnauthorizedSlice';
// import oldReducer from '../store/reducer'; // Your existing root reducer
import customizationReducer from 'store/customizationReducer';
import loginReducer from './LoginSlice';
import ButtonStateManage from './ButtonStateManage';
import ButtonStateManage2 from './ButtonStateManage2';
import aepsTimer from './AepsTimerSlice';
import CashwithdrawalTimeSlice from './CashwithdrawalTimeSlice';
import MpinModalSlice from './MpinModalSlice';
import SidebarDisablerSlice from './SidebarDisablerSlice';
import ServiceRestrictSlice from './ServiceRestrictSlice';
// import BcAgreementSlice from './BcAgreementSlice';
import BcAgreementSlice from './BcAgreementSlice';
import ProfileUpdationSlice from './ProfileUpdationSlice';
import ThemeSlice from './themeSlice';
const rootReducer = combineReducers({
    //Combining both the existing reducer and reducer within redux toolkit here
    wallet: walletReducer,
    buttonState: ButtonStateManage,
    buttonState2: ButtonStateManage2,
    aepsTimeManager: aepsTimer,
    cashWidrawalTimeManager: CashwithdrawalTimeSlice,
    mpinModalState: MpinModalSlice,
    disabler: SidebarDisablerSlice,
    bcAgreement: BcAgreementSlice,
    // dmtAuthorized: DmtReducer,
    customization: customizationReducer,
    loginInfo: loginReducer,
    checkIsBC: ServiceRestrictSlice,
    profileUpdation: ProfileUpdationSlice,
    theme: ThemeSlice
});

const store = configureStore({
    reducer: rootReducer
    // wallet: walletReducer
});

export default store;
