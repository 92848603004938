/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import { Button } from 'antd';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Fab from '@mui/material/Fab';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Link from '@mui/material/Link';
// import { axiosInstanceLive } from 'instance/Axios';
// import axios from 'axios';
// import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { globalNotifyError } from 'views/utilities/AlertToast';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
// import { useSelector } from 'react-redux';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import { createCustomerSendOtpApi, resendCustomerOtpApi, verifyCustomerOtpApi } from 'utils/API/Merchant APIs/DmtApiCalls';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';

const commonStyles = {
    m: 0
};
export default function CreateCustomerIserveu() {
    // const senderno = JSON.parse(localStorage.getItem('senderno'));
    const email = localStorage.getItem('email_id');
    const navigate = useNavigate();
    const [fname, setFname] = React.useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lname, setLname] = React.useState('');
    const [phone, setPhoneno] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [errName, setErrName] = useState('');
    const [errLname, setErrLname] = useState('');
    const [error, setError] = useState('');
    const [otpErr, setOtpErr] = useState('');
    const [showMessageResponse, setShowMessageResponse] = React.useState('');
    // const [csotp, setCsotp] = React.useState('');
    const [checkStatus, setCheckStatus] = React.useState('');
    const [otp, setOtp] = React.useState('');
    // const [isSuccess, setIsSuccess] = React.useState('');
    const [showResponse, setShowResponse] = React.useState('');
    const [customers, setCustomers] = useState([]);
    // const [lastResponse, setLastResponse] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);

    // const [senderno, setSenderno] = useState('');
    const location = useLocation();
    const mobilefromSearch = location?.state?.search;
    // const filteredData = customers;
    const [counter, setCounter] = React.useState(59);
    const senderno = phone;
    // const isMerchantOrBc = useSelector((state) => state?.checkIsBC?.authorizationStatus);
    // const agentType = isMerchantOrBc?.agentType;
    // const pendingStatus = isMerchantOrBc?.isPending;
    React.useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
    // console.log(showMessageResponse);
    // console.log(showResponse);
    // console.log(isSuccess);
    // console.log(error);
    // useEffect(() => {
    //     handleCreate();
    // }, [phone]);
    const handleVerification = () => {
        if (senderno && fname && lname && !errName) {
            handleCreate();
        } else {
            CommonErrorNotify('Please fill all the required fields');
        }
    };
    const handleOTPChange = (e) => {
        if (e.target.value.length <= 6) {
            const numericValue = e.target.value.replace(/\D/g, '');
            setOtp(numericValue);
        } else {
            globalNotifyError('Please enter a valid OTP');
        }
    };
    const handleCreate = async () => {
        setIsLoading(true);
        setShowResponse('');
        const firstName = fname;
        const lastName = lname;
        try {
            const payload = { senderno, firstName, lastName, title };
            const response = await createCustomerSendOtpApi(payload);
            // console.log('create customer otp response', response);
            const Data = response?.data?.message;
            if (response?.data?.status === true) {
                CommonSuccessNotify('OTP sent successfully.');
                setShowResponse(Data);
            } else {
                if (Data) {
                    globalNotifyError(Data);
                } else {
                    globalNotifyError('OTP sending failed.');
                }
            }
            if (response?.data?.count >= 4) {
                OtpErrorNotify();
            }
            // const otpdata = response.data.meta.otp;
            // console.log(otpdata);
            // setCsotp(otpdata);
            setIsLoading(false);

            // console.log('otp', csotp, otp);
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const handleResentOtp = () => {
        handleReOtp();
    };
    const handleReOtp = async () => {
        setIsDisabled(true);
        try {
            const payload = { senderno };
            const response = await resendCustomerOtpApi(payload);
            setIsDisabled(false);
            const Data = response?.data?.message;
            if (response?.data?.status === true) {
                setShowResponse(Data);
                ReOtpnotify();
                setCounter(59);
            }
            if (response?.data?.count >= 4) {
                OtpErrorNotify();
            }
            // const otpdata = response.data.meta.otp;
            // console.log(otpdata);
            // setCsotp(otpdata);

            // console.log('otp', csotp, otp);
        } catch (error) {
            setIsDisabled(false);
            console.log(error.message);
        }
    };
    const handleOtpVerifier = async () => {
        setIsDisabled(true);
        setIsLoading(true);
        const firstName = fname;
        let lastName;
        if (!lname) {
            lastName = firstName;
        } else {
            lastName = lname;
        }
        try {
            const payload = { firstName, lastName, senderno, otp };
            const response = await verifyCustomerOtpApi(payload);
            setIsLoading(false);
            setIsDisabled(false);
            if (response?.data?.status === true) {
                navigate('/dmt/dmtTable', { state: { createSuccessData: 'Customer created successfully' } });
            }
        } catch (error) {
            setIsLoading(false);
            setIsDisabled(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const validateMobile = () => {
        const re = /^[0-9]{10}$/; // Regular expression to match 10 digit mobile number
        if (!re.test(senderno)) {
            setErrorMsg('Please enter a valid 10 digit mobile number');
        } else {
            setErrorMsg('');
        }
    };
    const validateName = () => {
        const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        const minLength = 3;
        if (!re.test(fname)) {
            setErrName('Please enter a valid First Name');
        } else if (fname.length < minLength) {
            setErrName(`Name should be at least ${minLength} characters long`);
        } else {
            setErrName('');
        }
    };
    const validateLName = () => {
        const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        if (!re.test(lname)) {
            setErrLname('Please enter a valid Last Name');
        } else {
            setErrLname('');
        }
    };

    function registration() {
        if (otp && senderno && fname && lname) {
            handleOtpVerifier();
        } else {
            CommonErrorNotify('Make sure you have filled all the required fields.');
        }
    }
    function handleBack() {
        navigate('/dmt/dmtTable');
    }
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    function handlesubmit() {
        const phoneno = phone;
        const senderno = phone;
        setShowResponse('');
        setError('');
        if (email && senderno && fname && lname && phoneno) {
            handleRegisterCustomer();
        } else {
            setError(true);
        }
    }

    const ReOtpnotify = () =>
        toast.success('OTP Resent successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
    const OtpErrorNotify = () =>
        toast.error('OTP limit exceed. Please try again after some time', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
    const fieldNotify = () =>
        toast.error(' All fields are Required!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
    const notify = () =>
        toast.error('Invalid OTP!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
    const CustomerExistNotify = () =>
        toast.error(showMessageResponse, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });

    const CommonSuccessNotify = (message) =>
        toast.success(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
    const CommonErrorNotify = (message) =>
        toast.error(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
    useEffect(() => {
        // if (showResponse === false) {
        //     Otpnotify();
        // }
        if (error === true) {
            fieldNotify();
            setError(false);
        }
    }, [error]);
    // useEffect(() => {
    //     if (agentType === 'Merchant' || pendingStatus) {
    //         setMerchantModalOpen(true);
    //     }
    //     setIsBcConvertPending(pendingStatus);
    //     if (isMerchantOrBc) {
    //         setIsCheckLoading(false);
    //     } else {
    //         setIsCheckLoading(true);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isMerchantOrBc]);
    useEffect(() => {
        const handleCheckmerchant = async () => {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');

            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            // const globalMerchantPermission = useSelector((state) => state);
            // console.log('globalMerchantPermission', globalMerchantPermission);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus) {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
        };
        handleCheckmerchant();
        setPhoneno(mobilefromSearch);
    }, []);
    return (
        <div>
            {/* <Box sx={{ '& > :not(style)': { m: 1, mb: 4 } }}>
                    <Fab variant="extended" size="small" color="primary" aria-label="add" onClick={handleBack}>
                        <ArrowBackIcon sx={{ mr: 1 }} />
                    </Fab>
                </Box>{' '} */}
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    marginBottom: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton /> Create Customer
                </Typography>

                <Box sx={{ marginTop: '10px' }}>
                    <RouterLink to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </RouterLink>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Create Customer</Typography>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 5
                    }
                }}
            >
                {/* <h2>Create Customer Iserveu</h2> */}
                <RadioGroup
                    sx={{
                        mt: 0,
                        ml: 1
                    }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <FormControlLabel
                        value="Mrs"
                        control={<Radio />}
                        label="Mrs."
                        checked={title === 'Mrs'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <FormControlLabel
                        value="Mr"
                        control={<Radio />}
                        label="Mr."
                        checked={title === 'Mr'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <FormControlLabel
                        value="Ms"
                        control={<Radio />}
                        label="Ms."
                        checked={title === 'Ms'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                </RadioGroup>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                required
                                label="First Name:"
                                value={fname}
                                onChange={(event) => (event.target.value.length < 20 ? setFname(event.target.value) : null)}
                                helperText={errName}
                                error={Boolean(errName)}
                                onBlur={validateName}
                                // onBlur={validateAadhaar}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                                required
                                value={lname}
                                onChange={(event) => (event.target.value.length < 20 ? setLname(event.target.value) : null)}
                                helperText={errLname}
                                error={Boolean(errLname)}
                                onBlur={validateLName}
                                // helperText={errorMsg}
                                // error={Boolean(errorMsg)}
                                // onBlur={validateMobile}
                                // onChange={handleMobileChange}
                            />
                        </Grid>
                        {/* {!showResponse && ( */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Mobile Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={phone}
                                // onChange={(event) => setSenderno(event.target.value)}
                                helperText={errorMsg}
                                error={Boolean(errorMsg)}
                                onBlur={validateMobile}
                            />
                        </Grid>
                        {/* )} */}
                        {showResponse && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="OTP"
                                    value={otp}
                                    onChange={handleOTPChange}
                                    // helperText={errorMsg}
                                    // error={Boolean(errorMsg)}
                                    // onBlur={validateMobile}
                                    // onChange={handleMobileChange}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {/* {!isDisabled && (
                            <>
                                {!showResponse && (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={Boolean(isLoading)}
                                            size="medium"
                                            fullWidth
                                            onClick={handleCreate}
                                        >
                                            {isLoading ? 'Loading' : 'Submit'}
                                        </Button>
                                    </Box>
                                )}
                            </>
                        )} */}
                    {/* {isDisabled && ( */}
                    <>
                        {!showResponse && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Button
                                    variant="contained"
                                    type="primary"
                                    // disabled={Boolean(isDisabled)}
                                    size="medium"
                                    fullWidth
                                    onClick={handleVerification}
                                    loading={isLoading}
                                >
                                    submit
                                </Button>
                            </Box>
                        )}
                    </>
                    {/* )} */}
                    {showResponse && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button variant="contained" type="primary" size="medium" fullWidth loading={isLoading} onClick={registration}>
                                Verify Customer
                            </Button>
                        </Box>
                    )}
                    {/* <h3 style={{ paddingLeft: 40, color: 'green' }}>{isSuccess === 'true' ? 'Customer Created Sucessfully....!' : null}</h3>
                        <h3 style={{ paddingLeft: 40, color: 'red' }}>{isSuccess === 'false' ? 'Customer Already Exist....!' : null}</h3> */}
                    {/* <h4 style={{ paddingLeft: 40, color: 'red' }}>{showMessageResponse}</h4> */}
                    {/* {showResponse && (
                            <Typography variant="h6" color="error">
                                OTP sent successfully
                            </Typography>
                        )} */}
                    {/* {error && (
                            <Typography variant="h6" color="error">
                                All fields are Required!
                            </Typography>
                        )} */}
                    {/* <Typography variant="h6" color="error">
                            {otpErr}
                        </Typography> */}
                </Box>
                {showResponse && (
                    <>
                        <Box mt={3}>
                            <Typography fontWeight={500} align="center" color="textSecondary">
                                {' '}
                                Resend OTP in <span style={{ color: 'green', fontWeight: 'bold' }}> 00:{counter}</span>{' '}
                            </Typography>
                        </Box>
                        <Link component="button" disabled={isDisabled} variant="body2" onClick={handleResentOtp} style={{ float: 'right' }}>
                            Resend OTP
                        </Link>
                    </>
                )}
            </Box>
            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal isOpen={merchantModalOpen} handleclose={handleBcModalClose} isPending={isBcConvertPending} />
            )}
            <Spinner loading={isCheckLoading} />
        </div>
    );
}
