import { axiosInstanceLive } from 'instance/Axios';
import { handleCatchError } from 'utils/CatchHandler';

const commonEndPoint = 'api/V2/';
// let merchantEmpID;
// const isEmployee = localStorage.getItem('isEmployee');
// if (isEmployee === 'true') {
//     merchantEmpID = localStorage.getItem('empId');
// }
const merchantCommonPost = async (apiEndPoint, payload) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    let merchantEmpID;
    const isEmployee = localStorage.getItem('isEmployee');
    if (isEmployee === 'true') {
        merchantEmpID = localStorage.getItem('empId');
    }
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.post(
            endPoint,
            payload,

            {
                headers
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};
const merchantCommonGetRequest = async (apiEndPoint) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    let merchantEmpID;
    const isEmployee = localStorage.getItem('isEmployee');
    if (isEmployee === 'true') {
        merchantEmpID = localStorage.getItem('empId');
    }
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.get(
            endPoint,

            {
                headers
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};

export const GET_API_ENDPOINTS = {
    fetchCustomerList: 'merchants/iserveudmt/fetchCustomerListApi'
    // Add more endpoints as needed
};
export const POST_API_ENDPOINTS = {
    checkCustomerExist: 'moneytransfer/checkCustomerExist',
    getCustomer: 'merchants/iserveudmt/getCustomer',
    createCustomerSendOtp: 'merchants/iserveudmt/iserveuCreateCustomerSendOtp',
    verifyCustomer: 'merchants/iserveudmt/verifyCustomer',
    resendOTPCustomer: 'merchants/iserveudmt/resendOtpCustomer',
    getIserveuMonthlyLimitCustomer: 'merchants/iserveudmt/getIserveuMonthlyLimitCustomer',
    moneytransfer_dmt: 'moneytransfer/moneytransfer_dmt',
    deleteBeneficiery: 'moneytransfer/deleteBeneficiery',
    createBeneficiary: 'merchants/iserveudmt/createBeneficiary',
    checkBenfExistApi: 'moneytransfer/checkBenfExistApi',
    verifyBeneficiary: 'merchants/iserveudmt/verifyBeneficiary',
    indexverifysender_dmt_api: 'moneytransfer/indexverifysender_dmt_api?transfer=fino-transfer',
    GetChargesDMTtransfer: 'moneytransfer/GetChargesDMTtransfer',
    loadTransferApi: 'moneytransfer/loadTransferApi',
    fundTransferIserveuOnly: 'merchants/iserveudmt/fundTransferIserveuOnly',
    reduxIndexverifysender_dmt_api: 'moneytransfer/indexverifysender_dmt_api',
    viewMoneyTransferDMTRecipt: 'moneytransfer/viewMoneyTransferDMTRecipt',
    finoCustomerRegistrationOTPApi: 'moneytransfer/finoCustomerRegistrationOTPApi',
    txnOTPverifyApi: 'moneytransfer/txnOTPverifyApi',
    finoCreatecustomerRegisterApi: 'moneytransfer/fino/CreatecustomerRegisterApi',

    //exclusively for fino dmt>>>>>
    CheckVerifynosender_dmt_api: 'moneytransfer/CheckVerifynosender_dmt_api',
    fundTransferDmtApi: 'moneytransfer/fundTransfer_dmt_Api',
    listmoneytransferDmtApi: 'moneytransfer/listmoneytransfer_dmt_api',
    listAllBenfs: 'moneytransfer/listAllBenfs',
    verifyBenefincierPenny: 'moneytransfer/verifyBenefincier',
    createBeneficiaryApi: 'moneytransfer/createBeneficiaryApi'
};

//GET REQUESTS
export const fetchCustomerListAPi = async () => {
    //API TO FETCH THE CUSTOMER LIST IN DMT
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.fetchCustomerList)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

//POST REQUESTS
export const checkCustomerExistApi = async (payload) => {
    //API FOR CHECKING IF THE USER IS BCAGENT OR MERCHANT
    const response = await merchantCommonPost(POST_API_ENDPOINTS.checkCustomerExist, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getCustomerApi = async (payload) => {
    //API FOR DB CHECKING AND ONBOARDING
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getCustomer, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const createCustomerSendOtpApi = async (payload) => {
    //API FOR SENDING OTP TO CREATE CUSTOMER
    const response = await merchantCommonPost(POST_API_ENDPOINTS.createCustomerSendOtp, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const verifyCustomerOtpApi = async (payload) => {
    //API FOR VERIYING CUSTOMER OTP
    const response = await merchantCommonPost(POST_API_ENDPOINTS.verifyCustomer, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const resendCustomerOtpApi = async (payload) => {
    //API FOR RESENDING CUSTOMER API
    const response = await merchantCommonPost(POST_API_ENDPOINTS.resendOTPCustomer, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getMonthlyLimitApi = async (payload) => {
    //API FOR GETTING THE MONTHLY LIMIT OF THE CUSTOMER
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getIserveuMonthlyLimitCustomer, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const moneytransferBenefDetailsApi = async (payload) => {
    //API FOR GETTING THE MONTHLY LIMIT OF THE CUSTOMER
    const response = await merchantCommonPost(POST_API_ENDPOINTS.moneytransfer_dmt, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const deleteBeneficiaryApi = async (payload) => {
    //API FOR DELETE BENEFICIARY
    const response = await merchantCommonPost(POST_API_ENDPOINTS.deleteBeneficiery, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const createBenefApi = async (payload) => {
    //API FOR CREATING BENEFICIARY
    const response = await merchantCommonPost(POST_API_ENDPOINTS.createBeneficiary, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const checkBenefExistApi = async (payload) => {
    //API FOR CREATING BENEFICIARY
    const response = await merchantCommonPost(POST_API_ENDPOINTS.checkBenfExistApi, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const verifyBeneficiaryApi = async (payload) => {
    //API FOR VERIFY BENEFICIARY
    const response = await merchantCommonPost(POST_API_ENDPOINTS.verifyBeneficiary, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const indexVerifySenderAPI = async (payload) => {
    //API FOR VERIFY BENEFICIARY
    const response = await merchantCommonPost(POST_API_ENDPOINTS.indexverifysender_dmt_api, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getDmtChargesApi = async (payload) => {
    //API FOR VERIFY BENEFICIARY
    const response = await merchantCommonPost(POST_API_ENDPOINTS.GetChargesDMTtransfer, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const loadTransferApi = async (payload) => {
    //API FOR VERIFY BENEFICIARY
    const response = await merchantCommonPost(POST_API_ENDPOINTS.loadTransferApi, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const fundTransferIserveuOnly = async (payload) => {
    //API FOR VERIFY BENEFICIARY
    const response = await merchantCommonPost(POST_API_ENDPOINTS.fundTransferIserveuOnly, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const reduxIndexverifysender_dmt_api = async (payload) => {
    //API FOR VERIFY BENEFICIARY
    const response = await merchantCommonPost(POST_API_ENDPOINTS.reduxIndexverifysender_dmt_api, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const viewMoneyTransferDMTReciptAPI = async (payload) => {
    //API FOR FETCHING DMT RECIEPT
    const response = await merchantCommonPost(POST_API_ENDPOINTS.viewMoneyTransferDMTRecipt, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const finoCustomerRegistrationOTPApiCall = async (payload) => {
    //API FOR FINO CUSTOMER REGISTRATION OTP
    const response = await merchantCommonPost(POST_API_ENDPOINTS.finoCustomerRegistrationOTPApi, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const createCustomerOtpVerificationApi = async (payload) => {
    //API FOR VERIFYING OTP IN FINO DMT CREATE CUSTOMER
    const response = await merchantCommonPost(POST_API_ENDPOINTS.txnOTPverifyApi, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const finoCreatecustomerRegisterApiCall = async (payload) => {
    //API FOR VERIFYING OTP IN FINO DMT CREATE CUSTOMER
    const response = await merchantCommonPost(POST_API_ENDPOINTS.finoCreatecustomerRegisterApi, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const CheckVerifynosender_dmt_api = async (payload) => {
    //API FOR getting transaction limit
    const response = await merchantCommonPost(POST_API_ENDPOINTS.CheckVerifynosender_dmt_api, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const fundTransferDmtApiCall = async (payload) => {
    //API FOR MONEY TRANSFER
    const response = await merchantCommonPost(POST_API_ENDPOINTS.fundTransferDmtApi, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listmoneytransferDmtApiCall = async (payload) => {
    //API FOR LISTING MONEY TRANSFERS
    const response = await merchantCommonPost(POST_API_ENDPOINTS.listmoneytransferDmtApi, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listAllBenfsApi = async (payload) => {
    //API FOR LISTING ALL BENEFS
    const response = await merchantCommonPost(POST_API_ENDPOINTS.listAllBenfs, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const verifyBenefincierPennyApi = async (payload) => {
    //API FOR Verify benef for pennydrop
    const response = await merchantCommonPost(POST_API_ENDPOINTS.verifyBenefincierPenny, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const createBeneficiaryApiCall = async (payload) => {
    //API FOR CREATING BENEFICIARY
    const response = await merchantCommonPost(POST_API_ENDPOINTS.createBeneficiaryApi, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
