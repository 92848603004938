import { globalNotifyError } from 'views/utilities/AlertToast';

export const emailValidationGlobal = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
};

export const phoneValidationGlobal = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phone);
};
export const ifscValidationGlobal = (ifsc) => {
    const ifscRegex = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
    return ifscRegex.test(ifsc);
};

export const passwordMin8ValidationGlobal = (password) => {
    //VALIDATES PASSWORD FOR MINUMUM 8 CHARACTERS STRING ONLY
    const passwordRegex = /^(.{8,})$/;
    return passwordRegex.test(password);
};

export const passwordStrongValidationGlobal = (password) => {
    //VALIDATE PASSWORD FOR MINIMUM 8 CHARACTERS,1 UPPERCASE, 1 LOWERCASE, 1 NUMBER, 1 SPECIAL CHARACTERS
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
    return regex.test(password);
};

export const isImageValidationGlobal = (file) => {
    //only allow image files to upload
    if (file) {
        const allowedTypes = ['image/jpeg', 'image/png'];

        if (allowedTypes.includes(file.type)) {
            // The selected file is an image; you can proceed with further actions.
            console.log('Selected file is an image:', file);
            return true;
        } else {
            // The selected file is not an image; display an error message.
            globalNotifyError('Please select a valid image file (JPEG, PNG)');
            return false;
        }
    }
};

export const serviceChargeValidationGlobal = (integer) => {
    const integerRegex = /^[0-9]+$/;
    return integerRegex.test(integer);
};

export const isNumberValidationGlobal = (integer) => {
    const regex = /^[0-9]*$/;
    return regex.test(integer);
};
