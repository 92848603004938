/* eslint-disable */
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
// import Slide from '@mui/material/Slide';
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import SearchBox from './searchbox';
import SelectedListItem from './searchList';
const searchDatas = [
    {
        name: 'DMT',
        id: 1,
        navigate: '/dmt/dmtTable',
        phoneno: true,
        icon: 'iconDmt',
        showInList: true,
        description: 'Dmt money transfer',
        keywords: ['Domestic money transfer', 'money transfer', 'dmt']
    },
    {
        name: 'AEPS',
        id: 2,
        navigate: '/aeps/balanceEnquiry',
        phoneno: false,
        icon: 'iconAeps',
        description: 'Aadhaar Enabled Payment System',
        keywords: ['cashwithdrawal', 'aeps', 'ministatement', 'balance enquiry']
    },
    {
        name: 'BBPS',
        id: 4,
        navigate: '/BBPS/billpayments',
        phoneno: true,
        icon: 'iconBbps',
        showInList: true,
        description: 'Bharat Bill Payment System',
        keywords: ['bbps', 'bill payment', 'kseb', 'kwa']
    },
    {
        name: 'MATM',
        id: 5,
        navigate: '/matm/iserveu-matm-registration',
        phoneno: false,
        icon: 'iconMatm',
        showInList: true,
        description: 'Multi-Purpose Onboarding',
        keywords: ['matm', 'Multi-Purpose Onboarding', 'onboarding']
    },
    {
        name: 'Aadhaar Pay',
        id: 6,
        navigate: 'aeps/aadhaarpay',
        phoneno: false,
        icon: 'iconAadhar',
        showInList: true,
        description: 'Aadhaar pay ',
        keywords: ['Aadhaar pay', 'pay']
    },
    {
        name: 'MY QR',
        id: 7,
        navigate: '/qr/myqr',
        phoneno: false,
        icon: 'iconMyQr',
        description: 'Qr Onboarding',
        keywords: ['Qr', 'payment']
    },
    {
        name: 'Recharge',
        id: 8,
        navigate: '/BBPS/rechargepage',
        phoneno: true,
        icon: 'iconRecharge',
        description: 'Mobile Recharge',
        keywords: ['Mobile Recharge', 'sim recharge', 'vi', 'jio', 'airtel']
    }
];
function SearchDialog({ openDialog }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [dataFromSearch, setDataFromSearch] = useState('');
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction="down" ref={ref} {...props} />;
    // });

    const handleClose = () => {
        setOpen(false);
        setDataFromSearch('');
    };
    const handleDataFromSearch = (data) => {
        setDataFromSearch(data);
    };
    useEffect(() => {
        if (openDialog) {
            setOpen(true);
        }
    }, [openDialog]);
    //         const filteredData = searchDatas.filter((item) =>
    //             item.keywords.some((keyword) => keyword.toLowerCase().includes(dataFromSearch))
    //         );
    const filteredData = searchDatas.filter((item) => {
        if (!dataFromSearch && !item.showInList) {
            return false;
        }
        const isNumber = !isNaN(dataFromSearch);

        // Check if dataFromSearch is a number and item.phoneno is true
        if (isNumber && item.phoneno) {
            return true;
        }
        return item.keywords.some((keyword) => keyword.toLowerCase().includes(dataFromSearch.toLowerCase()));
    });
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            // TransitionComponent={Transition}
            PaperProps={{
                style: {
                    height: fullScreen ? '100%' : '400px',
                    width: fullScreen ? '100%' : '800px',
                    maxWidth: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: fullScreen ? '10px' : '20px'
                }
            }}
        >
            <SearchBox typeData={handleDataFromSearch} />
            <SelectedListItem listData={filteredData} closer={handleClose} searchData={dataFromSearch} />
        </Dialog>
    );
}

export default SearchDialog;
