/* eslint-disable */
import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    width: 500px;
    background-color: #f5f5f5;
    border-radius: 25px;
    padding: 20px 25px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:focus-within {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 20px;
    }

    @media (max-width: 480px) {
        padding: 10px 15px;
    }
`;

const SearchInput = styled.input`
    flex: 1;
    border: none;
    background: none;
    outline: none;
    padding: 0 10px;
    font-size: 16px;
    color: #333;

    &::placeholder {
        color: #aaa;
    }

    @media (max-width: 768px) {
        font-size: 14px;
        padding: 0 8px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
        padding: 0 5px;
    }
`;

const SearchBox = ({ typeData }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const searchBoxRef = useRef(null);
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        typeData(e.target.value);
        // Add your search logic here
    };
    useEffect(() => {
        if (searchBoxRef.current) {
            searchBoxRef.current.focus();
        }
    }, []);
    return (
        <SearchContainer>
            <SearchInput type="text" placeholder="Search..." value={searchTerm} onChange={handleSearch} ref={searchBoxRef} />
        </SearchContainer>
    );
};

export default SearchBox;
