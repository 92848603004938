// assets
import { IconScan, IconShare } from '@tabler/icons';

// constant
const icons = {
    IconScan,
    IconShare
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const RefferAndEarn = {
    id: 'REFER & EARN',
    title: 'REFER & EARN',
    type: 'group',
    children: [
        {
            id: 'icons16',
            title: 'Acemoney Agent Referral',
            type: 'collapse',
            icon: icons.IconShare,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Acemoney Agent Referral',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons17',
            title: 'QR referral',
            type: 'collapse',
            icon: icons.IconScan,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'QR referral',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        }
    ]
};
export default RefferAndEarn;
