/* eslint-disable */

import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Box } from '@mui/material';
import { handleCatchError } from 'utils/CatchHandler';
import { axiosInstanceLive } from 'instance/Axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router';
import { useRef } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { billInvoice } from 'utils/API/Merchant APIs/MerchantApiCalls';
const commonStyles = {
    m: 0
};

const BillInvoice = () => {
    const [data, setData] = useState('');
    const [total, setTotal] = useState('');
    const [shopName, setShopName] = useState('');
    const location = useLocation();
    const receipt_id = location.state?.id;
    const agentName = localStorage.getItem('name');
    const agentPlace = localStorage.getItem('place');
    const agentmobile = localStorage.getItem('agentmobile');
    const [merchantEmpID, setMerchantEmpID] = useState('');
    const isEmployee = localStorage.getItem('isEmployee');
    const handlePrint = useReactToPrint({ content: () => componentRef.current });
    const componentRef = useRef();
    // useEffect(() => {
    //     if (receipt_id) {
    //         fetchQrBalance();
    //     }
    // }, [receipt_id]);
    useEffect(() => {
        if (receipt_id) {
            if (isEmployee === 'true') {
                const merchantEmpID = localStorage.getItem('empId');
                setMerchantEmpID(merchantEmpID);
            }
            fetchQrBalance();
        }
    }, [receipt_id]);
    useEffect(() => {
        if (merchantEmpID) {
            fetchQrBalance();
        }
    }, [merchantEmpID]);
    const fetchQrBalance = async () => {
        try {
            const payload = {
                receipt_id
            };
            const response = await billInvoice(payload);
            setData(response?.data?.data[0]);
            const shopName = response?.data?.shopname;
            setShopName(shopName);
            const amount = parseInt(response?.data?.data[0]?.amount);
            const Stationary = parseInt(response?.data?.data[0]?.stationarycharge);
            setTotal(amount + Stationary);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    useEffect(() => {
        if (data) {
            // componentRef();
            handlePrint();
        }
    }, [data]);
    // const handlePrint = () => {
    //     const printContent = document.getElementById('print-content');
    //     const originalContent = document.body.innerHTML;

    //     // Temporarily replace the body's content with the print content
    //     document.body.innerHTML = printContent.innerHTML;

    //     // Delay the print operation using setTimeout
    //     setTimeout(() => {
    //         window.print();

    //         // Restore the original content after printing
    //         document.body.innerHTML = originalContent;
    //     }, 100);
    // };
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton />
                    Bill-Invoice
                </Typography>
                <Box sx={{ marginTop: '10px' }}>
                    <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </Link>
                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Link to="/bbps/bill-list" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography component="span">Bill-Lists</Typography>
                    </Link>
                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Bill-Invoice</Typography>
                </Box>
            </Box>
            <Box sx={{ m: 2 }}></Box>
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    // ...commonStyles,
                    borderRadius: 2,
                    padding: '10px 10px',
                    '@media screen and (min-width: 600px)': {
                        // padding: 1
                    }
                }}
            >
                <div ref={componentRef} id="print-content">
                    <div
                        className="modal fade bd-bill-modal-lg"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                {/* <div className="modal-header"> */}
                                {/* <h1 className="modal-title" id="exampleModalLongTitle">
                                BILL-INVOICE
                            </h1> */}
                                {/* <Button onClick={props.closeModal} color="primary">
                            Close
                        </Button> */}
                                {/* </div> */}
                                <div className="row">
                                    <div className="col-sm-12 p-4">
                                        <span className="pull-right">
                                            {/* <Button variant="contained" color="secondary" onClick={props.printDiv}>
                                    Print
                                </Button> */}
                                        </span>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-12">
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <span className="pull-left">
                                                    <h2>{shopName}</h2>
                                                </span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container justifyContent="flex-end">
                                                    <span>
                                                        <h4>Date: {data.created_at}</h4>
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <hr className="p-1" style={{ backgroundColor: '#3ac47d' }} />
                                    <div className="col-sm-12">
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                                <span className="pull-left font_12">
                                                    <b>Order Date: {data?.created_at}</b>
                                                </span>
                                                <br />
                                                <span className="pull-left font_12">
                                                    <b>Status: Success</b>
                                                </span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span className="pull-right font_12">
                                                    <b>Customer Detail:</b>
                                                </span>
                                                <br />
                                                <span className="pull-right font_12">{data?.name}</span>
                                                <br />
                                                <span className="pull-right font_12">{data?.phone}</span>
                                                <br />
                                                <span className="pull-right font_12">{data?.place}</span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span className="pull-right font_12">
                                                    <b>Agent Detail:</b>
                                                </span>
                                                <br />
                                                <span className="pull-right font_12">{agentName}</span>
                                                <br />
                                                <span className="pull-right font_12">{agentmobile}</span>
                                                <br />
                                                <span className="pull-right font_12">{agentPlace}</span>
                                                <br />
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div className="col-sm-12">
                                        <h3>Details</h3>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="Bill Details">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Customer name</TableCell>
                                                        <TableCell>Service</TableCell>
                                                        <TableCell>Amount</TableCell>
                                                        <TableCell>Stationary charge</TableCell>
                                                        <TableCell>Total</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>{data?.name}</TableCell>
                                                        <TableCell>{data?.services}</TableCell>
                                                        <TableCell>{data?.amount}</TableCell>
                                                        <TableCell>{data?.stationarycharge}</TableCell>
                                                        <TableCell>{total}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={4}>
                                                            <b className="pull-right" style={{ paddingRight: '2%' }}>
                                                                Total
                                                            </b>
                                                        </TableCell>
                                                        <TableCell>
                                                            Rs.{' '}
                                                            <span className="text-danger" color="red">
                                                                {total}
                                                            </span>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <div className="col-sm-12">
                                        <p style={{ fontSize: '12px' }}>
                                            <b>NOTE:</b> This is computer generated receipt and does not require physical signature.
                                            DISCLAIMER: Dear Consumer, the bill payment will reflect in the next 48 hours or in the next
                                            billing cycle, at your service provider's end.
                                        </p>
                                        <p style={{ fontSize: '12px' }}>*condition apply</p>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <hr className="p-1" style={{ backgroundColor: '#3ac47d' }} />
                    <div
                        className="modal fade bd-bill-modal-lg"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="row">
                                    <div className="col-sm-12 p-4">
                                        <span className="pull-right">
                                            {/* <Button variant="contained" color="secondary" onClick={props.printDiv}>
                                    Print
                                </Button> */}
                                        </span>
                                    </div>
                                </div>
                                {/* <div id="print-content"> */}
                                <div className="row p-3">
                                    <div className="col-sm-12">
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <span className="pull-left">
                                                    <h2>{shopName}</h2>
                                                </span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span className="pull-center">
                                                    <h2 style={{ color: 'red' }}>Customer Copy</h2>
                                                </span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container justifyContent="flex-end">
                                                    <span>
                                                        <h4>Date: {data.created_at}</h4>
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <hr className="p-1" style={{ backgroundColor: '#3ac47d' }} />
                                    <div className="col-sm-12">
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                                <span className="pull-left font_12">
                                                    <b>Order Date: {data?.created_at}</b>
                                                </span>
                                                <br />
                                                <span className="pull-left font_12">
                                                    <b>Status: Success</b>
                                                </span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span className="pull-right font_12">
                                                    <b>Customer Detail:</b>
                                                </span>
                                                <br />
                                                <span className="pull-right font_12">{data?.name}</span>
                                                <br />
                                                <span className="pull-right font_12">{data?.phone}</span>
                                                <br />
                                                <span className="pull-right font_12">{data?.place}</span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span className="pull-right font_12">
                                                    <b>Agent Detail:</b>
                                                </span>
                                                <br />
                                                <span className="pull-right font_12">{agentName}</span>
                                                <br />
                                                <span className="pull-right font_12">{agentmobile}</span>
                                                <br />
                                                <span className="pull-right font_12">{agentPlace}</span>
                                                <br />
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div className="col-sm-12">
                                        <h3>Details</h3>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="Bill Details">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Customer name</TableCell>
                                                        <TableCell>Service</TableCell>
                                                        <TableCell>Amount</TableCell>
                                                        <TableCell>Stationary charge</TableCell>
                                                        <TableCell>Total</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>{data?.name}</TableCell>
                                                        <TableCell>{data?.services}</TableCell>
                                                        <TableCell>{data?.amount}</TableCell>
                                                        <TableCell>{data?.stationarycharge}</TableCell>
                                                        <TableCell>{total}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={4}>
                                                            <b className="pull-right" style={{ paddingRight: '2%' }}>
                                                                Total
                                                            </b>
                                                        </TableCell>
                                                        <TableCell>
                                                            Rs.{' '}
                                                            <span className="text-danger" color="red">
                                                                {total}
                                                            </span>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <div className="col-sm-12">
                                        <p style={{ fontSize: '12px' }}>
                                            <b>NOTE:</b> This is computer generated receipt and does not require physical signature.
                                            DISCLAIMER: Dear Consumer, the bill payment will reflect in the next 48 hours or in the next
                                            billing cycle, at your service provider's end.
                                        </p>
                                        <p style={{ fontSize: '12px' }}>*condition apply</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ReactToPrint trigger={() => <button>Print this</button>} content={() => componentRef.current} /> */}
            </Box>
        </>
    );
};

export default BillInvoice;
