/* eslint-disable */

import Dashboard from './dashboard';
import BcPoints from './Bcpoints';
import Merchants from './Merchants';
import Wallets from './Wallets';
import coOperative from './Co-Operative';
import coOperativeBc from './Co-operativeBc';
// ==============================|| MENU ITEMS ||============================== //

const MisMenuItems = {
    items: [Dashboard]
};

export default MisMenuItems;
