import { createSlice } from '@reduxjs/toolkit';

const bcAgreementSlice = createSlice({
    name: 'bcAgreement',
    initialState: {
        bcAgreement: false
    },
    reducers: {
        setBcTrue: (state) => {
            state.bcAgreementShow = true;
        },
        setBcFalse: (state) => {
            state.bcAgreementShow = false;
        }
    }
});
export const { setBcTrue, setBcFalse } = bcAgreementSlice.actions;
export default bcAgreementSlice.reducer;
