import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isTimerRunning: false,
    timeRemaining: parseInt(localStorage.getItem('timer'), 10) || 30
};

const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        startTimer: (state) => {
            state.isTimerRunning = true;
        },
        stopTimer: (state) => {
            state.isTimerRunning = false;
            state.timeRemaining = 0;
            localStorage.removeItem('timer');
        },
        updateTimer: (state, action) => {
            state.timeRemaining = action.payload;
            localStorage.setItem('timer', action.payload);
        }
    }
});

// Thunk for asynchronous time updates
export const updateTimeAsync = (timeRemaining) => (dispatch) => {
    dispatch(updateTimer(timeRemaining));
};

export const { startTimer, stopTimer, updateTimer } = timerSlice.actions;
export default timerSlice.reducer;
