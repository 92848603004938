// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconWallet,
    IconArrowBigUpLines,
    IconDeviceMobile,
    IconHandFinger,
    IconId,
    IconBrandCashapp,
    IconHeartHandshake,
    IconReceipt,
    IconTrain
} from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconWallet,
    IconArrowBigUpLines,
    IconDeviceMobile,
    IconHandFinger,
    IconId,
    IconBrandCashapp,
    IconHeartHandshake,
    IconReceipt,
    IconTrain
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const UtilityBillsAndEMI = {
    id: 'UtilityBillsAndEMI',
    title: 'Utility Bills & Recharges',
    type: 'group',
    children: [
        {
            id: 'icons712',
            title: 'Bill Payments',
            type: 'item',
            icon: icons.IconArrowBigUpLines,
            url: '/BBPS/billpayments',
            breadcrumbs: false
            // children: [
            //     {
            //         id: 'tabler-icons4',
            //         title: 'All Billers',
            //         type: 'item',
            //         url: '/BBPS/billpayments',
            //         breadcrumbs: false
            //     }
            // ]
        },

        {
            id: 'icons10',
            title: 'Recharges',
            type: 'collapse',
            icon: icons.IconDeviceMobile,
            children: [
                {
                    id: 'tabler-icons5',
                    title: 'Mobile Recharge',
                    type: 'item',
                    url: '/BBPS/rechargepage',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons1235',
                    title: 'DTH Recharge',
                    type: 'item',
                    url: '/BBPS/dthpage',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'tabler-icons15',
            title: 'Bill/Receipt',
            type: 'item',
            icon: icons.IconReceipt,
            url: '/bbps/bill-receipt'
        },
        {
            id: 'tabler-icons53',
            title: 'Book Train Tickets',
            icon: icons.IconTrain,
            type: 'item',
            url: 'bookings/train-ticket',
            breadcrumbs: false
        }
    ]
};

export default UtilityBillsAndEMI;
