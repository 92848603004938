import DmtTable from './bankingServices/money transfer/DmtTable';
import DmtTableIserveu from './bankingServices/money transfer/DMTIserveu/DmtTable';
import CreateCustomer from './bankingServices/money transfer/CreateCustomer';
import CreateCustomerIserveu from './bankingServices/money transfer/DMTIserveu/CreateCustomer';
import DmtTransfer from './bankingServices/money transfer/DmtTransfer';
import DmtTransferIserveu from './bankingServices/money transfer/DMTIserveu/DmtTransfer';
import CheckBeneficiaryStatus from './bankingServices/money transfer/CheckBeneficiaryStatus';
import CheckBeneficiaryStatusIserveu from './bankingServices/money transfer/DMTIserveu/CheckBeneficiaryStatus';
import DenseTable from './bankingServices/money transfer/TransferPage';
import DenseTableIserveu from './bankingServices/money transfer/DMTIserveu/TransferPage';
export const ConditionalRenderDmtTable = () => {
    const dmtType = localStorage.getItem('dmtType');

    if (dmtType === 'iserveu' || dmtType === 'billavenue') {
        return <DmtTableIserveu />;
    } else {
        return <DmtTable />;
    }
};

export const ConditionalRenderCreateCustomer = () => {
    const dmtType = localStorage.getItem('dmtType');

    if (dmtType === 'iserveu' || dmtType === 'billavenue') {
        return <CreateCustomerIserveu />;
    } else {
        return <CreateCustomer />;
    }
};

export const ConditionalRenderDmtTransfer = () => {
    const dmtType = localStorage.getItem('dmtType');

    if (dmtType === 'iserveu' || dmtType === 'billavenue') {
        return <DmtTransferIserveu />;
    } else {
        return <DmtTransfer />;
    }
};

export const ConditionalRenderCheckBenef = () => {
    const dmtType = localStorage.getItem('dmtType');

    if (dmtType === 'iserveu' || dmtType === 'billavenue') {
        return <CheckBeneficiaryStatusIserveu />;
    } else {
        return <CheckBeneficiaryStatus />;
    }
};

export const ConditionalRenderTransferPage = () => {
    const dmtType = localStorage.getItem('dmtType');

    if (dmtType === 'iserveu' || dmtType === 'billavenue') {
        return <DenseTableIserveu />;
    } else {
        return <DenseTable />;
    }
};
