import { createSlice } from '@reduxjs/toolkit';

const sideBarDisablerSlice = createSlice({
    name: 'sideBarDisable',
    initialState: {
        sideBarDisable: false
    },
    reducers: {
        setDisablerTrue: (state) => {
            state.sidebarShow = true;
        },
        setDisablerFalse: (state) => {
            state.sidebarShow = false;
        }
    }
});
export const { setDisablerTrue, setDisablerFalse } = sideBarDisablerSlice.actions;
export default sideBarDisablerSlice.reducer;
