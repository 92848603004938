// assets
import { IconFingerprint } from '@tabler/icons';

// constant
const icons = {
    IconFingerprint
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const RDServices = {
    id: 'Rdservices',
    title: 'RD Services',
    type: 'group',
    children: [
        {
            id: 'tabler-icons999',
            title: 'Mantra RD Service MFS 100',
            icon: icons.IconFingerprint,
            type: 'item',
            url: '/rd/rdservices',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons998',
            title: 'Mantra RD Service MFS 110',
            icon: icons.IconFingerprint,
            type: 'item',
            url: '/rd/rdservices2',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons997',
            title: 'Morpho RD Service L0',
            icon: icons.IconFingerprint,
            type: 'item',
            url: '/rd/rdservices3',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons996',
            title: 'Morpho RD Service L1',
            icon: icons.IconFingerprint,
            type: 'item',
            url: '/rd/rdservices4',
            breadcrumbs: false
        }
    ]
};

export default RDServices;
