// assets
import { IconBuildingBank, IconHeartHandshake, IconTools } from '@tabler/icons';

// constant
const icons = {
    IconBuildingBank,
    IconHeartHandshake,
    IconTools
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const CommonService = {
    id: 'CommonService',
    title: 'Common Service',
    type: 'group',
    children: [
        {
            id: 'icons7',
            title: 'Government Services',
            type: 'item',
            icon: icons.IconBuildingBank,
            url: '/common-service/government-services',
            breadcrumbs: true
            // children: [
            //     {
            //         id: 'tabler-icons4',
            //         title: 'Government Services',
            //         type: 'item',
            //         url: '/common-service/government-services',
            //         breadcrumbs: true
            //     }
            // ]
        },
        {
            id: 'tabler-icons23',
            title: 'Insurance',
            icon: icons.IconHeartHandshake,
            type: 'item',
            url: '/common-service/insurance',
            breadcrumbs: false
            // children: [
            //     {
            //         id: 'tabler-icon7',
            //         title: 'Vehicle',
            //         type: 'item',
            //         url: '/common-service/insurance',
            //         breadcrumbs: true
            //     },
            //     {
            //         id: 'tabler-icon8',
            //         title: 'Health',
            //         type: 'item',
            //         url: '/common-service/insurance-comingsoon',
            //         breadcrumbs: true
            //     },
            //     {
            //         id: 'tabler-icon9',
            //         title: 'Travel',
            //         type: 'item',
            //         url: '/common-service/insurance-comingsoon',
            //         breadcrumbs: true
            //     },
            //     {
            //         id: 'tabler-icon10',
            //         title: 'Life',
            //         type: 'item',
            //         url: '/common-service/insurance-comingsoon',
            //         breadcrumbs: true
            //     },
            //     {
            //         id: 'tabler-icon11',
            //         title: 'Assets',
            //         type: 'item',
            //         url: '/common-service/insurance-comingsoon',
            //         breadcrumbs: true
            //     },
            //     {
            //         id: 'tabler-icons5',
            //         title: 'Insurance Report',
            //         type: 'item',
            //         url: '/common-service/insurance-report',
            //         breadcrumbs: true
            //     }
            // ]

            // target: '_blank' // Add this property to specify opening in a new tab
        },
        {
            id: 'tabler-icons24',
            title: 'Helping Tools',
            icon: icons.IconTools,
            type: 'item',
            url: '/common-service/helpingtools',
            breadcrumbs: true
            // target: '_blank' // Add this property to specify opening in a new tab
        }
    ]
};

export default CommonService;
