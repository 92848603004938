import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isTimerRunning: false,
    timeRemaining: parseInt(localStorage.getItem('timerCW'), 10) || 30
};

const timerSlice = createSlice({
    name: 'timerCW',
    initialState,
    reducers: {
        startTimerCW: (state) => {
            state.isTimerRunning = true;
        },
        stopTimerCW: (state) => {
            state.isTimerRunning = false;
            state.timeRemaining = 0;
            localStorage.removeItem('timerCW');
        },
        updateTimerCW: (state, action) => {
            state.timeRemaining = action.payload;
            localStorage.setItem('timerCW', action.payload);
        }
    }
});

// Thunk for asynchronous time updates
export const updateTimeAsyncCW = (timeRemaining) => (dispatch) => {
    dispatch(updateTimerCW(timeRemaining));
};

export const { startTimerCW, stopTimerCW, updateTimerCW } = timerSlice.actions;
export default timerSlice.reducer;
