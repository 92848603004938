import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';

export const checkMerchantOrBc = createAsyncThunk('checkMerchantOrBCAPI', async () => {
    try {
        const agentid = localStorage.getItem('id');
        const payload = { agentid };
        const response = await checkMerchantOrBCAPI(payload);
        return response.data;
    } catch (error) {
        throw new Error('Failed');
    }
});

const ServiceRestrictSlice = createSlice({
    name: 'checkMerchantOrBc',
    initialState: {
        authorizationStatus: null,
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(checkMerchantOrBc.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(checkMerchantOrBc.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.authorizationStatus = action.payload;
            })
            .addCase(checkMerchantOrBc.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const checkIsBcOrMerchant = ServiceRestrictSlice.actions;
export default ServiceRestrictSlice.reducer;
