/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import { Button } from 'antd';
import { axiosInstanceLive } from '../../../../instance/Axios';
// import { useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { message, Popconfirm } from 'antd';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
const commonStyles = {
    m: 0
};
import { useState } from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { fetchWalletBalanceAsync } from 'redux/WalletSlice';
import { useDispatch } from 'react-redux';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
// import { useSelector } from 'react-redux';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import { checkBenefExistApi, createBenefApi, verifyBeneficiaryApi } from 'utils/API/Merchant APIs/DmtApiCalls';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';

// import { Navigate } from 'react-router';
export default function CheckBeneficiaryStatus() {
    const dmtType = localStorage.getItem('dmtType');
    const dispatch = useDispatch();
    const [isTrue, setIsTrue] = useState(null);
    const email = localStorage.getItem('email_id');
    const senderno = localStorage.getItem('sendernumber');
    const address = localStorage.getItem('address');
    const pin = localStorage.getItem('pin');
    const city = localStorage.getItem('city');
    const transfername = dmtType;
    const [AccountNo, setAccountNo] = useState('');
    let [benefname, setBenefname] = useState('');
    let [benelname, setBenelname] = useState('');
    const [MobileNo, setMobileNo] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [title, setTitle] = useState('');
    const [error, setError] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [accError, setAccError] = useState('');
    const [showMessage, setShowMessage] = useState('');
    const [ifscError, setIfscError] = useState('');
    const [showResponse, setShowResponse] = useState('');
    const [showStatus, setShowStatus] = useState('');
    const [pennyDropMessage, setPennyDropMessage] = useState('');
    const [dataFromPennyDrop, setDataFromPennyDrop] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const [isLoadingPenny, setIsLoadingPenny] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);

    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.item || location?.state?.data;
    // console.log('data : ', data);
    const firstName = dataFromPennyDrop?.BeneFName;
    const lastName = dataFromPennyDrop?.BeneLName;
    const phoneNumber = dataFromPennyDrop?.MobileNo;

    const filteredData = location?.state?.filteredData;
    const index = location?.state?.index;
    // console.log('location.state :', location.state);
    const agent = localStorage.getItem('id');
    // const isMerchantOrBc = useSelector((state) => state?.checkIsBC?.authorizationStatus);
    // const agentType = isMerchantOrBc?.agentType;
    // const pendingStatus = isMerchantOrBc?.isPending;
    // console.log(data);
    // const senderno = data?.customermob || location?.state?.search;
    // console.log('sendero : ', senderno);
    // console.log(email, senderno, AccountNo, ifsc);
    // useEffect(() => {

    //Alert antd
    const errorExists = () => {
        // console.log('shdsuhsehfsdiufh');
        messageApi.open({
            type: 'warning',
            content: 'Account verified successfully, but the customer already exists !',
            style: {
                marginTop: '20vh'
            },
            duration: 5
        });
    };

    //API CALL FUNCTIONS

    //API TO CREATE BENEFICIARY
    const createBenes = async (dataAfterDrop) => {
        try {
            const firstName = dataAfterDrop?.BeneFName;
            const lastName = dataAfterDrop?.BeneLName;
            if (firstName && lastName) {
                benefname = firstName;
                benelname = lastName;
            }
            // console.log('sender no :', senderno);
            setIsLoadingCreate(true);
            const senderId = data?.id;
            // console.log(email, senderno, AccountNo, ifsc, title, MobileNo, pin, address, city, benefname, benelname, transfername);
            const payload = { senderno, AccountNo, ifsc, MobileNo, benefname, benelname, transfername, senderId };
            const response = await createBenefApi(payload);
            // console.log('ressss', response);
            const message = response?.data?.message;
            const status = response?.data?.status;
            setShowMessage(message);
            setIsLoadingCreate(false);
            if (status) {
                setMobileNo('');
                setBenefname('');
                setBenelname('');
                setIfsc('');
                setAccountNo('');
                setTitle('');
                navigate('/dmt/dmtTransfer', { state: { status, data, senderno, created: 1 } });
            }
        } catch (error) {
            setIsLoadingCreate(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //API TO CHECK THE BENEFICIARY EXISTS OR NOT
    const checkbeneficiary = async () => {
        try {
            if (AccountNo && ifsc && MobileNo && benefname && benelname) {
                if (!accError && !ifscError) {
                    setIsLoading(true);

                    const payload = { email, senderno, AccountNo, ifsc };
                    const response = await checkBenefExistApi(payload);
                    // console.log('response benef exist', response);
                    setIsLoading(false);
                    const Datas = response?.data;
                    const status = Datas?.status;
                    setShowStatus(status);
                    if (status) {
                        // notify();
                        globalNotifyError('The beneficiary already exist !');
                    } else {
                        createBenes();
                    }
                    // console.log('status', status);
                    setShowResponse(status);
                    if (AccountNo && ifsc && MobileNo) {
                        if (!accError && !ifscError) {
                            if (status === false) {
                                // console.log('show res', showResponse);
                                // createBene();
                                // pennydropFunction();
                                // navigate('/dmt/CreateBeneficiary', { state: { AccountNo, ifsc, status, data, senderno } });
                                // setShowCreate(true);
                            } else {
                                setIsTrue('true');
                            }
                        }
                    } else {
                        // setAccError('Please enter a valid Account Number');
                        // setIfscError('Please enter a valid IFSC Code');
                        // setErrorMsg('Please enter a valid Mobile Number');
                    }
                } else {
                    globalNotifyError('Please check the account number and IFSC you have entered.');
                }
            } else {
                if (!benefname) {
                    globalNotifyError('Please enter a first name.');
                } else if (!benelname) {
                    globalNotifyError('Please enter a last name.');
                } else if (!AccountNo) {
                    globalNotifyError('Please enter an account number');
                } else if (!ifsc) {
                    globalNotifyError('Please enter a IFSC code');
                } else if (!MobileNo) {
                    globalNotifyError('Please enter a mobile number');
                }
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Function to call api for penny drop(ISERVEU PENNYDROP)
    const pennydropFunction = async () => {
        setPennyDropMessage('');
        try {
            setIsLoadingPenny(true);
            // const Type = 'fino';
            const customermobile = senderno;
            const customerId = data?.id ? data?.id : data?.data[0]?.id;
            const accountno = AccountNo;
            const mobileno = MobileNo;
            const fname = benefname;
            const lname = benelname;
            const payload = { customermobile, accountno, ifsc, customerId, mobileno, fname, lname };
            const response = await verifyBeneficiaryApi(payload);
            setIsLoadingPenny(false);
            dispatch(fetchWalletBalanceAsync());
            // console.log('penny drop res :', response);
            if (response?.data?.success) {
                const verifiedData = response?.data?.data;
                setDataFromPennyDrop(verifiedData);
                const status = response?.data?.data?.status;
                const exists = response?.data?.data?.exists;
                if (!accError && !ifscError) {
                    if (!exists) {
                        // console.log('exists', exists);
                        // navigate('/dmt/CreateBeneficiary', { state: { AccountNo, ifsc, status, data, senderno, verifiedData } });
                        setShowCreate(true);
                        const responseMessage = response?.data?.message;
                        globalNotifySuccess(responseMessage);
                        createBenes();
                    } else {
                        // console.log('exists true');
                        errorExists();
                    }
                }
            } else {
                if (response?.data?.message) {
                    const responseMessage = response?.data?.message;
                    setPennyDropMessage(responseMessage);
                }
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Helpers for pennydrop confirmation
    const confirm = (e) => {
        // console.log(e);
        // message.success('Penny drop selected');
        if (AccountNo && ifsc && !accError && !ifscError && benefname && benelname) {
            pennydropFunction();
        } else {
            if (!benefname || !benelname) {
                globalNotifyError('Please enter valid first and last name.');
            } else {
                setAccError('Please enter a valid Account Number');
                setIfscError('Please enter a valid IFSC Code');
            }
        }
    };
    const cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    };

    // localStorage.setItem('AccontNo', AccountNo);
    // localStorage.setItem('ifsc', ifsc);

    // }, []);
    // function createBene() {
    //     navigate('/dmt/CreateBeneficiary', { state: { AccountNo, ifsc, showStatus } });
    // }

    // Validation before create customer
    function handleSubmit() {
        setError(false);
        if (MobileNo && AccountNo && ifsc && title && benefname && benelname) {
            createBenes();
        } else {
            setError(true);
        }
    }

    //FUNCTION TO HANDLE VALIDATION ERROR
    const validateMobile = () => {
        const re = /^[0-9]{10}$/; // Regular expression to match 10 digit mobile number
        if (!re.test(MobileNo)) {
            setErrorMsg('Please enter a valid 10 digit mobile number');
        } else {
            setErrorMsg('');
        }
    };

    const validateAccount = () => {
        // const re = /^[0-9]{9,18}$/; // Regular expression to match 10 digit mobile number
        if (!AccountNo) {
            setAccError('Please enter a valid Account Number');
        } else {
            setAccError('');
        }
    };

    const handleAccountChange = (e) => {
        const inputValue = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
        setAccountNo(inputValue);
        // If the entered value has reached 6 digits, trigger the submit function
    };
    const validateIfsc = () => {
        const re = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
        if (!re.test(ifsc)) {
            setIfscError('Please enter a valid IFSC code');
        } else {
            setIfscError('');
        }
    };

    const handleIfscChange = (e) => {
        setIfsc(e.target.value.toUpperCase());
        // If the entered value has reached 6 digits, trigger the submit function
    };
    const handleMobile = (e) => {
        const mobileValue = e.target.value;
        if (/^\d*$/.test(mobileValue) && mobileValue.length <= 10) {
            setMobileNo(mobileValue);
            // If the entered value has reached 6 digits, trigger the submit function
        }
    };
    function handleBack() {
        navigate('/dmt/dmtTransfer', { state: { data, senderno } });
    }
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    const handleBenefFirstName = (event) => {
        const firstNameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
        if (event.target.value === '' || (event.target.value.length <= 20 && firstNameRegex.test(event.target.value))) {
            setBenefname(event.target.value);
        } else {
            globalNotifyError('Please enter a valid first name');
        }
    };

    const handleBenefLastName = (event) => {
        const lastNameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
        if (event.target.value === '' || (event.target.value.length <= 20 && lastNameRegex.test(event.target.value))) {
            setBenelname(event.target.value);
        } else {
            globalNotifyError('Please enter a valid last name');
        }
    };

    const validationNotify = () =>
        toast.error('All fields are Required!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });

    const notify = () =>
        toast.error('The beneficiary already exist !', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });

    const pennyDropNotify = () =>
        toast.warning(pennyDropMessage, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });

    const MessageNotify = () =>
        toast.error(showMessage, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
    // useEffect(() => {
    //     if (agentType === 'Merchant' || pendingStatus) {
    //         setMerchantModalOpen(true);
    //     }
    //     setIsBcConvertPending(pendingStatus);
    //     if (isMerchantOrBc) {
    //         setIsCheckLoading(false);
    //     } else {
    //         setIsCheckLoading(true);
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isMerchantOrBc]);
    useEffect(() => {
        if (error) {
            validationNotify();
            setError(false);
        }
    }, [error]);
    useEffect(() => {
        if (showMessage) {
            MessageNotify();
        }
    }, [showMessage]);

    useEffect(() => {
        if (pennyDropMessage) {
            pennyDropNotify();
        }
    }, [pennyDropMessage]);

    useEffect(() => {
        //To automatically fill the fields in create benef

        if (showCreate) {
            const ifscCheck = ifsc?.toUpperCase();
            // setIfsc(ifscCheck);
            // setAccountNo(accno);
            // setAllData(verifiedData);
            setBenefname(firstName);
            setBenelname(lastName);
            // setMobileNo(phoneNumber);
        }
    }, [showCreate, dataFromPennyDrop]);
    useEffect(() => {
        const handleCheckmerchant = async () => {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');

            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            // const globalMerchantPermission = useSelector((state) => state);
            // console.log('globalMerchantPermission', globalMerchantPermission);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus) {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
        };
        handleCheckmerchant();
        // staticMessagePennydrop();
        // errorExists();
    }, []);

    return (
        <>
            {contextHolder}
            {/* <Box sx={{ '& > :not(style)': { m: 1, mb: 4 } }}>
                    <Fab variant="extended" size="small" color="primary" aria-label="add" onClick={handleBack}>
                        <ArrowBackIcon sx={{ mr: 1 }} />
                    </Fab>
                </Box> */}
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    marginBottom: 2,
                    boxShadow: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton /> Domestic Money Transfer
                </Typography>

                <Box sx={{ marginTop: '10px' }}>
                    <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </Link>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>

                    <Link to="/dmt/dmtTransfer" state={{ data }} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography component="span">View Customer</Typography>
                    </Link>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Check Beneficiary</Typography>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    ...commonStyles,
                    boxShadow: 2,
                    borderRadius: 2,
                    padding: 2,
                    mb: 1
                }}
            >
                <h2>Check Beneficiary</h2>
                {/* {showCreate ? ( */}
                <RadioGroup
                    sx={{
                        mt: 2,
                        ml: 1
                    }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <FormControlLabel
                        value="Mrs"
                        control={<Radio />}
                        label="Mrs."
                        checked={title === 'Mrs'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <FormControlLabel
                        value="Mr"
                        control={<Radio />}
                        label="Mr."
                        checked={title === 'Mr'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <FormControlLabel
                        value="Ms"
                        control={<Radio />}
                        label="Ms."
                        checked={title === 'Ms'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                </RadioGroup>
                {/* ) : null} */}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-basic"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={benefname}
                                    onChange={handleBenefFirstName}
                                    // onBlur={validateAadhaar}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-basic"
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={benelname}
                                    onChange={handleBenefLastName}
                                    // helperText={errorMsg}
                                    // error={Boolean(errorMsg)}
                                    // onBlur={validateMobile}
                                    // onChange={handleMobileChange}
                                />
                            </Grid>
                        </>
                        <Grid item s={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Account Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={AccountNo}
                                helperText={accError}
                                error={Boolean(accError)}
                                onChange={handleAccountChange}
                                onBlur={validateAccount}
                            />
                        </Grid>
                        <Grid item s={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="IFSC Code"
                                variant="outlined"
                                fullWidth
                                required
                                value={ifsc}
                                helperText={ifscError}
                                error={Boolean(ifscError)}
                                onChange={handleIfscChange}
                                onBlur={validateIfsc}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Mobile Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={MobileNo}
                                onChange={handleMobile}
                                helperText={errorMsg}
                                error={Boolean(errorMsg)}
                                onBlur={validateMobile}
                            />
                        </Grid>
                        {/* {showCreate ? (
                            <>
                                <Grid item xs={12} sm={6} lg={6}></Grid>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        fullWidth
                                        loading={isLoadingCreate}
                                        onClick={handleSubmit}
                                    >
                                        Create
                                    </Button>
                                </Grid>
                            </>
                        ) : ( */}
                        <>
                            <Grid item xs={12} sm={6} lg={6}></Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Button
                                    variant="contained"
                                    type="primary"
                                    size="middle"
                                    loading={isLoading}
                                    fullWidth
                                    onClick={checkbeneficiary}
                                    sx={{ mt: 3 }}
                                >
                                    Proceed Without Penny Drop
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Popconfirm
                                    title="Verify account by dropping a penny into it !"
                                    description="Using Penny Drop may cost you ₹3."
                                    onConfirm={confirm}
                                    onCancel={cancel}
                                    okText="Proceed"
                                    cancelText="Cancel"
                                >
                                    <Button
                                        size="middle"
                                        variant="contained"
                                        type="primary"
                                        loading={isLoadingPenny}
                                        fullWidth
                                        sx={{ mt: 3 }}
                                    >
                                        Verify With Penny Drop
                                    </Button>
                                </Popconfirm>
                            </Grid>
                        </>
                        {/* )} */}
                    </Grid>
                </Box>
            </Box>

            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal isOpen={merchantModalOpen} handleclose={handleBcModalClose} isPending={isBcConvertPending} />
            )}
            <Spinner loading={isCheckLoading} />
        </>
    );
}
