import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { HomeOutlined } from '@ant-design/icons';
import 'react-toastify/dist/ReactToastify.css';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { globalNotifySuccess } from 'views/utilities/AlertToast';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import LinearProgress from '@mui/material/LinearProgress';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { CheckVerifynosender_dmt_api, deleteBeneficiaryApi, moneytransferBenefDetailsApi } from 'utils/API/Merchant APIs/DmtApiCalls';
// console.log('id is :', uid);
// const senderno = localStorage.getItem('sendernumber');
const commonStyles = {
    m: 0
};
export default function DmtTransfer() {
    const uid = localStorage.getItem('id');
    const email = localStorage.getItem('email_id');
    const shouldshowEsign = localStorage.getItem('shouldshowEsign');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [benfDetails, setBenfDetails] = useState([]);
    const [maxLimit, setMaxLimit] = useState('');
    const [isFailed, setIsFailed] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isCreatedBenef, setisCreatedBenef] = useState(false);
    const [beneficiaryMobile, setBeneficiaryMobile] = useState('');
    const [beneficiary_id, setBenefId] = useState('');
    const [beneficiary_account, setBenefAcc] = useState('');
    const [beneficiary_ifsc, setBenefIfsc] = useState('');
    const [customerMobile, setCustomerMobile] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    const [benefListLoading, setBenefListLoading] = useState(false);
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    const type = 'fino';
    const data = location?.state?.item || location?.state?.data;
    const createdBenficiaryStatus = location?.state?.created;
    // console.log('createdBenficiaryStatus', createdBenficiaryStatus);
    const filteredData = location?.state?.filteredData;
    const index = location?.state?.index;
    // console.log('location.state :', location.state);
    const agent = localStorage.getItem('id');
    // console.log(data);
    const senderno = data?.customermob || location?.state?.search || localStorage.getItem('sendernumber');
    // console.log('location state is :', location?.state);
    // console.log('customers in state: ', customers);
    const getTransactionLimit = async () => {
        // console.log(agent, senderno, email, type);
        // const token = localStorage.getItem('token');
        try {
            const payload = { agent, senderno, type };
            const response = await CheckVerifynosender_dmt_api(payload);
            // console.log('Limit response : ', response?.data?.data?.sendupto);
            const maximumLimit = response?.data?.data?.sendupto;
            setMaxLimit(maximumLimit);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        customerDetails();
        const checkIsMerchantOrBc = async () => {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');

            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            // const globalMerchantPermission = useSelector((state) => state);
            // console.log('globalMerchantPermission', globalMerchantPermission);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus || shouldshowEsign === 'true') {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
        };
        checkIsMerchantOrBc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleted]);

    const customerDetails = async () => {
        // console.log(uid, senderno, email, type);
        setBenefListLoading(true);
        // const token = localStorage.getItem('token');
        try {
            const payload = { uid, senderno, email, type };
            const response = await moneytransferBenefDetailsApi(payload);
            // console.log('response all :', response);
            // console.log(uid, senderno, email, type);
            setBenefListLoading(false);

            const metaData = response?.data?.data;
            const benfDetails = metaData?.benfDetails;
            setBenfDetails(benfDetails);
            getTransactionLimit();
        } catch (error) {
            setBenefListLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    function addbeneficiary() {
        console.log('sendernumber to local storage', senderno);
        localStorage.setItem('sendernumber', senderno);
        navigate('/dmt/Checkbeneficiary', { state: { data, senderno } });
    }
    function ListBeneficiary() {
        navigate('/dmt/ListBeneficiary', { state: { data, senderno } });
    }
    function ListMoneyTransfer() {
        navigate('/dmt/ListMoneyTransfer', { state: { data, senderno } });
    }
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };

    const handleTransferClick = (row, indexBenef) => {
        //This function triggers when transfer button is clicked
        console.log('sendernumber to local storage - transfer click', senderno);
        localStorage.setItem('sendernumber', senderno);
        // console.log(data.customermob);
        // console.log('data in fn', benfDetails);
        navigate('/dmt/fundTransfer', { state: { data, row, filteredData, index, indexBenef, senderno } });
    };

    //antd popconfirm
    const confirm = (e) => {
        console.log(e);
        deleteBeneficiary();
        // customerDetails();
        // message.success('Beneficiary Successfully Deleted');
    };
    const cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    };

    useEffect(() => {
        if (isFailed) {
            notify();
        }
        if (isSuccess) {
            customerNotify();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFailed, isSuccess]);

    useEffect(() => {
        if (isCreatedBenef === 1) {
            // benefCreateSuccessNotify();
            globalNotifySuccess('Beneficiary added successfully !');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreatedBenef]);
    useEffect(() => {
        setisCreatedBenef(createdBenficiaryStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdBenficiaryStatus]);

    // const handleOk = () => {
    //     setModalText('The modal will be closed after two seconds');
    //     setConfirmLoading(true);
    //     deleteBeneficiary();
    // };
    // const handleCancel = () => {
    //     setOpen(false);
    // };
    //Delete Beneficiary >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const handleDeleteClick = (row) => {
        setBeneficiaryMobile(row?.benefMobile);
        setBenefId(row?.id);
        setBenefIfsc(row?.benefIfsc);
        setBenefAcc(row?.benefAcc);
        setCustomerMobile(senderno);
        // const beneficiary_mobile = row?.benefMobile;
        // const customer_mobile = senderno;

        // const showModal = () => {
        //     // console.log('is failed? :', isFailed);
        //     if (beneficiary_mobile && customer_mobile) {
        //         // setOpen(true);
        //     } else {
        //         // console.log('show modal else !');
        //         setIsFailedMessage('Make sure you have filled all the fields');
        //         // console.log('Is failed should be false :', isFailed);

        //         setIsFailed(true);
        //         // console.log('is failed? :', isFailed);

        //         setIsLoading(false);
        //     }
        // };

        // showModal();

        // deleteBeneficiary();
    };

    const deleteBeneficiary = async () => {
        // const token = localStorage.getItem('token');
        const beneficiary_mobile = beneficiaryMobile;
        const customer_mobile = customerMobile;
        setIsFailed(false);
        setIsSuccess(false);
        setIsDeleted(false);
        try {
            const payload = { beneficiary_mobile, customer_mobile, beneficiary_ifsc, beneficiary_account, beneficiary_id };
            const response = await deleteBeneficiaryApi(payload);
            // setOpen(false);
            // setConfirmLoading(false);
            customerDetails();

            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                setIsDeleted(true);
                // setIsSuccessMessage(response?.data?.message);
            }
            // console.log('delete response', response);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const filteredDatas = benfDetails?.filter((row) => {
        const benefName = row?.benefName?.toLowerCase(); // Convert name to lowercase
        const searchLowerCase = search?.toLowerCase(); // Convert search to lowercase

        return benefName?.includes(searchLowerCase);
    });

    return (
        <>
            <div>
                {/* <Box sx={{ '& > :not(style)': { m: 1, mb: 4 } }}>
                    <Fab variant="extended" size="small" color="primary" aria-label="add" onClick={handleBack}>
                        <ArrowBackIcon sx={{ mr: 1 }} />
                    </Fab>
                </Box> */}
                {/* <<<<<<<Breadcrumb starts here>>>>>> */}
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        ...commonStyles,
                        borderRadius: 2,
                        marginBottom: 2,
                        padding: '20px 10px',
                        '@media screen and (min-width: 600px)': {
                            padding: 2
                        }
                    }}
                >
                    <Typography variant="h3">
                        <GlobalBackButton />
                        Domestic Money Transfer
                    </Typography>

                    <Box sx={{ marginTop: '10px' }}>
                        <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <HomeOutlined />
                        </Link>

                        <Typography component="span" style={{ margin: '0 10px' }}>
                            &gt;
                        </Typography>
                        <Link to="/dmt/dmtTable" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Domestic Money Transfer
                        </Link>

                        <Typography component="span" style={{ margin: '0 10px' }}>
                            &gt;
                        </Typography>

                        <Typography component="span">View Customer</Typography>
                    </Box>
                </Box>
                {/* <<<<<<<Breadcrumb end here>>>>>>> */}
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name : {data?.name ? data?.name : data?.data[0]?.name}</TableCell>
                                {!isMobile && (
                                    <TableCell align="right">
                                        KYC :
                                        {data?.kycStatus == 0
                                            ? 'KYC Not Applied'
                                            : data?.kycStatus == 1
                                            ? 'KYC Applied'
                                            : data?.data[0]?.kycStatus == 0
                                            ? 'KYC Not Applied'
                                            : 'KYC Applied'}
                                    </TableCell>
                                )}
                                {!isMobile && (
                                    <TableCell align="right">
                                        Mobile :{data?.customermob ? data?.customermob : data?.data[0]?.customermob}
                                    </TableCell>
                                )}
                                {!isMobile && <TableCell align="right">MONTHLY LIMIT :{maxLimit ? maxLimit : '0'}</TableCell>}
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button color="primary" variant="contained" disableElevation sx={{ m: 2 }} onClick={() => addbeneficiary()}>
                        Add Beneficiary
                    </Button>
                    <Button color="primary" variant="contained" disableElevation sx={{ m: 2 }} onClick={() => ListBeneficiary()}>
                        List All Beneficiaries
                    </Button>
                    <Button color="primary" variant="contained" disableElevation sx={{ m: 2 }} onClick={() => ListMoneyTransfer()}>
                        List moneyTransfer
                    </Button>
                    <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                        <TextField
                            label="Search with Beneficiary Name"
                            name="mobile"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            variant="outlined"
                        />
                    </Stack>
                </div>
                <TableContainer component={Paper}>
                    {benefListLoading ? (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    ) : (
                        <Table size={isMobile ? 'small' : 'medium'} aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sl. No</TableCell>
                                    <TableCell align="right">Beneficiary Name</TableCell>
                                    <TableCell align="right">IFSC</TableCell>
                                    <TableCell align="right">Account Number</TableCell>
                                    <TableCell align="right">Mobile Number</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredDatas?.map((row, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row?.benefName + ' ' + row?.benefMname + ' ' + row?.benefLname}
                                        </TableCell>
                                        <TableCell align="right">{row.benefIfsc}</TableCell>
                                        <TableCell align="right">{row.benefAcc}</TableCell>
                                        <TableCell align="right">{row.benefMobile}</TableCell>
                                        <TableCell align="center">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size={isMobile ? 'small' : 'medium'}
                                                onClick={() => {
                                                    handleTransferClick(row, row.id);
                                                }}
                                            >
                                                Transfer
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Popconfirm
                                                title="Delete Beneficiary"
                                                description="Are you sure you want to delete this beneficiary ?"
                                                onConfirm={confirm}
                                                onCancel={cancel}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button
                                                    style={{ marginLeft: '10px' }}
                                                    color="error"
                                                    variant="contained"
                                                    size={isMobile ? 'small' : 'medium'}
                                                    onClick={() => {
                                                        handleDeleteClick(row, index);
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            </Popconfirm>
                                            {/* <Modal
                                            title="Are you sure you want to delete this beneficiary ?"
                                            open={open}
                                            onOk={handleOk}
                                            confirmLoading={confirmLoading}
                                            onCancel={handleCancel}
                                        >
                                            <Row gutter={16}>
                                                <Col>
                                                    <p>You can only delete beneficiaries with no transaction history!</p>
                                                </Col>
                                            </Row>
                                        </Modal> */}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
                {merchantModalOpen && (
                    <BcConvertModal
                        isOpen={merchantModalOpen}
                        handleclose={handleBcModalClose}
                        isPending={isBcConvertPending}
                        serviceName={'dmt'}
                        shouldshowEsign={shouldshowEsign}
                    />
                )}

                <Spinner loading={isCheckLoading} />
            </div>
        </>
    );
}
