/* eslint-disable */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstanceLive } from 'instance/Axios';
import { handleCatchError } from 'utils/CatchHandler';

const initialState = {
    userData: null // default mode
};
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => action.payload,
        clearUser: (state) => null
    }
});

// export const { increment, decrement } = loginSlice.actions;
// export default loginSlice.reducer;

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
