import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Link from '@mui/material/Link';
import { handleCatchError } from 'utils/CatchHandler';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { Link as RouterLink } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
import {
    createCustomerOtpVerificationApi,
    finoCreatecustomerRegisterApiCall,
    finoCustomerRegistrationOTPApiCall
} from 'utils/API/Merchant APIs/DmtApiCalls';

const commonStyles = {
    m: 0
};
export default function CreateCustomer() {
    // const senderno = JSON.parse(localStorage.getItem('senderno'));
    const shouldshowEsign = localStorage.getItem('shouldshowEsign');
    const email = localStorage.getItem('email_id');
    const navigate = useNavigate();
    const [fname, setFname] = React.useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lname, setLname] = React.useState('');
    const [phone, setPhoneno] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [errName, setErrName] = useState('');
    const [errLname, setErrLname] = useState('');
    const [error, setError] = useState('');
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    // const [csotp, setCsotp] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [showResponse, setShowResponse] = React.useState('');
    const customers = [];
    const [isDisabled, setIsDisabled] = useState(false);
    const location = useLocation();
    const mobilefromSearch = location?.state?.search;
    const filteredData = customers;
    const [counter, setCounter] = React.useState(59);
    React.useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);
    useEffect(() => {
        if (phone) {
            handleCreate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phone]);
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    //API CALL FOR OTP
    const handleCreate = async () => {
        setIsLoading(true);
        setShowResponse('');
        try {
            const payload = { phone };
            const response = await finoCustomerRegistrationOTPApiCall(payload);
            const Data = response?.data?.message;
            if (response?.data?.status === true) {
                globalNotifySuccess('OTP sent successfully');
            }
            setIsLoading(false);
            setShowResponse(Data);
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
        }
    };
    //RESENT OTP FUNCTION
    const handleResentOtp = () => {
        handleReOtp();
    };
    //RESENT OTP API CALL
    const handleReOtp = async () => {
        setIsDisabled(true);
        try {
            const payload = { phone };
            const response = await finoCustomerRegistrationOTPApiCall(payload);
            const Data = response?.data?.message;
            if (response?.data?.status === true) {
                globalNotifySuccess('OTP resent successfully');
                setCounter(59);
            }
            setShowResponse(Data);
            setIsDisabled(false);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //OTP VERIFY API CALL
    const handleOtpVerifier = async () => {
        setIsDisabled(true);
        setIsLoading(true);
        try {
            const payload = { phone, otp };
            const response = await createCustomerOtpVerificationApi(payload);
            setIsLoading(false);
            setIsDisabled(false);
            if (response?.data?.status === true) {
                handlesubmit();
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //FUNCTION FOR MOBILE VALIDATION
    const validateMobile = () => {
        const re = /^[0-9]{10}$/; // Regular expression to match 10 digit mobile number
        if (!re.test(phone)) {
            setErrorMsg('Please enter a valid 10 digit mobile number');
        } else {
            setErrorMsg('');
        }
    };
    const validateName = () => {
        const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        if (!re.test(fname)) {
            setErrName('Please enter a valid First Name');
        } else {
            setErrName('');
        }
    };
    const validateLName = () => {
        const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        if (!re.test(lname)) {
            setErrLname('Please enter a valid Last Name');
        } else {
            setErrLname('');
        }
    };
    //API CALL FOR CREATE CUSTOMER
    const handleRegisterCustomer = async () => {
        setIsLoading(true);
        try {
            const phoneno = phone;
            const senderno = phone;
            const payload = { email, senderno, fname, lname, phoneno, otp };
            const response = await finoCreatecustomerRegisterApiCall(payload);
            const createSuccessData = response?.data?.status;
            setIsLoading(false);
            if (response?.data?.status === true) {
                navigate('/dmt/dmtTable', { state: { createSuccessData, filteredData } });
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };

    function registration() {
        handleOtpVerifier();
    }
    // function handleBack() {
    //     navigate('/dmt/dmtTable');
    // }
    function handlesubmit() {
        const phoneno = phone;
        const senderno = phone;
        setShowResponse('');
        setError('');
        if (email && senderno && fname && lname && phoneno) {
            handleRegisterCustomer();
        } else {
            setError(true);
        }
    }
    const handleFirstNameChange = (event) => {
        const regex = /^[a-zA-Z\s]*$/;
        const firstName = event.target.value;
        if (regex.test(firstName) || firstName === '') {
            if (firstName.length <= 25) setFname(firstName);
        }
    };
    const handleLastNameChange = (event) => {
        const regex = /^[a-zA-Z\s]*$/;
        const lastName = event.target.value;
        if (regex.test(lastName) || lastName === '') {
            if (lastName.length <= 25) setLname(lastName);
        }
    };
    const handleOtpChange = (event) => {
        const regex = /^\d{0,6}$/;
        if (regex.test(event.target.value) || event.target.value === '') {
            setOtp(event.target.value);
        }
    };
    useEffect(() => {
        // if (showResponse === false) {
        //     Otpnotify();
        // }
        if (error === true) {
            // fieldNotify();
            globalNotifyError('All fields are Required!');
            setError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        const checkIsMerchantOrBc = async () => {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');

            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus || shouldshowEsign === 'true') {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
        };
        checkIsMerchantOrBc();
        setPhoneno(mobilefromSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    marginBottom: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton /> Create Customer
                </Typography>

                <Box sx={{ marginTop: '10px' }}>
                    <RouterLink to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </RouterLink>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <RouterLink to="/dmt/dmtTable" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Domestic Money Transfer
                    </RouterLink>
                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Create Customer</Typography>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 5
                    }
                }}
            >
                {/* <h2>Create Customer</h2> */}
                <RadioGroup
                    sx={{
                        mt: 0,
                        ml: 1
                    }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <FormControlLabel
                        value="Mrs"
                        control={<Radio />}
                        label="Mrs."
                        checked={title === 'Mrs'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <FormControlLabel
                        value="Mr"
                        control={<Radio />}
                        label="Mr."
                        checked={title === 'Mr'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <FormControlLabel
                        value="Ms"
                        control={<Radio />}
                        label="Ms."
                        checked={title === 'Ms'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                </RadioGroup>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                required
                                label="First Name:"
                                value={fname}
                                onChange={handleFirstNameChange}
                                helperText={errName}
                                error={Boolean(errName)}
                                onBlur={validateName}
                                // onBlur={validateAadhaar}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                                required
                                value={lname}
                                onChange={handleLastNameChange}
                                helperText={errLname}
                                error={Boolean(errLname)}
                                onBlur={validateLName}
                            />
                        </Grid>
                        {/* {!showResponse && ( */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Mobile Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={phone}
                                // onChange={(event) => setPhoneno(event.target.value)}
                                helperText={errorMsg}
                                error={Boolean(errorMsg)}
                                onBlur={validateMobile}
                            />
                        </Grid>
                        {/* )} */}
                        {/* {showResponse && ( */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                required
                                label="OTP"
                                value={otp}
                                onChange={handleOtpChange}
                                // helperText={errorMsg}
                                // error={Boolean(errorMsg)}
                                // onBlur={validateMobile}
                                // onChange={handleMobileChange}
                            />
                        </Grid>
                        {/* )} */}
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="contained" color="primary" size="medium" fullWidth disabled={isDisabled} onClick={registration}>
                            {isLoading ? 'Loading' : 'Verify Customer'}
                        </Button>
                    </Box>
                </Box>
                {showResponse && (
                    <>
                        <Box mt={3}>
                            <Typography fontWeight={500} align="center" color="textSecondary">
                                {' '}
                                Resend OTP in <span style={{ color: 'green', fontWeight: 'bold' }}> 00:{counter}</span>{' '}
                            </Typography>
                        </Box>
                        <Link component="button" variant="body2" onClick={handleResentOtp} style={{ float: 'right' }}>
                            Resend OTP
                        </Link>
                    </>
                )}
            </Box>
            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal
                    isOpen={merchantModalOpen}
                    handleclose={handleBcModalClose}
                    isPending={isBcConvertPending}
                    shouldshowEsign={shouldshowEsign}
                />
            )}
            <Spinner loading={isCheckLoading} />
        </div>
    );
}
