// src/features/theme/themeSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mode: 'light' // default mode
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleTheme: (state) => {
            state.mode = state.mode === 'light' ? 'dark' : 'light';
        }
    }
});

export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;
