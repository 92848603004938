// assets
import { IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconUsers
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const MerchantEmployee = {
    id: 'merchantEmployee',
    title: 'Employee',
    type: 'group',
    children: [
        {
            id: 'tabler-icons98',
            title: 'Employee',
            icon: icons.IconUsers,
            type: 'item',
            url: '/merchants/create-merchant-employee',
            breadcrumbs: false
        }
    ]
};

export default MerchantEmployee;
