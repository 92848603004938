/* eslint-disable */
import * as React from 'react';
import './searchList.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import searchDatas from '../../../layout/MainLayout/Header/SearchSection/ArrayItems';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
// const styles = {
//     container: {
//         display: 'flex',
//         justifyContent: 'center',
//         marginTop: '20px',
//         width: '95%',
//         marginLeft: 3,
//         height: '100vh' // Adjust to center vertically in viewport
//     },
//     list: {
//         width: '100%',
//         maxWidth: '90%', // Increase the maximum width as needed
//         backgroundColor: '#fbf9f9'
//     },
//     listItem: {
//         '&:hover': {
//             backgroundColor: '#f1f1f1'
//         }
//     },
//     icon: {
//         minWidth: 36
//     }
// };
import {
    // IconTypography,
    // IconPalette,
    // IconShadow,
    // IconWindmill,
    // IconWallet,
    // IconArrowBigUpLines,
    // IconDeviceMobile,
    IconHandFinger,
    IconArrowsTransferDown,
    IconQrcode,
    IconDeviceMobile,
    IconArrowBigUpLines,
    IconFingerprint
} from '@tabler/icons';
// constant
const icons = {
    // IconTypography,

    // IconPalette,
    // IconShadow,
    // IconWindmill,
    // IconWallet,
    // IconArrowBigUpLines,
    iconRecharge: IconDeviceMobile,
    iconAeps: IconHandFinger,
    iconDmt: IconArrowsTransferDown,
    iconMatm: IconDeviceMobile,
    iconBbps: IconArrowBigUpLines,
    iconMyQr: IconQrcode,
    // IconCreditCard,
    iconAadhar: IconFingerprint
};
function SelectedListItem({ listData, closer, searchData }) {
    const navigate = useNavigate();
    const handleListItemClick = (event, index, navigates) => {
        navigate(navigates, { state: { phoneno: searchData } });
        closer(false);
    };
    const IconComponent = ({ iconName }) => {
        const IconData = icons[iconName];
        return IconData ? <IconData /> : null;
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
            <>
                {listData.map((datas, index) => (
                    <Card
                        key={index}
                        onMouseEnter={() => handleMouseEnter(datas?.id)}
                        onClick={(event) => handleListItemClick(event, 0, datas?.navigate)}
                        sx={{
                            maxWidth: 90,
                            minWidth: 90,
                            maxHeight: 90,
                            minHeight: 90,
                            boxShadow: 3,
                            p: 1,
                            m: 1,
                            mt: 2,
                            ':hover': {
                                boxShadow: 8,
                                elevation: 8,
                                cursor: 'pointer'
                            }
                        }}
                    >
                        <Tooltip title={datas?.description} arrow>
                            <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>
                                    <div style={{ marginBottom: 10 }}>
                                        <IconComponent iconName={datas?.icon} />
                                    </div>
                                    <div style={{ marginTop: 'auto' }}>
                                        <Typography align="center" sx={{ fontSize: 10 }} color="text.secondary">
                                            {datas?.name}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Tooltip>
                    </Card>
                ))}
            </>
        </div>
    );
}

export default SelectedListItem;
