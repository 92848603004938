/* eslint-disable */
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { message } from 'antd';

export function globalNotifySuccess(messageProp, duration = 3) {
    message.success(messageProp, duration);
    // toast.success(message, {
    //     position: 'top-right',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: 'light'
    // });
}

export function globalNotifyError(messageProp, duration = 3) {
    message.error(messageProp, duration);
    // toast.error(message, {
    //     position: 'top-right',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: 'light'
    // });
}

function AlertToast() {
    return (
        <div>
            <ToastContainer />
        </div>
    );
}

export default AlertToast;
