/* eslint-disable */

// import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
import BankingServices from './BankingServices';
import UtilityBillsAndEMI from './utilityBillsAndEMI';
import CommonService from './CommonService';
import Reports from './Reports';
// import other from './other';
import Booking from './Booking';
import AceMoneyEarnMore from './AceMoneyEarnMore';
import AccountAndSavings from './AcountAndSavings';
import CollectPayment from './CollectPayment';
import RefferAndEarn from './RefferAndEarn';
import Pancard from './Pancard';
import Wallet from './Wallets';
import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
// import newUtilities from './newUtilities';
import other from './other';

import RDServices from './RDServices';
import CustomerSupport from './CustomerSupport';
import HelpLine from './HelpLine';
import MerchantEmployee from './MerchantEmployee';
// ==============================|| MENU ITEMS ||============================== //
const currentDomain = window.location.hostname;
const portalStatus = localStorage.getItem('portalStatus');
const isEmployee = localStorage.getItem('isEmployee');
// console.log('isEmployee in index', isEmployee);
// console.log(currentDomain);
let menuItems = {
    items: [dashboard, BankingServices, Wallet, MerchantEmployee, UtilityBillsAndEMI, CommonService, Pancard, RDServices, Reports]
};
if (currentDomain === 'app.acemoney.in' || currentDomain === 'dev-app.acemoney.in' || currentDomain === 'localhost') {
    //ACEMONEY SUPPORT NUMBER SECTION NOT ACCESSIBLE FOR WHITELABEL
    menuItems.items.push(HelpLine);
    // if (isEmployee !== 'true') {
    //     menuItems.items.splice(3, 0, MerchantEmployee);
    // }
} else if (portalStatus) {
    menuItems = { items: [dashboard, RDServices, Reports] };

    menuItems.items.push(HelpLine);
} else {
    menuItems.items.push(CustomerSupport);
}
export default menuItems;
