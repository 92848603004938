// assets
import { IconCreditCard, IconBrandFirebase, IconDoor, IconLifebuoy, IconMoneybag } from '@tabler/icons';

// constant
const icons = {
    IconCreditCard,
    IconBrandFirebase,
    IconDoor,
    IconLifebuoy,
    IconMoneybag
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const AceMoneyEarnMore = {
    id: 'ACEMONEY – EARN MORE',
    title: 'ACEMONEY – EARN MORE',
    type: 'group',
    children: [
        {
            id: 'icons31',
            title: 'ATM 2 HOME',
            type: 'collapse',
            icon: icons.IconCreditCard,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Registration & availability time Turn On option',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons32',
            title: 'Wearable ATM',
            type: 'collapse',
            icon: icons.IconBrandFirebase,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Wearable ATM',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons33',
            title: 'Doorstep Gold loan',
            type: 'collapse',
            icon: icons.IconDoor,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Doorstep Gold loan',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons34',
            title: 'Sahakarana Seva',
            type: 'collapse',
            icon: icons.IconLifebuoy,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'portal link connects to web page',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons35',
            title: 'Individual ATM machines ',
            type: 'collapse',
            icon: icons.IconMoneybag,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Hitachi ATM for Retailers',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default AceMoneyEarnMore;
