// assets
import { IconWallet, IconArrowAutofitRight } from '@tabler/icons';

// constant
const icons = {
    IconWallet,
    IconArrowAutofitRight
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Wallets = {
    id: 'Wallets',
    title: 'Wallets',
    type: 'group',
    children: [
        {
            id: 'tabler-icons77',
            title: 'Wallet Request',
            icon: icons.IconWallet,
            type: 'item',
            url: 'merchants/wallet',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons8822',
            title: 'Credit',
            type: 'item',
            icon: icons.IconArrowAutofitRight,
            url: '/credit',
            breadcrumbs: false
        }
    ]
};

export default Wallets;
