// assets
import { IconUserCircle, IconCoins, IconHeartHandshake, IconBuildingBank, IconBrandMastercard } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle,
    IconCoins,
    IconBuildingBank,
    IconHeartHandshake,
    IconBrandMastercard
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const AccountAndSavings = {
    id: 'Account And Savings',
    title: 'Account And Savings',
    type: 'group',
    children: [
        {
            id: 'icons26',
            title: 'Account opening & prepaid cards ',
            type: 'collapse',
            icon: icons.IconUserCircle,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Zero balance/Saving/CD referral',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons27',
            title: 'Loan ',
            type: 'collapse',
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Housing, Vehicle, Personal Loan',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons28',
            title: 'Mutual Fund',
            type: 'collapse',
            icon: icons.IconHeartHandshake,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Mutual Fund',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons29',
            title: 'BNPL',
            type: 'collapse',
            icon: icons.IconBrandMastercard,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'BNPL',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons30',
            title: 'Digital Gold',
            type: 'collapse',
            icon: icons.IconCoins,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Digital Gold',
                    type: 'item',
                    url: '',
                    breadcrumbs: false
                }
            ]
        }
    ]
};
export default AccountAndSavings;
