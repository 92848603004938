/* eslint-disable */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstanceLive } from 'instance/Axios';
import { checkMerchantBalanceApi } from 'utils/API/Merchant APIs/WalletApiCalls';
import { handleCatchError } from 'utils/CatchHandler';
const type = localStorage.getItem('type');
export const fetchWalletBalanceAsync = createAsyncThunk('wallet/fetchWalletBalance', async () => {
    try {
        const types = localStorage.getItem('type');
        if (types === 'merchants') {
            const response = await fetchWalletBalance();
            return response;
        }
    } catch (error) {
        throw new Error('Failed to fetch wallet balance');
    }
});
// console.log(type);
const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
        balance: null,
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWalletBalanceAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWalletBalanceAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.balance = action.payload;
            })
            .addCase(fetchWalletBalanceAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

//Function to fetch balance
const fetchWalletBalance = async () => {
    try {
        const agent = localStorage.getItem('id');
        const payload = { agent };
        const response = await checkMerchantBalanceApi(payload);
        if (response?.data?.settlement_wallet || response?.data?.settlement_wallet == 0) {
            localStorage.setItem('w3status', true);
        } else {
            localStorage.setItem('w3status', false);
        }
        const number = response?.data?.balance;
        const virtualBalance = response?.data?.virtual_balance;
        const wallet3Balance = response?.data?.settlement_wallet;
        const roundedNumber = (Math.round(number * 100) / 100).toFixed(2);

        return { virtualBalance, roundedNumber, wallet3Balance };
    } catch (error) {
        console.log(error.message);
        const checkMerchantLogin = localStorage.getItem('type');
        if (checkMerchantLogin === 'merchants') {
            handleCatchError(error);
        }
    }
};

export const { increment, decrement } = walletSlice.actions;
export default walletSlice.reducer;
